import React, { FC, useMemo } from 'react';
import { Stack, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';

const Links: FC = () => {
  const { t } = useTranslation();
  const isPrivacyPolicy = useAppSelector(selectPageData('privacy_policy'));
  const isTermsOfUse = useAppSelector(selectPageData('terms_of_use'));
  const isVacancies = useAppSelector(selectPageData('vacancies'));
  const deliveryPage = useAppSelector(selectPageData('delivery'));

  const isDelivery = useMemo(() => {
    return deliveryPage?.is_delivery_footer_link;
  }, []);

  return (
    <Stack
      sx={{ marginBottom: { xs: '10px', sm: '0' } }}
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 2, md: 3 }}
    >
      {isDelivery && (
        <Link
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            lineHeight: '1',
          }}
          to="/delivery"
          component={NavLink}
        >
          {t('delivery')}
        </Link>
      )}
      {isPrivacyPolicy && (
        <Link
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            lineHeight: '1',
          }}
          to="/privacy_policy"
          component={NavLink}
        >
          {t('privacy_policy')}
        </Link>
      )}
      {isTermsOfUse && (
        <Link
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            lineHeight: '1',
          }}
          to="/terms_of_use"
          component={NavLink}
        >
          {t('terms_of_use')}
        </Link>
      )}
      {isVacancies && (
        <Link
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            lineHeight: '1',
          }}
          to="/vacancies"
          component={NavLink}
        >
          {t('vacancies')}
        </Link>
      )}
    </Stack>
  );
};

export default Links;

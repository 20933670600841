import { Box } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

interface HTMLContentProps {
  htmlString: string;
}

const HTMLContent: FC<HTMLContentProps> = ({ htmlString }) => {
  const [uniqueId] = useState(
    `html-content-${Math.random().toString(36).substring(2, 11)}`
  );

  useEffect(() => {
    const container = document.createElement('div');
    container.innerHTML = htmlString;
    container.setAttribute(
      'style',
      'width: 100%; flex-grow: 1; flex-shrink: 0'
    );

    const target = document.getElementById(uniqueId);
    if (target) {
      target.appendChild(container);
    }

    const scripts = container.querySelectorAll('script');
    scripts.forEach((script) => {
      const newScript = document.createElement('script');
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.textContent = script.textContent;
      }

      if (target) {
        target.appendChild(newScript);
      } else {
        document.body.appendChild(newScript);
      }

      script.remove();
    });

    return () => {
      if (target) {
        target.innerHTML = '';
      }
    };
  }, [htmlString]);

  return (
    <Box
      id={uniqueId}
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
    />
  );
};

export default HTMLContent;

import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: ReactNode;
}

const portalRoot = document.getElementById('portal-root');

const Portal: FC<PortalProps> = ({ children }) => {
  return portalRoot ? ReactDOM.createPortal(<>{children}</>, portalRoot) : null;
};

export default Portal;

import React, { FC, useEffect } from 'react';
import {
  Account,
  Cart,
  About,
  Menu,
  PageNotFoundView,
  PromotionNews,
  Payment,
  Blog,
  BlogPost,
  PaymentSuccess,
  ErrorPage,
  OrderSuccess,
  Delivery,
  PrivacyPolicy,
  TermsOfUse,
  Home,
  QrPage,
  Gallery,
  Reserve,
  Vacancies,
  Reviews,
  MenuPdf,
} from 'pages';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AppLayout, ReviewsLayout } from 'layouts';
import { PrivateRoute, Rtl, ScrollToTop } from 'components/generic';
import { useInitApp } from 'hooks';
import { ThemeProvider } from '@mui/material';
import { useStyles } from 'hooks/styles';
import { Helmet } from 'react-helmet';
import { useAuth } from 'hooks/auth';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBlogPosts } from 'store/selectors/blogSelectors';
import { HighRating, LowRating } from 'components/reviews';
import { selectPageData } from 'store/selectors/pageSelectors';
import { useCookies } from 'react-cookie';

const App: FC = () => {
  useInitApp();
  const theme = useStyles();
  const location = useLocation();
  const { isAuth } = useAuth();
  const blogPosts = useAppSelector(selectBlogPosts);
  const isAboutPage = useAppSelector(selectPageData('about'));
  const isDeliveryPage = useAppSelector(selectPageData('delivery'));
  const isprivacyPolicyPage = useAppSelector(selectPageData('privacy_policy'));
  const isTermsOfUse = useAppSelector(selectPageData('terms_of_use'));
  const isReviewsPage = useAppSelector(selectPageData('reviews'));
  const isReservePage = useAppSelector(selectPageData('reserve'));
  const isVacanciesPage = useAppSelector(selectPageData('vacancies'));
  const isHomePage = useAppSelector(selectPageData('home'));
  const [cookie] = useCookies(['cookie_consent']);

  useEffect(() => {
    if (!cookie.cookie_consent) {
      localStorage.clear();
    }
  }, [cookie.cookie_consent]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Rtl>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Routes location={location}>
            {process.env.REACT_APP_IS_QR_PAGE === 'true' && (
              <>
                <Route path="/qr" element={<QrPage />} />
                <Route path="/qrpage" element={<QrPage />} />
              </>
            )}
            <Route path="/" element={<AppLayout />}>
              {isHomePage ? (
                <Route index element={<Home />} />
              ) : (
                <Route index element={<Menu />} />
              )}
              <Route path="menu/:id" element={<Menu />} />

              {isAboutPage && <Route path="about" element={<About />} />}

              {isReservePage && <Route path="reserve" element={<Reserve />} />}
              {blogPosts.length > 0 && (
                <>
                  <Route path="blog" element={<Blog />} />
                  <Route path="blog/:postId" element={<BlogPost />} />
                </>
              )}
              {isReviewsPage && (
                <Route path="reviews" element={<ReviewsLayout />}>
                  <Route index element={<Reviews />} />
                  <Route path="high" element={<HighRating />} />
                  <Route path="low" element={<LowRating />} />
                </Route>
              )}
              {isDeliveryPage && (
                <Route path="delivery" element={<Delivery />} />
              )}
              {isprivacyPolicyPage && (
                <Route path="privacy_policy" element={<PrivacyPolicy />} />
              )}
              {isTermsOfUse && (
                <Route path="terms_of_use" element={<TermsOfUse />} />
              )}
              {isVacanciesPage && (
                <Route path="vacancies" element={<Vacancies />} />
              )}
              {process.env.REACT_APP_IS_GALLERY_PAGE === 'true' && (
                <Route path="gallery" element={<Gallery />} />
              )}
              <Route path="menu_pdf" element={<MenuPdf />} />
              <Route path="promotion-news" element={<PromotionNews />} />
              <Route path="cart" element={<Cart />} />
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route
                path="payment-failure"
                element={<Navigate replace to="/error" state="payment" />}
              />
              <Route path="order-success" element={<OrderSuccess />} />
              <Route path="error" element={<ErrorPage />} />

              {isAuth && (
                <>
                  <Route
                    path="account"
                    element={
                      <PrivateRoute>
                        <Account />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="payment"
                    element={
                      <PrivateRoute>
                        <Payment />
                      </PrivateRoute>
                    }
                  />
                </>
              )}
              <Route path="*" element={<PageNotFoundView />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </Rtl>
    </>
  );
};

export default App;

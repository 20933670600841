import i18next from 'i18next';

export default function formatPrice(
  priceCop: number,
  currencyCode?: string
): string {
  // Please, when this project uses at least es2023, just use
  // `trailingZeroDisplay: 'stripIfInteger'`.

  return Intl.NumberFormat(
    i18next.language || process.env.REACT_APP_DEFAULT_LANGUAGE,
    {
      style: 'currency',
      currency: currencyCode || process.env.REACT_APP_CURRENCY_CODE,
    }
  )
    .format(priceCop / 100)
    .replace(',00', '');
}

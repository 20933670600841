import React, { FC } from 'react';
import { Box, Typography, Link, Button, CircularProgress } from '@mui/material';
import { BackDrawerButton, TextField } from 'components/generic';
import { NavLink } from 'react-router-dom';
import { useLoginInputStep } from 'hooks/auth';

interface LoginInputStepProps {
  onClose: () => void;
  nextStep: () => void;
}

const LoginInputStep: FC<LoginInputStepProps> = ({ onClose, nextStep }) => {
  const {
    t,
    control,
    title,
    text,
    inputLabel,
    pattern,
    mask,
    maskPlaceholder,
    codeIsLoading,
  } = useLoginInputStep(nextStep);

  return (
    <Box sx={{ padding: '100px 20px 20px 20px' }}>
      <Box
        sx={{ position: 'fixed', top: '24px', left: '20px', zIndex: '100' }}
        onClick={onClose}
      >
        <BackDrawerButton direction="down" />
      </Box>
      <Typography
        sx={{
          marginBottom: '11px',
        }}
        variant="h4"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: '17px',
          lineHeight: '21px',
        }}
      >
        {text}
      </Typography>

      <Box sx={{ marginBottom: '40px' }}>
        <TextField
          control={control}
          name="login"
          label={inputLabel}
          pattern={pattern}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          required
          endIcon={
            codeIsLoading ? (
              <CircularProgress size={25} color="primary" />
            ) : (
              <></>
            )
          }
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <Button
          sx={{
            padding: '15px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            boxShadow: 'none',
            fontWeight: '300',
            color: 'primary.contrastText',
          }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          {t('continue')}
        </Button>
      </Box>
      <Typography
        sx={{
          marginBottom: '20px',
          lineHeight: '18px',
        }}
        variant="body2"
      >
        {t('login.agreement.text_1')}{' '}
        <Link to="/terms_of_use" component={NavLink} onClick={onClose}>
          {t('login.agreement.link_1')}
        </Link>{' '}
        {t('login.agreement.text_2')}{' '}
        <Link to="/privacy_policy" component={NavLink} onClick={onClose}>
          {t('login.agreement.link_2')}
        </Link>
      </Typography>
    </Box>
  );
};

export default LoginInputStep;

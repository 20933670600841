import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useFormContext } from 'react-hook-form';
import {
  selectCodeData,
  selectCodeIsLoading,
} from 'store/selectors/accountSelectors';
import { AppSettingsData } from 'types/AppSettings';

const useLoginInputStep = (nextStep: () => void) => {
  const { control, setValue } = useFormContext();
  const { success } = useAppSelector(selectCodeData);
  const codeIsLoading = useAppSelector(selectCodeIsLoading);
  const { AUTH_SERVICE_TYPE } = useAppSelector<AppSettingsData>(
    selectAppSettingsData
  );
  const { t } = useTranslation();
  const phoneValue = sessionStorage.getItem('phoneValue');

  useEffect(() => {
    if (success) {
      nextStep();
    }
  }, [success]);

  const title = useMemo(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      return t('login.title.phone_title');
    }

    return t('login.title.email_title');
  }, [AUTH_SERVICE_TYPE]);

  const text = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return t('login.tips.phone_tip_1');
    }

    if (AUTH_SERVICE_TYPE === 2) {
      return t('login.tips.phone_tip_5');
    }

    if (AUTH_SERVICE_TYPE === 3) {
      return t('login.tips.phone_tip_3');
    }

    return t('login.tips.email_tip_1');
  }, [AUTH_SERVICE_TYPE]);

  const inputLabel = useMemo(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      return t('phone_number');
    }

    return t('email');
  }, [AUTH_SERVICE_TYPE]);

  const pattern = useMemo(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    }
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  }, [AUTH_SERVICE_TYPE]);

  const mask = useMemo(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      return '+7 (999) 999-99-99';
    }
    return '';
  }, [AUTH_SERVICE_TYPE]);

  const maskPlaceholder = useMemo(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      return '';
    }
    return '';
  }, [AUTH_SERVICE_TYPE]);

  const inputType = useMemo(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      return 'tel';
    }
    return 'email';
  }, [AUTH_SERVICE_TYPE]);

  useEffect(() => {
    if (AUTH_SERVICE_TYPE !== 0) {
      const loginValue = phoneValue
        ? phoneValue.replace(/\D/g, '')
        : phoneValue;

      setValue('login', loginValue);
    }
  }, [phoneValue, AUTH_SERVICE_TYPE]);

  return {
    t,
    control,
    title,
    text,
    inputLabel,
    pattern,
    mask,
    maskPlaceholder,
    codeIsLoading,
    inputType,
  };
};

export default useLoginInputStep;

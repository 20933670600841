import React, { FC } from 'react';
import {
  Regular,
  Combo,
  Footer,
} from 'components/modals/ProductModal/Modal/Content';
import { Box, Divider } from '@mui/material';
import { useProductModal } from 'hooks/menu';
import { FormProvider } from 'react-hook-form';

interface ContentProps {
  onClose: () => void;
  productId: number;
  categoryId: number;
}

const Content: FC<ContentProps> = ({ onClose, productId, categoryId }) => {
  const {
    name,
    options,
    group_modifiers,
    ingredients,
    cooking_options,
    image,
    additional_images,
    extra_description,
    methods,
    onSubmit,
    isCombo,
    description,
    calculatedDescription,
    totalPrice,
    optionDesc,
    isDescription,
  } = useProductModal(onClose, productId, categoryId);

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            sx={{
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'grey.400',
                borderRadius: '20px',
              },
            }}
          >
            {isCombo ? (
              <Combo
                name={name}
                groupModifiers={group_modifiers}
                image={image}
                extraDescription={extra_description}
                calculatedDescription={calculatedDescription}
              />
            ) : (
              <Regular
                name={name}
                options={options}
                groupModifiers={group_modifiers}
                ingredients={ingredients}
                cookingOptions={cooking_options}
                image={image}
                additionalImages={additional_images}
                description={description}
                extraDescription={extra_description}
                optionDesc={optionDesc}
                isDescription={isDescription}
              />
            )}
          </Box>
          <Divider />
          <Footer
            price={totalPrice}
            description={description}
            isDescription={isDescription}
          />
        </Box>
      </FormProvider>
    </>
  );
};

export default Content;

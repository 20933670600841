import { createSelector } from '@reduxjs/toolkit';
import { selectAppSettingsData } from './appSelectors';
import { selectAvailablePickupRestaurantsList } from './restaurantSelectors';
import { selectBrandId } from './brandsSelectors';
import { selectPromotionNews } from './promotionSelectors';

/**
 * Returns a `{ delivery: bool, carryOut: bool }` object, telling
 * wether delivery or carry-out possible based on general and temporal
 * settings
 */
export const selectCarryOutAndDeliverySettings = createSelector(
  [selectAppSettingsData, selectAvailablePickupRestaurantsList],
  (appSettings, availableRestaurants) => {
    return {
      delivery: appSettings.DELIVERY && !appSettings.IS_DELIVERY_STOP,
      carryOut: appSettings.CARRY_OUT && availableRestaurants.length > 0,
    };
  }
);

export const selectCurrentBrandPromotionNews = createSelector(
  [selectPromotionNews, selectBrandId],
  (promotionNews, brandId) => {
    if (brandId === null) {
      return promotionNews;
    }

    return promotionNews.filter(
      (news) => news.brands.length === 0 || news.brands.includes(brandId)
    );
  }
);

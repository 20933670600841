import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/SelectCityModal';

interface SelectCityModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SelectCityModal: FC<SelectCityModalProps> = ({ isOpen, onClose }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer isOpen={isOpen} onClose={onClose} />
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

export default SelectCityModal;

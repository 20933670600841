import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import bg from 'assets/images/footerbg.png';
import { Review, ReviewsSlider } from 'components/home/Reviews';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Review as RevieType } from 'types/Pages';

interface ReviewsProps {
  data: RevieType[];
}

const Reviews: FC<ReviewsProps> = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative', padding: { xs: '30px 0', sm: '50px 0' } }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          opacity: '0.2',
          backgroundColor: '#DAD7DF',
          zIndex: '-1',
        }}
      >
        <Box
          sx={{
            objectFit: 'cover',
            objectPosition: 'center',
            width: '100%',
            height: '100%',
          }}
          component="img"
          src={bg}
        />
      </Box>

      <Container>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: { xs: '16px', sm: '23px', md: '30px' },
            lineHeight: { xs: '18px', sm: '25px', md: '36px' },
            fontWeight: '400',
            marginBottom: { xs: '20px', sm: '22px' },
            textAlign: 'center',
            textTransform: 'capitalize',
          }}
        >
          {t('home_page.how_sweet_of_you')}
        </Typography>
        {isMobile ? (
          <ReviewsSlider data={data} />
        ) : (
          <Box
            sx={{
              display: 'grid',
              columnGap: { xs: 2, sm: 3, md: 10 },
              rowGap: { xs: 2, sm: 3, md: 5 },
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              marginBottom: '43px',
            }}
          >
            {data.map((review) => (
              <Review
                key={review.id}
                text={review.text}
                name={review.name}
                ratingValue={5}
              />
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Reviews;

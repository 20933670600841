import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Switch } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useFormContext } from 'react-hook-form';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { Delivery, Pickup } from 'components/basket/BasketContent/Receiving';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks';
import { selectIsDelivery } from 'store/selectors/orderSelectors';
import { addressSlice } from 'store/reducers/AddressSlice/AddressSlice';
import { selectCarryOutAndDeliverySettings } from 'store/selectors/otherSelectors';

interface ReceivingProps {
  handleAddressButton: () => void;
}

const Receiving: FC<ReceivingProps> = ({ handleAddressButton }) => {
  const dispatch = useAppDispatch();
  const { selectDeliveryType, addClientData, addComment } = orderSlice.actions;
  const { addDefaultAddressId } = addressSlice.actions;
  const { clearPickupTime } = orderSlice.actions;
  const { watch } = useFormContext();
  const selectedAddressId = watch('place');
  const nameValue = watch('name');
  const phoneValue = watch('phone');
  const commentValue = watch('comment');
  const name = useDebounce(nameValue, 500);
  const phone = useDebounce(phoneValue, 500);
  const comment = useDebounce(commentValue, 500);
  const isDelivery = useAppSelector<boolean>(selectIsDelivery);
  const { carryOut, delivery: deliveryPossible } = useAppSelector(
    selectCarryOutAndDeliverySettings
  );
  const { t } = useTranslation();
  const [delivery, setDelivery] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      addClientData({
        name,
        phone,
        app_version: '1.0',
      })
    );
  }, [phone, name, dispatch]);

  useEffect(() => {
    dispatch(clearPickupTime());
  }, [isDelivery, dispatch]);

  useEffect(() => {
    dispatch(addDefaultAddressId(Number(selectedAddressId)));
  }, [selectedAddressId, dispatch]);

  useEffect(() => {
    dispatch(addComment(comment));
  }, [comment, dispatch]);

  const handleChangeReceiving = (deliveryValue: boolean) => () => {
    setDelivery(deliveryValue);
  };

  useEffect(() => {
    if (carryOut && !deliveryPossible) {
      dispatch(selectDeliveryType(1));
    } else if (!carryOut && deliveryPossible) {
      dispatch(selectDeliveryType(0));
    } else {
      dispatch(selectDeliveryType(Number(delivery)));
    }
  }, [carryOut, deliveryPossible, delivery, dispatch]);

  return (
    <Box sx={{ padding: { xs: '0px', md: '0 25px' } }}>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '320px' },
          marginBottom: { xs: '14px', sm: '40px' },
        }}
      >
        {carryOut && deliveryPossible && (
          <Switch
            firstValue={t('delivery')}
            secondValue={t('pickup')}
            value={delivery}
            handleChange={handleChangeReceiving}
          />
        )}
        {carryOut && !deliveryPossible && (
          <Box
            sx={{
              fontSize: { xs: '16px', sm: '20px' },
              lineHeight: { xs: '20px', sm: '26px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: { xs: '40px', sm: '50px' },
              borderRadius: { xs: '20px', sm: '30px' },
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              fontWeight: 600,
            }}
          >
            {t('pick_up_an_order_from')}
          </Box>
        )}
        {!carryOut && deliveryPossible && (
          <Box
            sx={{
              fontSize: { xs: '16px', sm: '20px' },
              lineHeight: { xs: '20px', sm: '26px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: { xs: '40px', sm: '50px' },
              borderRadius: { xs: '20px', sm: '30px' },
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              fontWeight: 600,
            }}
          >
            {t('deliver_to_the_address')}
          </Box>
        )}
      </Box>

      {carryOut && deliveryPossible && (
        <>
          {isDelivery ? (
            <Delivery handleAddressButton={handleAddressButton} />
          ) : (
            <Pickup />
          )}
        </>
      )}
      {carryOut && !deliveryPossible && <Pickup />}
      {!carryOut && deliveryPossible && (
        <Delivery handleAddressButton={handleAddressButton} />
      )}
    </Box>
  );
};

export default Receiving;

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateBookingQueryParams, ResponseBookingBody } from 'types/Booking';

export const createBooking = createAsyncThunk(
  'booking/createBooking',
  async (queryParams: CreateBookingQueryParams, thunkAPI) => {
    const { api_key, body } = queryParams;
    try {
      const response = await axios.post<ResponseBookingBody>(
        `${process.env.REACT_APP_V1}/booking/add_new/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to book' ${e}`);
    }
  }
);

import { useEffect } from 'react';
import { useDisableBodyScroll, useDelayUnmount } from 'hooks';
import { useWindowDimensions } from 'hooks/menu';
import { useSpring } from 'react-spring';

const useDrawer = (isOpen: boolean, handleClose: () => void) => {
  useDisableBodyScroll(isOpen);
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
  }));

  const openDrawer = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };

  const closeDrawer = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
    if (isOpen) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      openDrawer();
    } else {
      closeDrawer();
    }
  }, [isOpen]);
  return { isMount, y, closeDrawer };
};

export default useDrawer;

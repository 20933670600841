import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectAppSettings = (state: RootState) => state.appSettings;

/**
 * Returns app settings
 */
export const selectAppSettingsData = createSelector(
  [selectAppSettings],
  (appSettingsReducer) => appSettingsReducer.data
);
/**
 * Returns is loading server time
 */
export const selectServerTimeIsLoading = createSelector(
  [selectAppSettings],
  (appSettings) => appSettings.serverTimeIsLoading
);
/**
 * Returns serverTime
 */
export const selectServerTime = createSelector(
  [selectAppSettings],
  (appSettings) => appSettings.serverTime
);

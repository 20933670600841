import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Address } from 'types/Address';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';

export const getAddresses = createAsyncThunk(
  'address/getAddresses',
  async (api_key: string, thunkAPI) => {
    try {
      const response = await axios.get<Address[]>(
        `${process.env.REACT_APP_V2}/address/`,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Failed to get an address with an error: ${e}`
      );
    }
  }
);

export const addAddress = createAsyncThunk(
  'address/addAddress',
  async (data: { api_key: string; body: Address }, thunkAPI) => {
    const { api_key, body } = data;
    try {
      const response = await axios.post<Address>(
        `${process.env.REACT_APP_V2}/address/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Failed to add an address with an error: ${e}`
      );
    }
  }
);

export const deleteAddress = createAsyncThunk(
  'address/deleteAddress',
  async (data: { api_key: string; addressId?: number }, thunkAPI) => {
    const { api_key, addressId } = data;
    try {
      const response = await axios.delete<AnyAsyncThunk>(
        `${process.env.REACT_APP_V2}/address/${addressId}/`,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Failed to delete address with error ${e}`
      );
    }
  }
);

export const updateAddress = createAsyncThunk(
  'address/updateAddress',
  async (
    data: { api_key: string; body: Address; addressId?: number },
    thunkAPI
  ) => {
    const { api_key, addressId, body } = data;
    try {
      const response = await axios.patch<Address>(
        `${process.env.REACT_APP_V2}/address/${addressId}/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Failed to update the address with an error ${e}`
      );
    }
  }
);

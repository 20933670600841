import React, { FC } from 'react';
import { Dialog, IconButton, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/CloudPaymentModal/Modal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  hanldeGenCryptoKey: () => void;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, hanldeGenCryptoKey }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '25px',
          overflowY: 'inherit',
          maxWidth: '500px',
        },
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={onClose}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '25px',
        }}
      >
        <Content hanldeGenCryptoKey={hanldeGenCryptoKey} />
      </Box>
    </Dialog>
  );
};

export default Modal;

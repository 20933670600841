import React, { FC } from 'react';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { GroupModifier } from 'types/Menu';
import { ComboSlider } from 'components/modals/ProductModal/Drawer/Content/Combo';
import { Tooltip } from 'components/generic';
import { ComboMultipleSelector } from 'components/modals/ProductModal/Modal/Content/Combo';

interface ComboProps {
  name: string;
  groupModifiers: GroupModifier[];
  image: string;
  extraDescription: string;
  calculatedDescription: string;
}

const Combo: FC<ComboProps> = ({
  name,
  groupModifiers,
  image,
  extraDescription,
  calculatedDescription,
}) => {
  return (
    <Box
      sx={{
        padding: '0',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          height: '320px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          component="img"
          src={image}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          padding: '20px 20px 40px 20px',
        }}
      >
        <Stack sx={{ marginBottom: '20px' }} direction="row" spacing={1}>
          <Typography
            sx={{
              fontSize: '28px',
              fontWeight: '600',
              flexGrow: '1',
            }}
          >
            {name}
          </Typography>
          <Tooltip title={extraDescription} placement="bottom-end" />
        </Stack>

        <Typography sx={{ fontWeight: '600', marginBottom: '20px' }}>
          {calculatedDescription}
          <Divider />
        </Typography>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: 7,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: '20px',
            },
          }}
        >
          {groupModifiers.map((group) => (
            <Box>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '23px',
                  fontWeight: '400',
                }}
              >
                {group.choice_name}
              </Typography>
              {group.max_count > 1 ? (
                <ComboMultipleSelector
                  modifiers={group.modifiers}
                  id={group.id}
                  maxCount={group.max_count}
                  minCount={group.min_count}
                />
              ) : (
                <ComboSlider modifiers={group.modifiers} id={group.id} />
              )}
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Combo;

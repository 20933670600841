import React, { FC } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/ProductModal/Modal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
  categoryId: number;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, productId, categoryId }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          maxWidth: { xs: '690px', md: '835px' },
          overflowY: 'inherit',
          lineHeight: '1',
        },
      }}
    >
      <Box
        sx={{
          padding: '30px',
          paddingRight: '23px',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
          onClick={onClose}
          size="large"
          aria-label="close-btn"
        >
          <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
        </IconButton>
        <Content
          onClose={onClose}
          productId={productId}
          categoryId={categoryId}
        />
      </Box>
    </Dialog>
  );
};

export default Modal;

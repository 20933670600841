import React, { FC } from 'react';
import { Box, Zoom, useScrollTrigger } from '@mui/material';

interface ScrollToTopProps {
  children: React.ReactElement;
}

const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 100 }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

export default ScrollToTop;

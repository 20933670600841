import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectPagesData = (state: RootState) => state.pages;
/**
 * Returns page data
 */
export const selectPage = (slug: string) =>
  createSelector([selectPagesData], (pagesData) =>
    pagesData.pages.find((page) => page.slug === slug)
  );
/**
 * Returns data for pages
 */
export const selectPageData = (pageName: string) =>
  createSelector([selectPage(pageName)], (page) => page?.fields);

/**
 * Returns data for delivery page
 */
export const selectImagesData = createSelector(
  [selectPage('images')],
  (page) => {
    return {
      image404: page?.fields?.page_404 || '',
      footer_mobile_bg: page?.fields?.footer_mobile_bg || '',
      footer_desktop_bg: page?.fields?.footer_desktop_bg || '',
      dpl_logo: page?.fields?.dpl_logo || '',
      qr_code: page?.fields?.qr_code || '',
      qr_slider: page?.fields?.qr_slider || [],
    };
  }
);

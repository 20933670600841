import React, { FC } from 'react';
import { Stack } from '@mui/material';
import {
  LoginButton,
  BasketButton,
} from 'components/generic/Header/HeaderButtons';

const HeaderButtons: FC = () => {
  return (
    <Stack direction="row" spacing={2}>
      <LoginButton />
      <BasketButton />
    </Stack>
  );
};

export default HeaderButtons;

import React, { FC, useEffect, useState } from 'react';
import { Box, RadioGroup, Typography } from '@mui/material';
import { GroupModifier, Modifier } from 'types/Menu';
import { OptionCheckbox, OptionRadioButton } from 'components/generic';

interface GroupModifiersProps {
  groupModifiers: GroupModifier[];
}

const GroupModifiers: FC<GroupModifiersProps> = ({ groupModifiers }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelect = (value: number, maxCount: number, minCount: number) => {
    setSelectedItems((prevSelectedItems) => {
      const isPresent = prevSelectedItems.includes(value);
      const count = prevSelectedItems.length;

      // "Активированный модификатор" --- модификатор, находящийся в списке
      // `prevSelectedItems`, то есть активированный.
      // "Нажатый модификатор" --- модификатор, на который пользователь
      // сейчас нажал.

      // Нажали на активированный модификатор == предпринимаем попытку
      // деактивировать модификатор
      if (isPresent) {
        // Если количество активированных модификаторов равно минимальному,
        // не удалять нажатый модификатор и вернуть список без изменений
        if (count === minCount) {
          return prevSelectedItems;
        }

        // Иначе возвращаем список без нажатого модификаторв
        return prevSelectedItems.filter((item) => item !== value);
      }

      // Нажали на неактивированный модификатор == предпринимам попытку активировать
      // модификатор

      // Если количество активированных модификаторов равно максимальному,
      // то удаляем первый активированный модификатор и добавляем нажатый модификатор
      if (count === maxCount) {
        const newValue = prevSelectedItems.slice(1);
        newValue.push(value);
        return newValue;
      }

      // Иначе, если не достигли максимального числа активированны модификаторов,
      // активировать нажатый модификатор
      return [...prevSelectedItems, value];
    });
  };

  useEffect(() => {
    groupModifiers.forEach((groupModifier) => {
      setSelectedItems(
        groupModifier.modifiers
          .slice(0, groupModifier.min_count)
          .map((modifier) => modifier.id)
      );
    });
  }, []);

  return (
    <>
      {groupModifiers.map((group) => (
        <Box sx={{ marginBottom: '14px' }} key={group.id}>
          <Typography
            sx={{ fontWeight: '400', marginBottom: '10px' }}
            variant="h6"
          >
            {group.name}
          </Typography>
          {group.max_count < 1 ? (
            <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              aria-label={group.name}
              defaultValue={group.modifiers[0].id}
              name="radio-buttons-group"
            >
              {group.modifiers.map((modifier) => (
                <OptionRadioButton
                  key={modifier.id}
                  label={modifier.name}
                  group={group.id}
                  value={modifier.id}
                  price={modifier.price_kop}
                  defaultValue={group.modifiers[0].id}
                />
              ))}
            </RadioGroup>
          ) : (
            <>
              {group.modifiers.map((modifier: Modifier) => (
                <OptionCheckbox
                  selectedItems={selectedItems}
                  handleSelect={handleSelect}
                  key={modifier.id}
                  label={modifier.name}
                  groupId={group.id}
                  value={modifier.id}
                  price={modifier.price_kop}
                  maxCount={group.max_count}
                  minCount={group.min_count}
                />
              ))}
            </>
          )}
        </Box>
      ))}
    </>
  );
};

export default GroupModifiers;

import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/PromotionModal';

interface PromotionModalProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
  title: string;
  description: string;
  date: string;
}

const PromotionModal: FC<PromotionModalProps> = ({
  isOpen,
  onClose,
  src,
  title,
  description,
  date,
}) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          src={src}
          title={title}
          description={description}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          src={src}
          title={title}
          description={description}
          date={date}
        />
      )}
    </>
  );
};

export default PromotionModal;

import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

interface MainTitleProps {
  children: ReactNode;
}

const MainTitle: FC<MainTitleProps> = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Montserrat',
        fontSize: { xs: '24px', sm: '30px' },
        lineHeight: { xs: '28px', sm: '30px' },
        fontWeight: '400',
        color: 'text.main',
      }}
      variant="h2"
    >
      {children}
    </Typography>
  );
};

export default MainTitle;

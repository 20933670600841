import { useState, useEffect } from 'react';

const useDataForDispatch = (dayValue: string, timeValue: string) => {
  const [bookingTime, setBookingTime] = useState<string>('');

  useEffect(() => {
    const date = new Date(dayValue).toLocaleString('fr-CA').slice(0, 10);
    setBookingTime(`${date} ${timeValue}`);
  }, [dayValue, timeValue]);

  return bookingTime;
};

export default useDataForDispatch;

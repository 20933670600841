import React, { FC } from 'react';
import { Button, Box, Skeleton, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAuth } from 'hooks/auth';
import { SelectField } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import {
  selectAddressesList,
  selectAddressIsLoading,
  selectDefaultAddressId,
} from 'store/selectors/addressSelectors';
import { useTranslation } from 'react-i18next';

interface AddressFormProps {
  handleAddressButton: () => void;
}

const AddressForm: FC<AddressFormProps> = ({ handleAddressButton }) => {
  const { control } = useFormContext();
  const { isAuth } = useAuth();
  const addressesList = useAppSelector(selectAddressesList);
  const isLoading = useAppSelector(selectAddressIsLoading);
  const addressId = useAppSelector(selectDefaultAddressId);
  const { t } = useTranslation();

  return (
    <>
      {isAuth && (
        <Box sx={{ marginBottom: { xs: '12px', sm: '20px' } }}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={45} />
          ) : (
            <SelectField
              control={control}
              name="place"
              label={t('delivery_at')}
              required
              size="small"
              selectItem={addressesList}
              defaultValue={addressId || ''}
            />
          )}
        </Box>
      )}
      <Button
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          border: 'none',
          background: 'transparent',
          padding: '2px',
          textDecoration: 'none',
          WebkitTapHighlightColor: 'transparent',
          textTransform: 'inherit',
        }}
        onClick={handleAddressButton}
      >
        <AddIcon color="secondary" sx={{ marginRight: '10px' }} />
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '20px' },
            lineHeight: { xs: '16px', sm: '23px' },
            fontWeight: '600',
            color: 'secondary.main',
          }}
        >
          {t('add_new_address')}
        </Typography>
      </Button>
    </>
  );
};

export default AddressForm;

import React, { FC } from 'react';
import { Box, RadioGroup, Typography } from '@mui/material';
import { CookingOption } from 'types/Menu';
import { OptionRadioButton } from 'components/generic';
import { useTranslation } from 'react-i18next';

interface CookingOptionsProps {
  cookingOptions: CookingOption[];
}

const CookingOptions: FC<CookingOptionsProps> = ({ cookingOptions }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '20px' },
          lineHeight: { xs: '19px', sm: '23px' },
          marginBottom: '10px',
        }}
      >
        {t('cooking_options')}
      </Typography>

      <Box sx={{ marginBottom: '14px' }}>
        <RadioGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          defaultValue={cookingOptions[0].id}
          name="cooking-options"
        >
          {cookingOptions.map((option) => (
            <OptionRadioButton
              key={option.id}
              label={option.name}
              group="cooking_options"
              value={option.id}
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default CookingOptions;

import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ListItem } from 'components/modals/SelectCityModal/Drawer/CitiesList';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { citySlice } from 'store/reducers/CitySlice/CitySlice';

interface FilteredCity {
  label: string;
  value: number;
}

interface CitiesListProps {
  cities: FilteredCity[];
  onClose: () => void;
}

const CitiesList: FC<CitiesListProps> = ({ cities, onClose }) => {
  const dispatch = useAppDispatch();
  const { selectCity } = citySlice.actions;

  const handleSelectCity = (cityId: number) => () => {
    dispatch(selectCity(cityId));
    onClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '25px 0px',
        height: 'calc(100% - 50vh - 258px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: 7,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey.400',
          borderRadius: '20px',
        },
      }}
    >
      {cities.map((city) => (
        <ListItem
          key={city.value}
          label={city.label}
          value={city.value}
          handleClick={handleSelectCity}
        />
      ))}
    </Box>
  );
};

export default CitiesList;

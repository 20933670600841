import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';

const MobileOffers = () => {
  const { t } = useTranslation();
  const homePageData = useAppSelector(selectPageData('home'));

  if (!homePageData) {
    return null;
  }

  const {
    offer_mobile_image_1,
    offer_mobile_image_2,
    offer_mobile_image_3,
    offer_mobile_bg_1,
    offer_mobile_bg_2,
    offer_mobile_bg_3,
  } = homePageData;

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '130px',
          marginBottom: '40px',
          textAlign: 'right',
          padding: '20px 0',
        }}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              lineHeight: '22px',
              fontWeight: '600',
              color: 'text.primary',
              padding: '6px 20px',
              backgroundColor: 'primary.main',
              marginBottom: '17px',
              alignSelf: 'flex-end',
            }}
          >
            {t('home_page.offer_title_1')}
          </Typography>
          <Button
            sx={{
              fontSize: '18px',
              color: 'secondary.main',
              fontWeight: '300',
              textTransform: 'inherit',
              borderRadius: '10px',
              backgroundColor: '#ffffff',
              boxShadow: 'none',
              marginRight: '8px',
            }}
            to="/menu"
            component={Link}
            variant="contained"
          >
            {t('order_now')}
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-20px',
            left: '0px',
            width: '220px',
            height: '220px',
            zIndex: '2',
          }}
        >
          <Box
            component="img"
            src={offer_mobile_image_1}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            zIndex: '-1',
          }}
        >
          <Box
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            component="img"
            src={offer_mobile_bg_1}
          />
        </Box>
      </Box>
      {/* ========================================================================== */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '130px',
          marginBottom: '40px',
          textAlign: 'left',
          padding: '20px 0',
        }}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              lineHeight: '22px',
              fontWeight: '600',
              color: 'text.primary',
              padding: '6px 20px',
              backgroundColor: 'primary.main',
              marginBottom: '17px',
              alignSelf: 'flex-start',
            }}
          >
            {t('home_page.offer_title_2')}
          </Typography>
          <Button
            sx={{
              fontSize: '18px',
              color: 'secondary.main',
              fontWeight: '300',
              textTransform: 'inherit',
              borderRadius: '10px',
              backgroundColor: '#ffffff',
              boxShadow: 'none',
              marginLeft: '8px',
            }}
            to="/menu"
            component={Link}
            variant="contained"
          >
            {t('order_now')}
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-20px',
            right: '0px',
            width: '150px',
            height: '170px',
            zIndex: '2',
          }}
        >
          <Box
            component="img"
            src={offer_mobile_image_2}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            zIndex: '-1',
          }}
        >
          <Box
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
            component="img"
            src={offer_mobile_bg_2}
          />
        </Box>
      </Box>
      {/* ========================================================================== */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '130px',
          marginBottom: '40px',
          textAlign: 'right',
          padding: '20px 0',
        }}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              lineHeight: '22px',
              fontWeight: '600',
              color: 'text.primary',
              padding: '6px 20px',
              backgroundColor: 'primary.main',
              marginBottom: '17px',
              alignSelf: 'flex-end',
            }}
          >
            {t('home_page.offer_title_3')}
          </Typography>
          <Button
            sx={{
              fontSize: '18px',
              color: 'secondary.main',
              fontWeight: '300',
              textTransform: 'inherit',
              borderRadius: '10px',
              backgroundColor: '#ffffff',
              boxShadow: 'none',
              marginRight: '8px',
            }}
            to="/menu"
            component={Link}
            variant="contained"
          >
            {t('order_now')}
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-30px',
            left: '0px',
            width: '130px',
            height: '160px',
            zIndex: '2',
          }}
        >
          <Box
            component="img"
            src={offer_mobile_image_3}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            zIndex: '-1',
          }}
        >
          <Box
            sx={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            component="img"
            src={offer_mobile_bg_3}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileOffers;

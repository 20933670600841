import React, { FC, useEffect, useRef, useState } from 'react';
import { useYMaps } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { Box, Typography } from '@mui/material';

interface DeliveryMapProps {
  userCoords: number[];
  restaurantCoords: number[];
}

const DeliveryMap: FC<DeliveryMapProps> = ({
  userCoords,
  restaurantCoords,
}) => {
  const mapRef = useRef(null);
  const ymaps = useYMaps(['Map', 'multiRouter.MultiRoute']);
  const [deliveryTime, setDeliveryTime] = useState<string | null>(null);
  const [map, setMap] = useState<ymaps.Map | null>(null);

  const centerPoint = (first: number[], second: number[]) =>
    first.map((el, idx) => (el + second[idx]) / 2);

  const buildMultiRoute = (ymaps: YMapsApi) => {
    const route = new ymaps.multiRouter.MultiRoute(
      {
        params: {
          routingMode: 'auto',
        },
        referencePoints: [userCoords, restaurantCoords],
      },
      {
        boundsAutoApply: true,
      }
    );

    route.model.events.add('requestsuccess', () => {
      const activeRoute = route.getActiveRoute();
      setDeliveryTime(
        (
          activeRoute?.properties.get('duration', { text: null }) as {
            text: string | null;
          }
        ).text
      );
    });

    return route;
  };

  useEffect(() => {
    if (!ymaps || !mapRef.current) {
      return;
    }

    setMap(
      new ymaps.Map(mapRef.current, {
        center: centerPoint(userCoords, restaurantCoords),
        zoom: 13,
      })
    );
  }, [ymaps]);

  useEffect(() => {
    if (!ymaps || !mapRef.current || !map) {
      return;
    }

    map.geoObjects.removeAll();
    map.geoObjects.add(buildMultiRoute(ymaps));
  }, [...userCoords, ...restaurantCoords, map]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '4px',
        width: '100%',
        height: '100%',
      }}
    >
      <div ref={mapRef} style={{ width: '100%', height: '350px' }} />
      {deliveryTime && (
        <Typography>Приблизительное время доставки: {deliveryTime}</Typography>
      )}
    </Box>
  );
};

export default DeliveryMap;

import React, { FC } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { TextArea } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { postFeedback } from 'store/reducers/AccountSlice/ActionCreators';
import {
  selectAccountIsLoading,
  selectApiKey,
} from 'store/selectors/accountSelectors';

const Comment: FC = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useFormContext();
  const isLoading = useAppSelector(selectAccountIsLoading);
  const dispatch = useAppDispatch();
  const api_key = useAppSelector<string>(selectApiKey);

  const onSubmit = (formValues: any) => {
    dispatch(postFeedback({ api_key, body: formValues }));
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        sx={{
          fontSize: '30px',
          lineHeight: '30px',
          fontWeight: '400',
          textAlign: 'center',
          marginBottom: '25px',
        }}
      >
        {t('review_page.comment.way_to_fix_it')}
      </Typography>
      <Typography
        sx={{
          maxWidth: '500px',
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          marginBottom: '30px',
        }}
      >
        {t('review_page.comment.comment_text')}
      </Typography>
      <Box sx={{ marginBottom: '40px' }}>
        <TextArea
          control={control}
          name="text"
          label={t('review_page.comment.your_review')}
          rows={8}
        />
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            height: '50px',
            fontWeight: '300',
            width: '50%',
          }}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t('review_page.comment.send')}
        </Button>
      )}
    </Box>
  );
};

export default Comment;

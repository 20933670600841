import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Parse from 'html-react-parser';

interface DeliveryItemProps {
  title: string;
  text: string;
}

const DeliveryItem: FC<DeliveryItemProps> = ({ title, text }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '18px', sm: '22px' },
          lineHeight: { xs: '22px', sm: '26px' },
          fontWeight: '600',
        }}
        component="h4"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '18px' },
          lineHeight: { xs: '18px', sm: '22px' },
          fontWeight: '300',
        }}
        component="div"
      >
        {Parse(text)}
      </Typography>
    </Box>
  );
};

export default DeliveryItem;

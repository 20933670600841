import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectOrderHistoryList } from 'store/selectors/orderSelectors';
import { useAuth } from 'hooks/auth';
import { useNavigate } from 'react-router-dom';
import { postFeedback } from 'store/reducers/AccountSlice/ActionCreators';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import {
  selectAccountIsLoading,
  selectApiKey,
} from 'store/selectors/accountSelectors';
import { getOrderHistory } from 'store/reducers/OrderSlice/ActionCreators';
import { selectAvailableRestaurantsList } from 'store/selectors/restaurantSelectors';

const useReviews = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { control, setValue, watch, handleSubmit } = useFormContext();
  const orders = useAppSelector(selectOrderHistoryList(t('order')));
  const isAccountLoading = useAppSelector(selectAccountIsLoading);
  const starsKitchenValue = watch('stars_kitchen');
  const starsServiceValue = watch('stars_service');
  const { isAuth } = useAuth();
  const [authModal, setAuthModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { clearFeedback } = accountSlice.actions;
  const api_key = useAppSelector(selectApiKey);
  const restaurantList = useAppSelector(selectAvailableRestaurantsList);
  const [isVisiting, setIsVisiting] = useState<boolean>(false);

  const handleChange = (switchValue: boolean) => () => {
    if (switchValue) {
      setValue('order_id', null);
      setValue('type', 1);
    } else {
      setValue('rest_id', null);
      setValue('type', 2);
    }
    setIsVisiting(switchValue);
  };

  const handleAuthModalOpen = useCallback(() => {
    setAuthModal(true);
  }, [authModal]);

  const handleAuthModalClose = useCallback(() => {
    setAuthModal(false);
  }, [authModal]);

  const onSubmit = (formValues: any) => {
    dispatch(postFeedback({ api_key, body: formValues }))
      .unwrap()
      .then((res) => {
        if (res.success === true) {
          navigate('/reviews/high');
          dispatch(clearFeedback());
        }
      });
  };

  const nextStep = (isHighRating: boolean) => {
    if (isHighRating) {
      handleSubmit(onSubmit)();
    } else {
      navigate('/reviews/low');
    }
  };

  const handleButton = useMemo(() => {
    const isHighRating = starsKitchenValue > 3 && starsServiceValue > 3;
    if (isAuth) {
      return () => nextStep(isHighRating);
    }
    return handleAuthModalOpen;
  }, [isAuth, starsKitchenValue, starsServiceValue]);

  useEffect(() => {
    dispatch(getOrderHistory(api_key));
  }, [api_key]);

  useEffect(() => {
    if (orders.length > 0) {
      setIsVisiting(false);
      setValue('type', 2);
      setValue('order_id', null);
      setValue('rest_id', null);
    } else {
      setIsVisiting(true);
      setValue('type', 1);
      setValue('order_id', null);
      setValue('rest_id', null);
    }
  }, [orders.length]);

  useEffect(() => {
    setValue('stars_kitchen', 5);
    setValue('stars_service', 5);
    setValue('platform', 'site');
    setValue('source', 3);
  }, []);

  return {
    t,
    control,
    isVisiting,
    isAccountLoading,
    handleChange,
    authModal,
    handleAuthModalClose,
    handleButton,
    orders,
    restaurantList,
  };
};

export default useReviews;

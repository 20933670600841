import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MasterCardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="7" cy="12" r="7" fill="#EA001B" />
        <circle cx="17" cy="12" r="7" fill="#FFA200" fillOpacity=".8" />
      </g>
    </SvgIcon>
  );
};

export default MasterCardIcon;

import { useState, useMemo, useEffect, useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { checkDelivery } from 'store/reducers/OrderSlice/ActionCreators';
import { useAddressQueryData, useAddressView } from 'hooks/address';
import {
  addAddress,
  deleteAddress,
  updateAddress,
} from 'store/reducers/AddressSlice/ActionCreators';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { selectAddressById } from 'store/selectors/addressSelectors';
import { CheckDeliveryResponse } from 'types/Delivery';

const useAddressModal = (
  modalType: 'add' | 'update',
  onClose: () => void,
  addressId?: number
) => {
  const addressValues = useAppSelector(selectAddressById(addressId));
  const methods = useForm();
  const { watch, setValue } = methods;
  const formValues = watch();
  const dispatch = useAppDispatch();
  const api_key = useAppSelector(selectApiKey);
  const [isCheckDelivery, setIsCheckDelivery] = useState<boolean>(false);
  const addressQueryBody = useAddressQueryData(
    modalType,
    formValues,
    addressId
  );
  const addressView = useAddressView(formValues);

  const isRuForm = useMemo(() => {
    return process.env.REACT_APP_IS_RU_FORM === 'true';
  }, []);

  const onSubmit = (formData: FieldValues) => {
    dispatch(
      checkDelivery({
        api_key,
        body: {
          city_id: formData.city,
          street: formData.street,
          house: formData.house,
        },
      })
    )
      .unwrap()
      .then((res: CheckDeliveryResponse) => {
        if (res.success) {
          if (modalType === 'update') {
            dispatch(
              updateAddress({
                api_key,
                addressId,
                body: {
                  ...addressQueryBody,
                  latitude: res.data?.latitude,
                  longitude: res.data?.longitude,
                },
              })
            );
          } else {
            dispatch(
              addAddress({
                api_key,
                body: {
                  ...addressQueryBody,
                  latitude: res.data?.latitude,
                  longitude: res.data?.longitude,
                },
              })
            );
          }
        }
      });
    setIsCheckDelivery(true);
  };

  const handleClose = useCallback(() => {
    onClose();
    setIsCheckDelivery(false);
  }, [isCheckDelivery]);

  const handleBackToForm = useCallback(() => {
    setIsCheckDelivery(false);
  }, [isCheckDelivery]);

  const handleDeleteAddress = () => {
    dispatch(deleteAddress({ api_key, addressId }));
    onClose();
  };

  useEffect(() => {
    setValue('name', addressValues?.name);
    setValue('city', addressValues?.city);
    setValue('street', addressValues?.street);
    setValue('house', addressValues?.house);
    setValue('apartment', addressValues?.apartment);
    setValue('entrance', addressValues?.entrance);
    setValue('floor', addressValues?.floor);
    // eu fields
    if (addressValues?.area) {
      setValue('area', addressValues?.area);
    }
  }, [addressValues]);

  return {
    isRuForm,
    methods,
    isCheckDelivery,
    addressView,
    onSubmit,
    handleBackToForm,
    handleDeleteAddress,
    handleClose,
  };
};

export default useAddressModal;

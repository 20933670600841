import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextField } from 'components/generic';
import { usePaymentCardNumber } from 'hooks/order';

const PaymentForm: FC = () => {
  const { control, watch } = useFormContext();
  const cardNumberValue = watch('card_number');
  const { cardNumberMask, cardIcon } = usePaymentCardNumber(cardNumberValue);

  return (
    <Stack direction="column" spacing={2}>
      <TextField
        control={control}
        name="card_number"
        label="Номер карты"
        size="small"
        mask={cardNumberMask}
        maskPlaceholder={null}
        data-cp="cardNumber"
        required
        endIcon={cardNumberValue ? cardIcon : <></>}
      />
      <Stack
        sx={{ justifyContent: 'space-between' }}
        direction="row"
        spacing={2}
      >
        <TextField
          sx={{ maxWidth: '120px' }}
          control={control}
          name="exp_date_month_year"
          label="ММ/ГГ"
          size="small"
          mask="99/99"
          maskPlaceholder={null}
          data-cp="expDateMonthYear"
          required
        />
        <TextField
          sx={{ maxWidth: '90px' }}
          control={control}
          name="cvv"
          label="CVV"
          size="small"
          mask="999"
          maskPlaceholder={null}
          data-cp="cvv"
          required
        />
      </Stack>
    </Stack>
  );
};

export default PaymentForm;

import React, { FC, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTimeList } from 'hooks';
import { DatePicker, SelectField } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { selectTimeZone } from 'store/selectors/citySelectors';
import moment from 'moment-timezone';
import { selectTimedOrderSchedule } from 'store/selectors/orderSelectors';

interface DateTimePickerProps {
  type: 'delivery' | 'pickup';
}

const DateTimePicker: FC<DateTimePickerProps> = ({ type }) => {
  const dispatch = useAppDispatch();
  const { addPickupTime } = orderSlice.actions;
  const { control, watch, resetField } = useFormContext();
  const dayValue = watch('day');
  const timeValue = watch('time');
  const { availableTimeList, minDate, maxDate } = useTimeList(dayValue);
  const timeZone = useAppSelector<string>(selectTimeZone);
  const { t } = useTranslation();
  const timedOrderSchedule = useAppSelector(selectTimedOrderSchedule);

  useEffect(() => {
    resetField('time');
  }, [dayValue]);

  useEffect(() => {
    resetField('time');
    resetField('day');
  }, [timedOrderSchedule]);

  useEffect(() => {
    const time = moment(timeValue, 'HH:mm');
    const momentObj = moment(dayValue?.$d);
    const date = momentObj
      .tz(timeZone)
      .minutes(time.get('minutes'))
      .hours(time.get('hours'))
      .format();

    if (dayValue) {
      dispatch(addPickupTime(date));
    }
  }, [dayValue, timeValue]);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ marginBottom: { xs: '13px', sm: '30px' }, width: '100%' }}
      spacing={{ xs: 2, sm: 1.6 }}
    >
      {minDate && maxDate && (
        <DatePicker
          control={control}
          name="day"
          label={type === 'delivery' ? t('delivery_date') : t('day')}
          minDate={minDate.toDate()}
          maxDate={maxDate.toDate()}
          required
        />
      )}

      <SelectField
        control={control}
        name="time"
        label={type === 'delivery' ? t('delivery_time_slot') : t('time')}
        required
        selectItem={availableTimeList ?? []}
        defaultValue=""
      />
    </Stack>
  );
};

export default DateTimePicker;

import React, { FC } from 'react';
import { Box, Card as MUICard, Typography } from '@mui/material';
import { useSrcImage, useTrimmed } from 'hooks';

interface CardProps {
  name: string;
  description: string;
  src: string;
}

const Card: FC<CardProps> = ({ name, description, src }) => {
  const image = useSrcImage(src);
  const trimmedDescription = useTrimmed(description, 50);

  return (
    <MUICard
      sx={{
        display: 'flex',
        height: '112px',
        boxShadow: 'none',
        borderRadius: '0',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '112px',
          maxWidth: '112px',
          width: '100%',
          height: '112px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          component="img"
          src={image}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '4px 10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 400,
            marginBottom: '10px',
          }}
          variant="h5"
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: '15px',
            color: 'grey.600',
          }}
        >
          {trimmedDescription}
        </Typography>
      </Box>
    </MUICard>
  );
};

export default Card;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InstagramIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.735 0 31.8351 6.99782 31.9972 15.6959L32 16C32 24.735 25.0022 31.8351 16.3041 31.9972L16 32C7.16344 32 0 24.8385 0 16C0 7.26501 6.99782 0.164898 15.6959 0.00283092L16 0ZM19.774 7.52941H12.226C9.64077 7.52941 7.52941 9.63214 7.52941 12.226V19.774C7.52941 22.3592 9.63214 24.4706 12.226 24.4706H19.774C22.3592 24.4706 24.4706 22.3679 24.4706 19.774V12.226C24.4706 9.64077 22.3679 7.52941 19.774 7.52941Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4855 9.03516C21.4069 9.03516 22.9646 10.5925 22.9646 12.5142V19.4855C22.9646 21.4069 21.4072 22.9646 19.4855 22.9646H12.5142C10.5928 22.9646 9.03516 21.4072 9.03516 19.4855V12.5142C9.03516 10.5928 10.5925 9.03516 12.5142 9.03516H19.4855ZM15.9999 11.6705C13.6088 11.6705 11.6705 13.6088 11.6705 15.9999C11.6705 18.391 13.6088 20.3293 15.9999 20.3293C18.391 20.3293 20.3293 18.391 20.3293 15.9999C20.3293 13.6088 18.391 11.6705 15.9999 11.6705ZM20.7058 10.3528C20.186 10.3528 19.7646 10.7742 19.7646 11.294C19.7646 11.8138 20.186 12.2352 20.7058 12.2352C21.2256 12.2352 21.6469 11.8138 21.6469 11.294C21.6469 10.7742 21.2256 10.3528 20.7058 10.3528Z"
      />
      <path d="M15.9999 19.1058C17.7153 19.1058 19.1058 17.7153 19.1058 15.9999C19.1058 14.2846 17.7153 12.894 15.9999 12.894C14.2846 12.894 12.894 14.2846 12.894 15.9999C12.894 17.7153 14.2846 19.1058 15.9999 19.1058Z" />
    </SvgIcon>
  );
};

export default InstagramIcon;

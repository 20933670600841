import { createSelector } from '@reduxjs/toolkit';
import { Promotion } from 'types/Promotion';
import { RootState } from 'store/store';

const selectPromotions = (state: RootState) => state.promotions;
const selectCalculatedBasket = (state: RootState) =>
  state.order.calculatedBasket;

/**
 * Returns all promotions data
 */
export const selectPromotionsData = createSelector(
  [selectPromotions],
  (promotions) => promotions.promotions
);
/**
 * Returns promotions data is loading
 */
export const selectPromotionsDataIsLoading = createSelector(
  [selectPromotions],
  (promotions) => promotions.isLoading
);
/**
 * Returns promo without promocode
 */
export const selectPromotionsWithoutPromocode = createSelector(
  [selectPromotionsData],
  (promotionsData) =>
    promotionsData.filter((promo) => !promo.is_promo_code && promo.type !== 0)
);
/**
 * Returns promo with promocode
 */
export const selectPromotionsWithPromocode = createSelector(
  [selectPromotionsData],
  (promotionsData) => promotionsData.filter((promo) => promo.is_promo_code)
);

export const selectOtherPromotions = (promotionId: number) =>
  createSelector(
    [selectPromotionsWithoutPromocode],
    (promotions: Promotion[]) =>
      promotions.filter((promotion: Promotion) => promotion.id !== promotionId)
  );
/**
 * Returns filtered promo for the basket
 */
export const selectBasketPromotion = createSelector(
  [selectPromotionsWithoutPromocode],
  (promotions: Promotion[]) =>
    promotions.filter(
      (promotion) => promotion.is_show_basket === true && promotion.type === 2
    )
);
/**
 * Returns available promo
 */
export const selectAvailablePromotions = createSelector(
  [selectBasketPromotion, selectCalculatedBasket],
  (promotions: Promotion[], calculatedBasket) =>
    promotions.filter((promotion: Promotion) => {
      const availablePromotions = calculatedBasket.data?.available_actions;
      const basketPromotions = calculatedBasket.data?.actions;

      return (
        availablePromotions?.includes(promotion.id) ||
        basketPromotions?.includes(promotion.id)
      );
    })
);
export const selectPromotionNews = createSelector(
  [selectPromotionsData],
  (promotions) =>
    promotions.filter(
      (promotion) => promotion.type === 0 || promotion.type === 3
    )
);
export const selectBrandPromotionsWithoutPromocode = (brandId: number) =>
  createSelector(
    [selectPromotionsWithoutPromocode],
    (promotions: Promotion[]) =>
      promotions.filter(
        (promotion) =>
          promotion.brands.length === 0 || promotion.brands.includes(brandId)
      )
  );

import React, { FC, useEffect, useState } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useFormContext } from 'react-hook-form';
import { SelectField } from 'components/generic';
import { DateTimePicker } from 'components/basket/BasketContent/Receiving/DeliveryTimeForm';
import { useTranslation } from 'react-i18next';
import { useReadyTime } from 'hooks/basket';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { selectServerTimeIsLoading } from 'store/selectors/appSelectors';
import { selectCitiesIsLoading } from 'store/selectors/citySelectors';

const DeliveryTimeForm: FC = () => {
  const dispatch = useAppDispatch();
  const { control, watch, setValue } = useFormContext();
  const receiving = watch('receiving');
  const citiesIsLoading = useAppSelector(selectCitiesIsLoading);
  const serverTimeIsLoading = useAppSelector(selectServerTimeIsLoading);
  const [isSelectTimeFrom, setIsSelectTimeForm] = useState<boolean>(false);
  const { t } = useTranslation();
  const selectTimeData = useReadyTime();
  const { clearPickupTime, addCallBack } = orderSlice.actions;

  useEffect(() => {
    dispatch(addCallBack());
  }, []);

  useEffect(() => {
    setValue('delivery_time', 1);
  }, []);

  useEffect(() => {
    if (receiving === 2) {
      setIsSelectTimeForm(true);
    } else {
      setIsSelectTimeForm(false);
      dispatch(clearPickupTime());
    }
  }, [receiving]);

  return (
    <>
      <Box
        sx={{
          width: { xs: '100%', sm: '49%' },
        }}
      >
        <SelectField
          control={control}
          name="receiving"
          label={t('delivery_time')}
          required
          size="small"
          selectItem={selectTimeData}
          defaultValue={1}
        />
      </Box>
      {isSelectTimeFrom && (
        <Stack
          sx={{ marginBottom: { xs: '16px', sm: '30px' } }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1.6}
        >
          {citiesIsLoading && serverTimeIsLoading ? (
            <Skeleton variant="rectangular" height={50} />
          ) : (
            <DateTimePicker type="delivery" />
          )}
        </Stack>
      )}
    </>
  );
};

export default DeliveryTimeForm;

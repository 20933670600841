import React, { FC } from 'react';
import { Box, Container, Skeleton, Typography } from '@mui/material';
import Parser from 'html-react-parser';

interface MainBannerProps {
  src: string;
  logo: string;
  title?: string;
  text?: string;
  textColor?: string;
}

const MainBanner: FC<MainBannerProps> = ({
  title,
  src,
  logo,
  text,
  textColor,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        zIndex: '0',
        height: { xs: '320px', sm: '410px', md: '60vh' },
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'absolute',
          top: '0px',
          right: '0px',
          height: { xs: '50%', sm: '60%' },
          zIndex: '1',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            minHeight: '100%',
            objectFit: 'contain',
            objectPosition: 'right',
          }}
          component="img"
          src={logo}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          overflow: 'hidden',
        }}
      >
        {!src ? (
          <Skeleton
            sx={{ height: '60vh', width: '100%' }}
            animation="wave"
            variant="rectangular"
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              minHeight: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            component="img"
            src={src}
          />
        )}
      </Box>

      <Container sx={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            alignItems: 'flex-end',
            justifyContent: { xs: 'space-between', sm: 'center' },
            height: '100%',
            paddingBottom: { xs: '15px', sm: '0px' },
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: { xs: '20px', sm: '50px' },
              lineHeight: { xs: '24px', sm: '60px' },
              fontWeight: '700',
              color: `${textColor}`,
              marginBottom: { xs: '0px', sm: '15px' },
              zIndex: '2',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: `${textColor}`,
              fontSize: { xs: '14px', sm: '20px' },
              lineHeight: { xs: '17px', sm: '23px' },
              fontWeight: '300',
              marginBottom: { xs: '20px', sm: '30px', md: '50px' },
            }}
            component="div"
          >
            {Parser(text || '')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default MainBanner;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocialMediaItem } from 'types/SocialMedia';
import { getSocialMedia } from './ActionCreators';

interface SocialMediaState {
  socialMedia: SocialMediaItem[];
  isLoading: boolean;
  error: string;
}

const initialState: SocialMediaState = {
  socialMedia: [],
  isLoading: false,
  error: '',
};

export const socialMediaSlice = createSlice({
  name: 'social_media',
  initialState,
  reducers: {},
  extraReducers: {
    [getSocialMedia.fulfilled.type]: (
      state,
      action: PayloadAction<SocialMediaItem[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.socialMedia = action.payload;
    },
    [getSocialMedia.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getSocialMedia.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default socialMediaSlice.reducer;

import React, { FC, useEffect, useRef, useState } from 'react';
import { Typography, Box, Stack, Divider } from '@mui/material';
import { CookingOption, GroupModifier, Ingredient, Option } from 'types/Menu';
import { Slider } from 'components/modals/ProductModal/Modal/Content/Regular';
import {
  CookingOptions,
  GroupModifiers,
  Ingredients,
  Options,
} from 'components/modals/ProductModal/Buttons';
import { Tooltip } from 'components/generic';

interface RegularProps {
  name: string;
  options: Option[];
  groupModifiers: GroupModifier[];
  ingredients: Ingredient[];
  cookingOptions: CookingOption[];
  image: string;
  additionalImages: any[];
  description: string;
  extraDescription: string;
  isDescription: boolean;
  optionDesc?: string;
}

const Regular: FC<RegularProps> = ({
  name,
  options,
  groupModifiers,
  ingredients,
  cookingOptions,
  image,
  additionalImages,
  description,
  extraDescription,
  isDescription,
  optionDesc,
}) => {
  const titleRef = useRef<any>(null);
  const [titleHeight, setTitleHeight] = useState<number>(0);

  useEffect(() => {
    setTitleHeight(titleRef.current.clientHeight);
  }, [titleRef]);

  return (
    <Stack direction={{ xs: 'row', md: 'row' }} spacing={2.5}>
      {additionalImages.length > 0 ? (
        <Box sx={{ width: '50%' }}>
          <Slider images={additionalImages} />
        </Box>
      ) : (
        <Box
          sx={{
            height: { xs: '300px', md: '380px' },
            width: '50%',
            minWidth: { xs: '305px', md: '380px' },
            marginBottom: '25px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            component="img"
            src={image}
          />
        </Box>
      )}
      <Box sx={{ width: '50%' }}>
        <Stack direction="row" spacing={1} ref={titleRef}>
          <Stack
            sx={{ marginBottom: '8px', flexGrow: '1' }}
            direction="column"
            spacing={1}
          >
            <Typography
              sx={{
                fontSize: '28px',
                lineHeight: '1',
                fontWeight: '600',
              }}
            >
              {name}
            </Typography>

            {optionDesc && (
              <Typography sx={{ fontSize: '14px', lineHeight: '1.2' }}>
                {optionDesc}
              </Typography>
            )}
          </Stack>
          <Tooltip title={extraDescription} placement="bottom-end" />
        </Stack>
        <Divider />
        <Box
          sx={{
            overflow: 'auto',
            padding: '20px 0',
            maxHeight: {
              xs: `calc(323px - ${titleHeight}px)`,
              md: `calc(404px - ${titleHeight}px)`,
            },
            minWidth: { xs: '305px', md: '380px' },
            '&::-webkit-scrollbar': {
              width: 7,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: '20px',
            },
          }}
        >
          {isDescription ? (
            <Typography sx={{ whiteSpace: 'pre-wrap', paddingRight: '10px' }}>
              {description}
            </Typography>
          ) : (
            <>
              {options.length > 1 && <Options options={options} />}
              {cookingOptions.length > 0 && (
                <CookingOptions cookingOptions={cookingOptions} />
              )}
              {ingredients.length > 0 && (
                <Ingredients ingredients={ingredients} />
              )}
              {groupModifiers.length > 0 && (
                <GroupModifiers groupModifiers={groupModifiers} />
              )}
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default Regular;

import React, { FC } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { MainTitle } from 'components/generic';
import useCaptchaInputStep from 'hooks/auth/useCaptchaInputStep';
import { CaptchaImage, CaptchaSlider } from 'components/modals/AuthModal';

interface CaptchaInputStepProps {
  nextStep: () => void;
}

const CaptchaInputStep: FC<CaptchaInputStepProps> = ({ nextStep }) => {
  const { state, onCaptchaSubmit, onRetryButtonClick, t, setX, convertY } =
    useCaptchaInputStep(nextStep);

  const sliderHelperText = t('login.tips.captcha_tip_2');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
        }}
      >
        <MainTitle>{t('login.title.captcha_title')}</MainTitle>
        {state.kind !== 'error' && (
          <Typography
            sx={{
              marginBottom: '32px',
              fontSize: '18px',
              lineHeight: '21px',
            }}
          >
            {t('login.captcha.explanation')}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '30px',
        }}
      >
        {state.kind === 'loading_captcha' && (
          <CircularProgress size={35} color="primary" />
        )}
        {(state.kind === 'accepting_input' ||
          state.kind === 'processing_input') && (
          <>
            <CaptchaImage
              puzzleImage={state.data.puzzle_image}
              pieceImage={state.data.piece_image}
              relativeX={state.input.relativeX}
              relativeY={convertY(state.data.y)}
            />
            <CaptchaSlider
              value={state.input.relativeX}
              onChange={(value) => setX(value)}
              onChangeCommitted={(value) => onCaptchaSubmit(value)}
              helperText={sliderHelperText}
            />
          </>
        )}
        {state.kind === 'wrong_input' && (
          <>
            <CaptchaImage
              puzzleImage={state.data.puzzle_image}
              pieceImage={state.data.piece_image}
              relativeX={0}
              relativeY={convertY(state.data.y)}
              displayError
            />
            <Button
              sx={{
                padding: '12px 86px',
                textTransform: 'inherit',
                fontSize: '20px',
                lineHeight: '130%',
                borderRadius: '6px',
                fontWeight: '300',
                color: 'primary.contrastText',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
                marginBottom: '6px',
              }}
              variant="contained"
              color="primary"
              onClick={onRetryButtonClick}
            >
              {t('login.get_captcha_again')}
            </Button>
          </>
        )}
        {state.kind === 'error' && (
          <Typography
            sx={{
              fontSize: '18px',
              lineHeight: '21px',
              textAlign: 'center',
              marginTop: '25%',
            }}
          >
            {t('login.captcha.errors.generic')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CaptchaInputStep;

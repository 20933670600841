import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import OrderResult from 'components/order/OrderResult/OrderResult';
import { useTranslation } from 'react-i18next';
import { BackButton } from 'components/generic';

/**
 * Page displayed on successful order payment
 */
const PaymentSuccess: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('order_id');

  if (orderId === null) {
    navigate('/error', { state: 'payment' });
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <BackButton handleBack={handleBack} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <OrderResult
            orderNumber={orderId}
            orderMessage={t('payment_success')}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentSuccess;

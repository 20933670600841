import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { isAuth } = useAuth();

  return isAuth ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;

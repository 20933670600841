import React, { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { MainTitle } from 'components/generic';
import { Buttons } from 'components/blogPost';
import Parser from 'html-react-parser';

interface PostProps {
  title: string;
  src: string;
  description: string;
  published_at: string;
}

const Post: FC<PostProps> = ({ title, src, description, published_at }) => {
  const time = useMemo(() => {
    return new Date(published_at).toLocaleDateString();
  }, [published_at]);

  return (
    <>
      <Box sx={{ marginBottom: { xs: '18px', sm: '23px', md: '37px' } }}>
        <MainTitle>{title}</MainTitle>
      </Box>
      <Box
        sx={{
          height: { xs: '250px', md: '510px' },
          width: '100%',
          marginBottom: '39px',
        }}
      >
        <Box
          sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          component="img"
          src={src}
        />
      </Box>
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '18px', md: '20px' },
          lineHeight: { xs: '16px', sm: '21px', md: '26px' },
          fontWeight: '300',
          marginBottom: '34px',
        }}
      >
        {Parser(description)}
      </Typography>
      <Buttons />
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '16px', md: '18px' },
          lineHeight: { xs: '16px', sm: '20px', md: '25px' },
          fontWeight: '300',
        }}
      >
        {time}
      </Typography>
    </>
  );
};

export default Post;

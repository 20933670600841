import { useCallback, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { createOrder } from 'store/reducers/OrderSlice/ActionCreators';
import {
  useCreateOrder,
  useOrderComplete,
  usePaymentButtonText,
} from 'hooks/order';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useSelector } from 'react-redux';
import {
  selectDefaultPaymentType,
  selectOrderIsLoading,
  selectOrderPaymentType,
  selectOrderTotalPrice,
  selectPaymentTypeOptions,
} from 'store/selectors/orderSelectors';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useSnackbar } from 'notistack';
import { SnackActions } from 'components/generic';
import {
  selectBrandPaymentTypes,
  selectIsBrands,
} from 'store/selectors/brandsSelectors';
import { getBrands } from 'store/reducers/BrandSlice/ActionCreators';

declare global {
  interface Window {
    cp?: any;
  }
}

const usePayment = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const defaultPaymentType = useSelector(selectDefaultPaymentType);
  const orderTotalPrice = useAppSelector(selectOrderTotalPrice);
  const paymentTypes = useSelector(selectPaymentTypeOptions);
  const brandPaymentTypes = useSelector(selectBrandPaymentTypes);
  const { navigateOrderCreated, navigateOrderCreationFailed } =
    useOrderComplete();
  const { addPaymentData, addPaymentType } = orderSlice.actions;
  const { PAYMENT_PUBLIC_ID, PAYMENT_SYSTEM_TYPE } = useAppSelector(
    selectAppSettingsData
  );
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues: {
      payment_type: defaultPaymentType,
      card_number: '',
      exp_date_month_year: '',
      payment_cardholder: '',
      cvv: '',
    },
  });
  const { control, watch, handleSubmit, setError, clearErrors } = methods;
  const paymentTypeChange = watch('payment_type');
  const cardNumber = watch('card_number');
  const expDateMonthYear = watch('exp_date_month_year');
  const cvv = watch('cvv');
  const orderIsLoading = useAppSelector(selectOrderIsLoading);
  const { BONUS_PROGRAM } = useAppSelector(selectAppSettingsData);
  const paymentType = useAppSelector(selectOrderPaymentType);
  const orderButtonText = usePaymentButtonText(paymentType);
  const [cloudPaymentModal, setCloudPaymentModal] = useState<boolean>(false);
  const orderData = useCreateOrder();
  const [isCryptoKeySuccess, setIsCryptoKeySuccess] = useState<boolean>(false);
  const isBrands = useSelector(selectIsBrands);

  const lang = useMemo(() => {
    return i18n.language.slice(0, 2);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getBrands(lang));
  }, [lang, dispatch]);

  useEffect(() => {
    dispatch(
      addPaymentType({
        payment_type: Number(defaultPaymentType),
      })
    );
  }, [defaultPaymentType]);

  // Update payment type on state whenever it changes
  useEffect(() => {
    if (
      paymentTypeChange !== null &&
      paymentTypeChange !== defaultPaymentType
    ) {
      dispatch(
        addPaymentType({
          payment_type: Number(paymentTypeChange),
        })
      );
    }
  }, [paymentTypeChange]);

  // Submit completed order
  const onSubmit = () => {
    const orderPromise = dispatch(createOrder(orderData)).unwrap();
    orderPromise
      .then((createdOrderResponse) => {
        navigateOrderCreated(createdOrderResponse);
      })
      .catch((reason) => {
        navigateOrderCreationFailed(reason);
      });
  };

  const cardValidation = (errors: any) => {
    clearErrors();
    const message = Object.keys(errors);
    message.forEach((item) => {
      if (item === 'cardNumber') {
        setError('card_number', {
          message: '',
        });
        enqueueSnackbar('Некорректный номер карты', {
          action: SnackActions,
          variant: 'error',
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
      if (item === 'expDateMonthYear') {
        setError('exp_date_month_year', {
          message: '',
        });
        enqueueSnackbar('Некорректная дата карты', {
          action: SnackActions,
          variant: 'error',
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
      if (item === 'cvv') {
        setError('cvv', {
          message: '',
        });
        enqueueSnackbar('Некорректный сvv карты', {
          action: SnackActions,
          variant: 'error',
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
    });
  };

  const hanldeGenCryptoKey = () => {
    const { cp } = window;
    if (cp) {
      const checkout = new cp.Checkout({
        publicId: PAYMENT_PUBLIC_ID,
      });

      const fieldValues = {
        cvv,
        cardNumber,
        expDateMonthYear,
      };

      checkout
        .createPaymentCryptogram(fieldValues)
        .then((cryptogram: string) => {
          dispatch(
            addPaymentData({
              payment_cryptogram: cryptogram,
            })
          );
          setIsCryptoKeySuccess(true);
        })
        .catch((errors: any) => {
          cardValidation(errors);
        });
    }
  };

  const cloudPaymentModalOpen = useCallback(() => {
    setCloudPaymentModal(true);
  }, [cloudPaymentModal]);

  const cloudPaymentModalClose = useCallback(() => {
    setCloudPaymentModal(false);
  }, [cloudPaymentModal]);

  const isCloudPayment = useMemo(() => {
    return (
      Number(paymentTypeChange) === 1 && PAYMENT_SYSTEM_TYPE === 'cloudpayments'
    );
  }, [paymentTypeChange, PAYMENT_SYSTEM_TYPE]);

  useEffect(() => {
    if (isCryptoKeySuccess) {
      onSubmit();
    }
  }, [isCryptoKeySuccess]);

  return {
    t,
    methods,
    onSubmit,
    handleSubmit,
    BONUS_PROGRAM,
    orderTotalPrice,
    paymentTypes: isBrands ? brandPaymentTypes : paymentTypes,
    orderIsLoading,
    control,
    orderButtonText,
    cloudPaymentModal,
    cloudPaymentModalClose,
    isCloudPayment,
    cloudPaymentModalOpen,
    hanldeGenCryptoKey,
  };
};

export default usePayment;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectBookingData = (state: RootState) => state.booking;

/**
 * Returns booking data is loading
 */
export const selectBookingIsLoading = createSelector(
  [selectBookingData],
  (bookingData) => bookingData.isLoading
);

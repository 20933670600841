import React from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

const SnackActions: SnackbarAction = (snackbarId: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      size="small"
      aria-label="close-btn"
    >
      <CloseIcon sx={{ color: 'common.white' }} fontSize="small" />
    </IconButton>
  );
};

export default SnackActions;

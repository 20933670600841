import React, { FC } from 'react';
import { Button } from '@mui/material';

interface ListItemProps {
  handleClick: (arg: number) => () => void;
  label: string;
  value: number;
}

const ListItem: FC<ListItemProps> = ({ handleClick, label, value }) => {
  return (
    <Button
      sx={{
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: '300',
        cursor: 'pointer',
        marginBottom: '24px',
        textTransform: 'inherit',
        padding: '0 20px',
        color: 'text.primary',
        '&:last-child': {
          marginBottom: '0',
        },
        '&:hover': {
          color: 'primary.main',
        },
      }}
      onClick={handleClick(value)}
    >
      {label}
    </Button>
  );
};

export default ListItem;

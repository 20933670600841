import React, { FC, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { BackDrawerButton, Portal } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { useWindowDimensions } from 'hooks/menu';
import { useDisableBodyScroll, useDelayUnmount } from 'hooks';
import { useTranslation } from 'react-i18next';
import { PromoProduct } from 'types/Promotion';
import { Product } from 'components/modals/PromoProductsModal/Drawer';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { chunks } from 'helpers';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  promoProducts: PromoProduct[];
  actionId: number;
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  promoProducts,
  actionId,
}) => {
  useDisableBodyScroll(isOpen);
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const { t } = useTranslation();
  SwiperCore.use([Pagination]);

  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
  }));
  const open = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };
  const close = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  return (
    <Portal>
      {isMount && (
        <animated.div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            right: '0px',
            width: '100%',
            height: '100dvh',
            background: '#ffffff',
            y,
          }}
        >
          <Box
            sx={{ position: 'fixed', top: '24px', left: '20px' }}
            onClick={close}
          >
            <BackDrawerButton direction="down" />
          </Box>
          <Box
            sx={{
              overflow: 'auto',
              height: '100%',
              width: '100%',
            }}
          >
            <Box sx={{ padding: '90px 20px 60px 20px', height: '100dvh' }}>
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '28px',
                  fontWeight: '400',
                  marginBottom: '20px',
                }}
              >
                {t('choose_a_gift')}
              </Typography>
              <Swiper slidesPerView={1} pagination style={{ height: '100%' }}>
                {chunks(promoProducts, 4).map((chunk) => (
                  <SwiperSlide>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '24px',
                        height: 'calc(100% - 30px)',
                      }}
                    >
                      {chunk.map((product) => (
                        <Product
                          key={product.id}
                          itemId={product.item}
                          categoryId={product.menu}
                          itemOption={product.item_option}
                          actionId={actionId}
                          onClose={onClose}
                        />
                      ))}
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        </animated.div>
      )}
    </Portal>
  );
};

export default Drawer;

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';
import { useAccountFormData } from 'hooks';
import { getAddresses } from 'store/reducers/AddressSlice/ActionCreators';
import { getOrderHistory } from 'store/reducers/OrderSlice/ActionCreators';
import {
  selectActiveOrdersData,
  selectOrderIsLoading,
} from 'store/selectors/orderSelectors';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { OrderHistoryItem } from 'types/Order';
import { useSnackbar } from 'notistack';
import { SnackActions } from 'components/generic';
import { useTranslation } from 'react-i18next';

const useAccount = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const formValues = methods.watch();
  const patchData = useAccountFormData(formValues);
  const isLoadingOrder = useAppSelector<boolean>(selectOrderIsLoading);
  const api_key = useAppSelector<string>(selectApiKey);
  const activeOrdersData = useAppSelector<OrderHistoryItem[]>(
    selectActiveOrdersData
  );
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getErrorMessage = (reason: string | null) => {
    if (reason === 'EMAIL_ALREADY_USE') {
      return t('profile.update.errors.email_used');
    }
    if (reason === 'PHONE_ALREADY_USE') {
      return t('profile.update.errors.phone_used');
    }

    return t('something_went_wrong');
  };

  const onSubmit = () => {
    dispatch(updateAccountData(patchData))
      .unwrap()
      .then((res) => {
        if (res.success) {
          enqueueSnackbar(t('successfully_saved'), {
            action: SnackActions,
            variant: 'success',
            autoHideDuration: 3000,
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(getErrorMessage(res.reason), {
            action: SnackActions,
            variant: 'error',
            autoHideDuration: 3000,
            preventDuplicate: true,
          });
        }
      });
  };

  useEffect(() => {
    dispatch(getAddresses(api_key));
    dispatch(getOrderHistory(api_key));
  }, [api_key]);

  return {
    methods,
    isLoadingOrder,
    activeOrdersData,
    onSubmit,
  };
};

export default useAccount;

import React, { FC, useRef } from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { Card } from 'components/basket/BasketContent/Recommendations';
import { SubTitle } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { selectBasketRecommendations } from 'store/selectors/orderSelectors';

const Recomendations: FC = () => {
  SwiperCore.use([Autoplay, Mousewheel, Navigation]);
  const products = useAppSelector(selectBasketRecommendations);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { t, i18n } = useTranslation();
  const isLtr = i18n.dir() === 'ltr';

  return (
    <Box sx={{ padding: { xs: '0px', md: '0 25px' } }}>
      <Box sx={{ marginBottom: { xs: '10px', md: '35px' } }}>
        <SubTitle>{t('complete_your_meal_with')}</SubTitle>
      </Box>

      <Box
        sx={{
          position: 'relative',
        }}
      >
        <IconButton
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
            marginRight: '10px',
            position: 'absolute',
            left: '-50px',
            top: '0',
            bottom: '0',
            height: '40px',
            margin: 'auto',
            '&.swiper-button-disabled': {
              opacity: 0,
              cursor: 'inherit',
            },
          }}
          size="medium"
          ref={navigationPrevRef}
        >
          {isLtr ? (
            <ArrowBackIosNew fontSize="medium" color="primary" />
          ) : (
            <ArrowForwardIos fontSize="medium" color="primary" />
          )}
        </IconButton>

        <Swiper
          freeMode
          centeredSlidesBounds
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpoints={{
            '300': {
              slidesPerView: 1.1,
              spaceBetween: 11,
            },
            '355': {
              slidesPerView: 1.3,
              spaceBetween: 11,
            },
            '400': {
              slidesPerView: 1.4,
              spaceBetween: 11,
            },
            '480': {
              slidesPerView: 1.7,
              spaceBetween: 11,
            },
            '567': {
              slidesPerView: 1.9,
              spaceBetween: 18,
            },
            '767': {
              slidesPerView: 2.3,
              spaceBetween: 20,
            },
            '991': {
              slidesPerView: 2.4,
              spaceBetween: 30,
            },
            '1250': {
              slidesPerView: 2.6,
              spaceBetween: 40,
            },
            '1400': {
              slidesPerView: 2.7,
              spaceBetween: 40,
            },
          }}
        >
          {products ? (
            <>
              {products.map((product) => (
                <SwiperSlide key={product.id}>
                  <Card
                    productId={product.id}
                    categoryId={product.parent_menu}
                    src={product.src}
                    title={product.name}
                    price={product.options[0].price_kop}
                  />
                </SwiperSlide>
              ))}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: { xs: '132px', sm: '190px' },
                width: '100%',
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          )}
        </Swiper>

        <IconButton
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
            marginLeft: '10px',
            position: 'absolute',
            right: '-50px',
            top: '0',
            bottom: '0',
            height: '40px',
            margin: 'auto',
            '&.swiper-button-disabled': {
              opacity: 0,
              cursor: 'inherit',
            },
          }}
          size="medium"
          ref={navigationNextRef}
        >
          {isLtr ? (
            <ArrowForwardIos fontSize="medium" color="primary" />
          ) : (
            <ArrowBackIosNew fontSize="medium" color="primary" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default Recomendations;

import React, { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import { useMediaQuery } from 'react-responsive';

type BannerType = 1 | 2 | undefined;

interface BannerProps {
  src: string;
  title?: string;
  logo?: string;
  type?: BannerType;
  handleClick?: () => void;
  text?: string;
  textColor?: string;
  burger?: string;
  qrCodeImage?: string;
  mobileBannerImage?: string;
}

const Banner: FC<BannerProps> = ({
  title,
  logo,
  src,
  type,
  handleClick,
  text,
  textColor,
  burger,
  qrCodeImage,
  mobileBannerImage,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const bannerType = (value: BannerType) => {
    switch (value) {
      case 1:
        return (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '-20px', md: '-15px' },
                bottom: { xs: '0px', md: '-35px' },
                zIndex: '1',
                left: { xs: '0px', md: '-100px', lg: '0px' },
                height: { xs: '250px', sm: '450px', md: 'auto' },
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                component="img"
                src={burger}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                width: '100%',
                height: '100%',
                zIndex: '-1',
              }}
            >
              {!src ? (
                <Skeleton
                  sx={{ height: '60vh', width: '100%' }}
                  animation="wave"
                  variant="rectangular"
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  component="img"
                  src={src}
                />
              )}
            </Box>

            <Container sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-start', sm: 'center' },
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', md: 'column' },
                    alignItems: { xs: 'flex-end', md: 'flex-start' },
                    justifyContent: { xs: 'space-between', md: 'center' },
                    width: { xs: '100%', md: '50%' },
                    height: '100%',
                    padding: { xs: '0 0 15px 0', md: '0' },
                    gap: { xs: '15px', md: '0' },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '30px', md: '50px' },
                      lineHeight: { xs: '34px', md: '60px' },
                      fontWeight: '700',
                      color: `${textColor}`,
                      marginBottom: { xs: '0px', md: '15px' },
                    }}
                    component="div"
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: 'none', md: 'inline-block' },
                      color: `${textColor}`,
                      fontSize: { xs: '14px', sm: '20px' },
                      lineHeight: { xs: '17px', sm: '23px' },
                      fontWeight: '300',
                      marginBottom: { xs: '20px', sm: '30px', md: '50px' },
                    }}
                    component="div"
                  >
                    {Parser(text || '')}
                  </Typography>
                  <Button
                    sx={{
                      textTransform: 'inherit',
                      fontSize: { xs: '18px', sm: '20px' },
                      lineHeight: { xs: '20px', sm: '23px' },
                      fontWeight: '300',
                      padding: '13px 25px',
                      color: 'secondary.main',
                      boxShadow: 'none',
                      whiteSpace: 'nowrap',
                    }}
                    variant="contained"
                    onClick={handleClick}
                  >
                    {t('order_now')}
                  </Button>
                </Box>
              </Box>
            </Container>
          </>
        );

      case 2:
        return (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                width: '100%',
                height: '100%',
                zIndex: '-1',
              }}
            >
              {!src ? (
                <Skeleton
                  sx={{ height: '60vh', width: '100%' }}
                  animation="wave"
                  variant="rectangular"
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  component="img"
                  src={isMobile ? mobileBannerImage : src}
                />
              )}
            </Box>

            <Container sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-start', sm: 'center' },
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-end',
                  padding: { xs: '30px 0 85px 0', sm: '0px' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: { xs: 'space-between', sm: 'center' },
                    width: { xs: '100%', md: '50%' },
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '30px', md: '50px' },
                      lineHeight: { xs: '34px', md: '60px' },
                      fontWeight: '700',
                      color: `${textColor}`,
                      marginBottom: { xs: '10px', sm: '15px' },
                    }}
                    component="div"
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      color: `${textColor}`,
                      fontSize: { xs: '14px', sm: '20px' },
                      lineHeight: { xs: '17px', sm: '23px' },
                      fontWeight: '300',
                      marginBottom: { xs: '20px', sm: '23px', md: '27px' },
                    }}
                    component="div"
                  >
                    {Parser(text || '')}
                  </Typography>

                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      width: '150px',
                      height: '150px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      component="img"
                      src={qrCodeImage}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: 'inherit',
                        fontSize: { xs: '18px', sm: '20px' },
                        lineHeight: { xs: '20px', sm: '23px' },
                        fontWeight: '300',
                        padding: '13px 25px',
                        color: 'secondary.main',
                        boxShadow: 'none',
                        whiteSpace: 'nowrap',
                        backgroundColor: '#ffffff',
                      }}
                      variant="contained"
                      onClick={handleClick}
                    >
                      {t('show_me')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          </>
        );

      default:
        return (
          <>
            <Box
              sx={{
                position: 'absolute',
                zIndex: '2',
                transform: 'translate(-50%, -45%)',
                top: '50%',
                left: '50%',
                textAlign: 'center',
              }}
            >
              {!title ? (
                <CircularProgress color="primary" />
              ) : (
                <>
                  <Box
                    sx={{
                      maxHeight: { xs: '115px', sm: '140px', md: '200px' },
                      padding: { xs: '0 50px', sm: '0' },
                      marginBottom: { xs: '10px', sm: '20px' },
                    }}
                    component="img"
                    src={logo}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '30px', md: '50px' },
                      lineHeight: { xs: '34px', md: '60px' },
                      color: `${textColor}`,
                      fontWeight: '600',
                      textTransform: 'uppercase',
                    }}
                  >
                    {title}
                  </Typography>
                </>
              )}
            </Box>

            {!src ? (
              <Skeleton
                sx={{ height: '60vh', width: '100%' }}
                animation="wave"
                variant="rectangular"
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                component="img"
                src={src}
              />
            )}
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        zIndex: '0',
        height: { xs: '320px', sm: '60vh' },
      }}
    >
      {bannerType(type)}
    </Box>
  );
};

export default Banner;

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { City } from 'types/City';

export const getCities = createAsyncThunk(
  'city/getCities',
  async (params: { lang: string; brand?: number }, thunkAPI) => {
    const { lang, brand } = params;
    try {
      const response = await axios.get<City[]>(
        `${process.env.REACT_APP_V3}/city/`,
        {
          headers: {
            'Accept-Language': lang,
          },
          params: {
            brand,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Failed to get a list of cities with an error ${e}`
      );
    }
  }
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Restaurant } from 'types/Restaurant';
import { getRestaurantList } from './ActionCreators';

interface RestaurantState {
  restaurantList: Restaurant[];
  isLoading: boolean;
  error: string;
}

const initialState: RestaurantState = {
  restaurantList: [],
  isLoading: false,
  error: '',
};

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {},
  extraReducers: {
    [getRestaurantList.fulfilled.type]: (
      state,
      action: PayloadAction<Restaurant[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.restaurantList = action.payload;
    },
    [getRestaurantList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getRestaurantList.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default restaurantSlice.reducer;

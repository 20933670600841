import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';

interface DrawerHeaderProps {
  handleDeleteAddress: () => void;
  modalType: 'add' | 'update';
}

const DrawerHeader: FC<DrawerHeaderProps> = ({
  handleDeleteAddress,
  modalType,
}) => {
  const { LOGO_LIGHT } = useAppSelector(selectAppSettingsData);
  const { t } = useTranslation();
  return (
    <>
      {modalType === 'add' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ alignSelf: 'flex-end', marginBottom: '40px' }}>
            <Box component="img" src={LOGO_LIGHT} />
          </Box>
          <Typography
            sx={{ fontSize: '24px', lineHeight: '29px' }}
            variant="h4"
          >
            {t('delivery_address')}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ alignSelf: 'flex-end', marginBottom: '40px' }}>
            <Box component="img" src={LOGO_LIGHT} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '28px' }}
              variant="h4"
            >
              {t('delivery_address')}
            </Typography>
            <Button
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                textTransform: 'capitalize',
                padding: '3px 10px',
              }}
              onClick={handleDeleteAddress}
            >
              {t('delete')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DrawerHeader;

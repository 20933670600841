import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Page } from 'types/Pages';

export const getPages = createAsyncThunk(
  'pages/getPages',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<Page[]>(
        `${process.env.REACT_APP_V3}/cms/page/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get pages. Error: ${e}`);
    }
  }
);

export const getPage = createAsyncThunk(
  'pages/getPage',
  async (queryArgs: { slug: string; lang: string }, thunkAPI) => {
    const { slug, lang } = queryArgs;
    try {
      const response = await axios.get<Page[]>(
        `${process.env.REACT_APP_V3}/cms/page/${slug}`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get page. Error: ${e}`);
    }
  }
);

import { useNavigate } from 'react-router-dom';
import { ResponseCreateOrder } from 'types/Order';

/**
 * Data for completing order placement.
 */
export function useOrderComplete() {
  const navigate = useNavigate();
  /**
   * Navigates customer to either payment page or
   * page with order information
   */
  const navigateOrderCreated = (createdOrder: ResponseCreateOrder) => {
    const orderData = createdOrder.data;

    if (!orderData?.order_id) {
      navigate('/error');
      throw new Error(
        `No order data provided! Provided orderData: ${JSON.stringify(
          orderData
        )}`
      );
    }

    // If payment data exists and it has return url, then we may navigate customer to payment page
    // After payment they will be redirected back go the app by the server.
    if (orderData.payment_data) {
      if (orderData.payment_data.formUrl) {
        window.location.href = orderData.payment_data.formUrl;
        return;
      }

      throw new Error(
        `No formUrl provided for payment, although payment_data field exits`
      );
    }

    // In case if a customer don't use online payment just navigate to a order success page
    navigate('/order-success', { state: { order_id: orderData.order_id } });
  };

  /**
   * Navigate customer to error page in case if order hasn't been created
   * due to error.
   */
  const navigateOrderCreationFailed = (reason: string) => {
    navigate('/error');
    throw new Error(reason);
  };

  return {
    navigateOrderCreated,
    navigateOrderCreationFailed,
  };
}

export default useOrderComplete;

import React, { FC } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import {
  AlternateEmail as AlternateEmailIcon,
  SmsOutlined as SmsOutlinedIcon,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectFirstAvailableRestaurant } from 'store/selectors/restaurantSelectors';
import { useScrollToBlock } from 'hooks';
import { selectAppSettingsData } from 'store/selectors/appSelectors';

const Contacts: FC = () => {
  useScrollToBlock();
  const { t } = useTranslation();
  const { address, operating_time, email } = useAppSelector<{
    address: string;
    operating_time: string;
    email: string;
    phone: string;
  }>(selectFirstAvailableRestaurant);
  const { FEEDBACK_LINK } = useAppSelector<{ FEEDBACK_LINK: string }>(
    selectAppSettingsData
  );

  return (
    <Container>
      <Box
        id="contact_us"
        sx={{
          display: 'grid',
          columnGap: { xs: 1, sm: 5, md: 15 },
          rowGap: { xs: 3 },
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          padding: { xs: '30px 0', sm: '50px 0' },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '30px' },
              lineHeight: '30px',
              fontWeight: '400',
              marginBottom: { xs: '7px', sm: '11px' },
            }}
          >
            {t('location')}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '20px' },
              lineHeight: { xs: '18px', sm: '26px' },
              fontWeight: '300',
              color: '#4F4F4F',
              marginLeft: { xs: '8px', sm: '0px' },
            }}
          >
            {address}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '30px' },
              lineHeight: '30px',
              fontWeight: '400',
              marginBottom: { xs: '7px', sm: '11px' },
            }}
          >
            {t('contact_us')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '7px',
              marginLeft: { xs: '8px', sm: '0px' },
            }}
          >
            <AlternateEmailIcon color="primary" sx={{ marginRight: '8px' }} />
            <Link
              sx={{
                textDecoration: 'none',
                color: 'text.primary',
                fontSize: { xs: '14px', sm: '20px' },
                lineHeight: { xs: '18px', sm: '26px' },
                fontWeight: '300',
              }}
              href={`mailto:${email}`}
            >
              {t('email_us')}
            </Link>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: { xs: '8px', sm: '0px' },
            }}
          >
            <SmsOutlinedIcon color="primary" sx={{ marginRight: '8px' }} />
            <Typography />
            <Link
              sx={{
                textDecoration: 'none',
                color: 'text.primary',
                fontSize: { xs: '14px', sm: '20px' },
                lineHeight: { xs: '18px', sm: '26px' },
                fontWeight: '300',
              }}
              href={FEEDBACK_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('text_us')}
            </Link>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '30px' },
              lineHeight: '30px',
              fontWeight: '400',
              marginBottom: { xs: '7px', sm: '11px' },
            }}
          >
            {t('store_hours')}
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '20px' },
              lineHeight: { xs: '18px', sm: '26px' },
              fontWeight: '300',
              marginLeft: { xs: '8px', sm: '0px' },
              color: '#4F4F4F',
            }}
          >
            {operating_time}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Contacts;

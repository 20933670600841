import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { BackDrawerButton } from 'components/generic';
import { useTranslation } from 'react-i18next';

const BackToCartButton: FC = () => {
  const { t, i18n } = useTranslation();
  const dir = i18n.dir() === 'ltr' ? 'left' : 'right';
  const isLtr = i18n.dir() === 'ltr';

  return (
    <>
      <Box
        sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
        component={NavLink}
        to="/cart"
      >
        <BackDrawerButton direction={dir} />
      </Box>
      <Button
        sx={{
          display: { xs: 'none', sm: 'inline-flex' },
          fontSize: { xs: '14px', sm: '18px' },
          lineHeight: '21px',
          color: 'secondary.main',
        }}
        variant="text"
        component={NavLink}
        to="/cart"
      >
        {isLtr ? (
          <ArrowBackIos color="secondary" />
        ) : (
          <ArrowForwardIos color="secondary" />
        )}
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            lineHeight: '21px',
            color: 'secondary.main',
            fontWeight: '500',
          }}
        >
          {t('back_to_the_cart')}
        </Typography>
      </Button>
    </>
  );
};

export default BackToCartButton;

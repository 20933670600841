import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { OrderList } from 'components/account/OrderInfro';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectActiveOrdersData } from 'store/selectors/orderSelectors';

const OrderInfo: FC = () => {
  const activeOrdersData = useAppSelector(selectActiveOrdersData);
  return (
    <Stack direction="column" spacing={5}>
      {activeOrdersData.length > 0 &&
        activeOrdersData.map((order) => (
          <OrderList
            key={order.id}
            deliveryPriceKop={order.delivery_price}
            benefitKop={0}
            orderId={order.id}
            resultTotalPriceKop={order.total_price}
            items={order.order_item_options}
            status={order.status}
          />
        ))}
    </Stack>
  );
};

export default OrderInfo;

import React, { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DatePicker, SelectField } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectServerTime } from 'store/selectors/appSelectors';
import moment from 'moment';

const DateTimePicker: FC = () => {
  const { control } = useFormContext();
  const serverTime = useAppSelector(selectServerTime);
  const { t } = useTranslation();

  const availableTimeList = useMemo(() => {
    const list = [];
    const start = moment().startOf('day');
    const end = moment().endOf('day');

    for (let current = start; current <= end; current.add(30, 'minutes')) {
      list.push({
        label: current.format('HH:mm'),
        value: current.format('HH:mm'),
      });
    }

    return list;
  }, []);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ marginBottom: { xs: '13px', sm: '30px' }, width: '100%' }}
      spacing={{ xs: 2, sm: 1.6 }}
    >
      <DatePicker
        control={control}
        name="day"
        label={t('day')}
        minDate={new Date(serverTime)}
        defaultValue={new Date(serverTime)}
        required
      />

      <SelectField
        control={control}
        name="time"
        label={t('time')}
        required
        selectItem={availableTimeList}
        defaultValue=""
      />
    </Stack>
  );
};

export default DateTimePicker;

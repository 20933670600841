import React, { FC } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectSuccessDelivery,
  selectCheckDelieryIsLoading,
} from 'store/selectors/orderSelectors';
import {
  Loading,
  Success,
  Error,
} from 'components/modals/AddressModal/Drawer/Content/CheckDelivery';

interface CheckDeliveryProps {
  addressView: string;
}

const CheckDelivery: FC<CheckDeliveryProps> = ({ addressView }) => {
  const isLoading = useAppSelector(selectCheckDelieryIsLoading);
  const isSuccess = useAppSelector(selectSuccessDelivery);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>{isSuccess ? <Success /> : <Error addressView={addressView} />}</>
      )}
    </>
  );
};

export default CheckDelivery;

import React, { FC, useCallback, useState } from 'react';
import { Card as MUICard, Typography, Button, Box } from '@mui/material';
import { usePrice, useSrcImage } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ProductModal } from 'components/modals';

interface CardProps {
  title: string;
  src: string;
  price: number;
  productId: number;
  categoryId: number;
}

const Card: FC<CardProps> = ({ title, src, price, productId, categoryId }) => {
  const image = useSrcImage(src);
  const currentPrice = usePrice(price);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  return (
    <>
      <MUICard
        sx={{
          display: 'flex',
          margin: '10px 0',
          height: { xs: '112x', md: '170px' },
          borderRadius: '0px',
          textDecoration: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        onClick={handleOpenModal}
      >
        <Box
          sx={{
            height: { xs: '112px', md: '170px' },
            width: { xs: '112px', md: '170px' },
            minWidth: { xs: '112px', md: '170px' },
          }}
        >
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            alt="recomendation-image"
            src={image}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: {
              xs: '5px 12px 7px 12px',
              sm: '5px 20px 10px 20px',
              md: '5px 20px 10px 20px',
            },
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '20px' },
              lineHeight: { xs: '17px', md: '23px' },
              fontWeight: '400',
            }}
            variant="h6"
            component="h6"
          >
            {title}
          </Typography>
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '12px', md: '20px' },
                lineHeight: { xs: '14px', md: '23px' },
                fontWeight: '300',
                marginBottom: { xs: '6px', md: '7px' },
              }}
            >
              {currentPrice}
            </Typography>
            <Button
              sx={{
                width: '100%',
                height: { xs: '45px', md: '50px' },
                textTransform: 'inherit',
                borderRadius: '6px',
                border: '2px solid',
                borderColor: 'primary.main',
                display: 'inline-flex',
                '&:hover': {
                  border: '2px solid',
                  borderColor: 'primary.main',
                },
              }}
              variant="outlined"
            >
              <Typography
                sx={{
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '20px', md: '24px' },
                  fontWeight: '300',
                  color: 'primary.main',
                }}
              >
                {t('add')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </MUICard>
      <ProductModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        productId={productId}
        categoryId={categoryId}
      />
    </>
  );
};

export default Card;

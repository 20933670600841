import React, { FC } from 'react';
import { useCaptchaInputStep } from 'hooks/auth';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { BackDrawerButton } from 'components/generic';
import { CaptchaImage, CaptchaSlider } from 'components/modals/AuthModal';

interface CaptchaInputStepProps {
  nextStep: () => void;
  onClose: () => void;
}

const CaptchaInputStep: FC<CaptchaInputStepProps> = ({ nextStep, onClose }) => {
  const { state, onCaptchaSubmit, onRetryButtonClick, t, setX, convertY } =
    useCaptchaInputStep(nextStep);

  return (
    <Box
      sx={{
        padding: '100px 20px 20px 20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{ position: 'fixed', top: '24px', left: '20px', zIndex: '100' }}
        onClick={onClose}
      >
        <BackDrawerButton direction="down" />
      </Box>
      <Typography
        sx={{
          marginBottom: '11px',
        }}
        variant="h4"
      >
        {t('login.title.captcha_title')}
      </Typography>
      {state.kind !== 'error' && (
        <Typography
          variant="body1"
          sx={{
            marginBottom: '17px',
            lineHeight: '21px',
          }}
        >
          {t('login.captcha.explanation')}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {state.kind === 'loading_captcha' && (
          <CircularProgress size={35} color="primary" />
        )}
        {(state.kind === 'accepting_input' ||
          state.kind === 'processing_input') && (
          <>
            <CaptchaImage
              puzzleImage={state.data.puzzle_image}
              pieceImage={state.data.piece_image}
              relativeX={state.input.relativeX}
              relativeY={convertY(state.data.y)}
              fullWidth
            />
            <CaptchaSlider
              value={state.input.relativeX}
              onChange={(value) => setX(value)}
              onChangeCommitted={(value) => onCaptchaSubmit(value)}
              helperText={t('login.tips.captcha_tip_2')}
              fullWidth
              mobile
            />
          </>
        )}
        {state.kind === 'wrong_input' && (
          <>
            <CaptchaImage
              puzzleImage={state.data.puzzle_image}
              pieceImage={state.data.piece_image}
              relativeX={0}
              relativeY={convertY(state.data.y)}
              fullWidth
              displayError
            />
            <Button
              sx={{
                padding: '13px 86px',
                textTransform: 'inherit',
                fontSize: '18px',
                lineHeight: '1',
                borderRadius: '6px',
                fontWeight: '300',
                color: 'primary.contrastText',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
              }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={onRetryButtonClick}
            >
              {t('login.get_captcha_again')}
            </Button>
          </>
        )}
        {state.kind === 'error' && (
          <Typography
            sx={{
              fontSize: '1.1rem',
              lineHeight: '24px',
              textAlign: 'center',
              marginTop: '25%',
            }}
          >
            {t('login.captcha.errors.generic')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CaptchaInputStep;

import React, { FC } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { GroupModifier, Option, Ingredient, CookingOption } from 'types/Menu';
import {
  CookingOptions,
  GroupModifiers,
  Ingredients,
  Options,
} from 'components/modals/ProductModal/Buttons';
import { RegularSlider } from 'components/modals/ProductModal/Drawer/Content/Regular';
import { Tooltip } from 'components/generic';

interface RegularProps {
  name: string;
  options: Option[];
  groupModifiers: GroupModifier[];
  ingredients: Ingredient[];
  cookingOptions: CookingOption[];
  image: string;
  additionalImages: any[];
  description: string;
  extraDescription: string;
  optionDesc?: string;
}

const Regular: FC<RegularProps> = ({
  name,
  options,
  groupModifiers,
  ingredients,
  cookingOptions,
  image,
  additionalImages,
  description,
  extraDescription,
  optionDesc,
}) => {
  return (
    <>
      {additionalImages.length > 0 ? (
        <RegularSlider images={additionalImages} />
      ) : (
        <Box sx={{ height: '320px' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            component="img"
            src={image}
          />
        </Box>
      )}
      <Box sx={{ padding: '20px' }}>
        <Stack sx={{ marginBottom: '20px' }} direction="row" spacing={1}>
          <Stack
            sx={{ marginBottom: '8px', flexGrow: '1' }}
            direction="column"
            spacing={1}
          >
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '28px',
                fontWeight: '400',
                flexGrow: '1',
              }}
            >
              {name}
            </Typography>
            {optionDesc && (
              <Typography sx={{ fontSize: '14px', lineHeight: '1.2' }}>
                {optionDesc}
              </Typography>
            )}
          </Stack>
          <Tooltip title={extraDescription} placement="bottom-end" />
        </Stack>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '300',
            marginBottom: '30px',
          }}
        >
          {description}
        </Typography>
        {options.length > 1 && <Options options={options} />}
        {cookingOptions.length > 0 && (
          <CookingOptions cookingOptions={cookingOptions} />
        )}
        {ingredients.length > 0 && <Ingredients ingredients={ingredients} />}
        {groupModifiers.length > 0 && (
          <GroupModifiers groupModifiers={groupModifiers} />
        )}
      </Box>
    </>
  );
};

export default Regular;

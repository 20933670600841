import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Promotion } from 'types/Promotion';
import {
  checkPromoCode,
  getPromotions,
} from 'store/reducers/PromotionSlice/ActionCreators';

interface PromotionState {
  promotions: Promotion[];
  promoCode: any;
  isLoading: boolean;
  error: string;
}

const initialState: PromotionState = {
  promotions: [],
  promoCode: {
    data: null,
    reason: null,
    success: false,
  },
  isLoading: false,
  error: '',
};

export const menuSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {},
  extraReducers: {
    [getPromotions.fulfilled.type]: (
      state,
      action: PayloadAction<Promotion[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.promotions = action.payload;
    },
    [getPromotions.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPromotions.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [checkPromoCode.fulfilled.type]: (
      state,
      action: PayloadAction<Promotion[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.promoCode = action.payload;
    },
    [checkPromoCode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [checkPromoCode.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default menuSlice.reducer;

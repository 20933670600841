import React, { FC } from 'react';
import { Box } from '@mui/material';
import { DrawerButton } from 'components/modals/AddressModal/Drawer/Content';
import { useDrawer } from 'hooks';
import { FormProvider } from 'react-hook-form';
import { BackDrawerButton, Portal } from 'components/generic';
import { animated } from 'react-spring';
import { useAddressModal } from 'hooks/address';
import { Content } from 'components/modals/AddressModal/Drawer';

interface DrawerProps {
  onClose: () => void;
  isOpen: boolean;
  modalType: 'add' | 'update';
  addressId?: number;
}

const Drawer: FC<DrawerProps> = ({ onClose, isOpen, addressId, modalType }) => {
  const {
    isRuForm,
    methods,
    isCheckDelivery,
    addressView,
    onSubmit,
    handleBackToForm,
    handleDeleteAddress,
    handleClose,
  } = useAddressModal(modalType, onClose, addressId);
  const { isMount, y, closeDrawer } = useDrawer(isOpen, handleClose);

  return (
    <Portal>
      {isMount && (
        <Box component={FormProvider} {...methods}>
          <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <animated.div
              style={{
                position: 'fixed',
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                height: '150vh',
                background: '#ffffff',
                y,
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: '24px',
                  left: '20px',
                  zIndex: '100',
                }}
                onClick={closeDrawer}
              >
                <BackDrawerButton direction="down" />
              </Box>

              <Box
                sx={{
                  height: '100vh',
                  width: '100%',
                  overflow: 'auto',
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 100px)',
                }}
              >
                <Content
                  modalType={modalType}
                  isCheckDelivery={isCheckDelivery}
                  addressView={addressView}
                  handleDeleteAddress={handleDeleteAddress}
                  isRuForm={isRuForm}
                />
              </Box>
            </animated.div>
            <animated.div
              style={{
                position: 'fixed',
                bottom: '-10px',
                left: '0px',
                right: '0px',
                touchAction: 'none',
                zIndex: '200',
                boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.25)',
                y,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '24px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 34px)',
                  display: 'flex',
                  flexDirection: 'column',
                  zIndex: '1',
                  backgroundColor: 'rgba(255, 255, 255, .15)',
                  backdropFilter: 'blur(3px)',
                }}
              >
                <DrawerButton
                  isCheckDelivery={isCheckDelivery}
                  handleBack={handleBackToForm}
                  closeDrawer={closeDrawer}
                />
              </Box>
            </animated.div>
          </Box>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;

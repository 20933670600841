import React, { FC, useMemo } from 'react';
import { Box, Container } from '@mui/material';
import ErrorMessage from 'components/generic/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { selectOrderResponse } from 'store/selectors/orderSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

/**
 * Page where customer is redirected in case of an error
 */
const ErrorPage: FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { message } = useAppSelector(selectOrderResponse);

  const wrongMessage = useMemo(() => {
    switch (state) {
      case 'payment':
        return t('payment_failed');
      default:
        return message || t('sorry_something_went_wrong');
    }
  }, [state]);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ErrorMessage message={wrongMessage} />
        </Box>
      </Container>
    </Box>
  );
};

export default ErrorPage;

import React, { FC, useState, MouseEvent } from 'react';
import { Tooltip, MenuItem, Menu, Box, Typography } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { useLanguages } from 'hooks';
import { Language as LanguageIcon } from '@mui/icons-material';
import i18next from 'i18next';

const Language: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const languages = useLanguages();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (code: string) => () => {
    i18next.changeLanguage(code);
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <LanguageIcon sx={{ color: 'primary.contrastText' }} />
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: '600',
            padding: '0px',
            textTransform: 'uppercase',
            position: 'relative',
            color: 'primary.contrastText',
            minWidth: 'auto',
            cursor: 'pointer',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: 'primary.contrastText',
              bottom: '-1px',
              left: '0',
            },
          }}
          onClick={handleClick}
        >
          {i18next.language.slice(0, 2)}
        </Typography>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock
      >
        {languages.map(({ code, name, country_code }) => (
          <Tooltip title={name} placement="left" key={code}>
            <MenuItem
              onClick={handleChangeLanguage(code)}
              sx={{ height: '30px' }}
            >
              <ReactCountryFlag
                style={{
                  width: '20px',
                  height: '20px',
                }}
                countryCode={country_code}
                svg
              />
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
};

export default Language;

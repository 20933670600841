import { useTranslation } from 'react-i18next';
import { ORDER_STATUS_TYPES } from 'types/Order';

const useOrderStatusText = (status?: number) => {
  const { t } = useTranslation();

  const ORDER_STATUS_TEXT = {
    ORDER_STATUS_CONFIRMED: t('confrimed'),
    ORDER_STATUS_DELIVERY: t('in_delivery'),
    ORDER_STATUS_COMPLETED: t('complited'),
    ORDER_STATUS_CANCELLED: t('canceled'),
    DEFAULT: t('new'),
  };

  switch (status) {
    case ORDER_STATUS_TYPES.ORDER_STATUS_COMPLETED:
      return ORDER_STATUS_TEXT.ORDER_STATUS_COMPLETED;
    case ORDER_STATUS_TYPES.ORDER_STATUS_CANCELLED:
      return ORDER_STATUS_TEXT.ORDER_STATUS_CANCELLED;
    case ORDER_STATUS_TYPES.ORDER_STATUS_CONFIRMED:
      return ORDER_STATUS_TEXT.ORDER_STATUS_CONFIRMED;
    case ORDER_STATUS_TYPES.ORDER_STATUS_DELIVERY:
      return ORDER_STATUS_TEXT.ORDER_STATUS_DELIVERY;
    default:
      return ORDER_STATUS_TEXT.DEFAULT;
  }
};

export default useOrderStatusText;

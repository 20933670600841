import { useState, useEffect } from 'react';

const useTrimmed = (text: string, characters: number) => {
  const [trimmedText, setTrimmedText] = useState<string>('');

  useEffect(() => {
    if (trimmedText.length > characters) {
      setTrimmedText(`${text.slice(0, characters)}...`);
    } else {
      setTrimmedText(text);
    }
  }, [trimmedText, text]);

  return trimmedText;
};

export default useTrimmed;

import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectSuccessDelivery,
  selectCheckDelieryIsLoading,
} from 'store/selectors/orderSelectors';
import {
  Success,
  Error,
  Loading,
} from 'components/modals/AddressModal/Modal/Content/CheckDelivery';

interface CheckDeliveryProps {
  handleBackToForm: () => void;
  addressView: string;
}

const CheckDelivery: FC<CheckDeliveryProps> = ({
  handleBackToForm,
  addressView,
}) => {
  const isLoading = useAppSelector(selectCheckDelieryIsLoading);
  const isSuccess = useAppSelector(selectSuccessDelivery);

  return (
    <Box sx={{ height: '387px' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isSuccess ? (
            <Success />
          ) : (
            <Error
              handleBackToForm={handleBackToForm}
              addressView={addressView}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default CheckDelivery;

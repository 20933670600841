import React, { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

interface CounterButtonProps {
  count: number;
  addCount: () => void;
  removeCount: () => void;
}

const CounterButton: FC<CounterButtonProps> = ({
  count,
  addCount,
  removeCount,
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: { xs: '1px solid', sm: '2px solid' },
        borderColor: { xs: 'primary.main', sm: 'primary.main' },
        backgroundColor: 'primary.main',
        borderRadius: '6px',
        width: '100%',
        height: '100%',
      }}
    >
      <IconButton
        sx={{ color: 'primary.contrastText' }}
        aria-label="remove"
        onClick={removeCount}
      >
        <RemoveIcon />
      </IconButton>
      <Typography
        sx={{
          color: 'primary.contrastText',
          fontWeight: '300',
          fontSize: { xs: '16px', sm: '19px' },
          lineHeight: { xs: '19px', sm: '21px' },
        }}
      >
        {count}
      </Typography>
      <IconButton
        sx={{ color: 'primary.contrastText' }}
        aria-label="add"
        onClick={addCount}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default CounterButton;

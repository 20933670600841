import React, { FC, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { CounterButton } from 'components/generic';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { ItemImage } from 'components/basket/BasketContent/BasketList/BasketItem';
import { usePrice } from 'hooks';

interface BasketItemProps {
  src: string;
  price: number;
  sumPrice: number;
  basketId?: number;
  title?: string;
  itemCount?: number;
  description?: string;
  isOnlyPickup?: boolean;
  isOnlyPickupCategory?: boolean;
}

const BasketItem: FC<BasketItemProps> = ({
  price = 0,
  sumPrice = 0,
  basketId = 0,
  title,
  src,
  itemCount = 1,
  description,
  isOnlyPickup,
  isOnlyPickupCategory,
}) => {
  const dispatch = useAppDispatch();
  const { removeProduct, addProductCount, removeProductCount } =
    orderSlice.actions;
  const convertedPrice = usePrice(price);
  const convertedSumPrice = usePrice(sumPrice);

  useEffect(() => {
    if (itemCount === 0) {
      dispatch(removeProduct(basketId));
    }
  }, [itemCount]);

  const handleRemove = () => {
    dispatch(removeProduct(basketId));
  };

  const handleAddCount = () => {
    dispatch(addProductCount(basketId));
  };

  const handleRemoveCount = () => {
    dispatch(removeProductCount(basketId));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: '6px', sm: '10px', md: '20px' },
        alignItems: { xs: 'auto', md: 'center' },
        width: '100%',
        padding: { xs: '10px 0', md: '20px 0' },
        borderBottom: '1px solid',
        borderColor: '#E5E5E5',
      }}
    >
      <Box
        sx={{ display: 'flex', flexGrow: 1, gap: { xs: '13px', sm: '20px' } }}
      >
        <ItemImage
          src={src}
          isOnlyPickup={isOnlyPickup}
          isOnlyPickupCategory={isOnlyPickupCategory}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'space-between' },
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', sm: 'flex-end', md: 'center' },
            gap: '20px',
            flexGrow: { xs: 0, sm: 1 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: {
                xs: 'flex-start',
                sm: 'flex-end',
                md: 'flex-start',
              },
              marginBottom: { xs: '10px', sm: '0' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: { xs: '15px', sm: '20px' },
                lineHeight: { xs: '17px', sm: '23px' },
                marginBottom: '5px',
              }}
              variant="h6"
              color="text.main"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontWeight: '300',
                fontSize: { xs: '12px', sm: '14px' },
                lineHeight: { xs: '15px', sm: '18px' },
              }}
              color="grey.50"
            >
              {description}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '20px' },
              lineHeight: { xs: '17px', sm: '24px' },
              marginRight: '3px',
              fontWeight: '300',
              color: 'grey.50',
              whiteSpace: 'nowrap',
            }}
          >
            {convertedPrice}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: '13px', sm: '20px' },
        }}
      >
        <Box
          sx={{
            width: { xs: '117px', sm: '110px', md: '140px' },
            minWidth: { xs: '117px', sm: '110px' },
            textAlign: 'center',
            height: '45px',
          }}
        >
          <CounterButton
            count={itemCount}
            addCount={handleAddCount}
            removeCount={handleRemoveCount}
          />
        </Box>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            minWidth: { xs: '115px', sm: '135px' },
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '20px' },
              lineHeight: { xs: '19px', sm: '24px' },
              marginRight: '3px',
              fontWeight: '300',
              color: 'grey.50',
            }}
          >
            {convertedSumPrice}
          </Typography>
        </Box>
        <Box sx={{ minWidth: '28px', marginLeft: { xs: 'auto', md: '0' } }}>
          <IconButton
            onClick={handleRemove}
            sx={{ border: '2px solid #777777', padding: '0' }}
            aria-label="remove"
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BasketItem;

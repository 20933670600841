import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';

const WebOffers = () => {
  const { t } = useTranslation();
  const homePageData = useAppSelector(selectPageData('home'));

  if (!homePageData) {
    return null;
  }

  const { offer_image_1, offer_image_2, offer_image_3 } = homePageData;

  return (
    <Box>
      <Container>
        <Box
          sx={{
            display: 'grid',
            columnGap: { xs: 1, sm: 2, md: 4, lg: 5, xl: 5 },
            gridTemplateColumns: {
              xs: 'repeat(3, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: '12%', right: '0px' }}>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '30px',
                  lineHeight: '33px',
                  fontWeight: '600',
                  color: 'text.primary',
                  padding: '10px 27px',
                  backgroundColor: 'primary.main',
                  marginBottom: '17px',
                  textTransform: 'uppercase',
                }}
              >
                {t('home_page.offer_title_1')}
              </Typography>
              <Button
                sx={{
                  fontSize: '23px',
                  color: 'secondary.main',
                  fontWeight: '300',
                  textTransform: 'inherit',
                  borderRadius: '10px',
                  marginRight: '12px',
                  backgroundColor: 'common.white',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                to="/menu"
                component={Link}
                variant="contained"
              >
                {t('order_now')}
              </Button>
            </Box>

            <Box
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              component="img"
              src={offer_image_1}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '30%',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '30px',
                  lineHeight: '33px',
                  fontWeight: '600',
                  color: 'text.primary',
                  padding: '10px 27px',
                  backgroundColor: 'primary.main',
                  marginBottom: '17px',
                  textTransform: 'uppercase',
                }}
              >
                {t('home_page.offer_title_2')}
              </Typography>
              <Button
                sx={{
                  fontSize: '23px',
                  color: 'secondary.main',
                  fontWeight: '300',
                  textTransform: 'inherit',
                  borderRadius: '10px',
                  boxShadow: 'none',
                  marginRight: '15px',
                  backgroundColor: 'common.white',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                to="/menu"
                component={Link}
                variant="contained"
              >
                {t('order_now')}
              </Button>
            </Box>
            <Box
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              component="img"
              src={offer_image_2}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '5%',
                left: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '30px',
                  lineHeight: '33px',
                  fontWeight: '600',
                  color: 'text.primary',
                  padding: '10px 27px',
                  backgroundColor: 'primary.main',
                  marginBottom: '17px',
                  textTransform: 'uppercase',
                }}
              >
                {t('home_page.offer_title_3')}
              </Typography>
              <Button
                sx={{
                  fontSize: '23px',
                  color: 'secondary.main',
                  fontWeight: '300',
                  textTransform: 'inherit',
                  borderRadius: '10px',
                  backgroundColor: 'common.white',
                  boxShadow: 'none',
                }}
                to="/menu"
                component={Link}
                variant="contained"
              >
                {t('order_now')}
              </Button>
            </Box>
            <Box
              sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              component="img"
              src={offer_image_3}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default WebOffers;

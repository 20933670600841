import React, { FC, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useOptionLabel } from 'hooks/menu';

interface OptionCheckboxProps {
  label: string;
  groupId: string | number;
  value: number;
  price?: number;
  selectedItems: number[];
  handleSelect: (value: number, maxCount: number, minCount: number) => void;
  maxCount?: number;
  minCount?: number;
}

const OptionCheckbox: FC<OptionCheckboxProps> = ({
  label,
  groupId,
  value,
  price,
  selectedItems,
  handleSelect,
  maxCount = 1,
  minCount = 0,
}) => {
  const { setValue, control } = useFormContext();
  const [labelWithPrice] = useOptionLabel(label, price);

  useEffect(() => {
    setValue(groupId.toString(), selectedItems);
  }, [selectedItems, groupId]);

  return (
    <FormControlLabel
      label={labelWithPrice}
      key={value}
      sx={{
        position: 'relative',
        color: 'grey.50',
        margin: '0 7px 10px 0',
        borderRadius: '30px',
        '& .MuiFormControlLabel-label': {
          userSelect: 'none',
          padding: '10px 15px',
          fontWeight: '300',
          zIndex: '2',
        },
        '& .Mui-checked+.MuiFormControlLabel-label': {
          color: 'primary.contrastText',
        },
      }}
      defaultValue={value}
      control={
        <Controller
          name={groupId.toString()}
          render={() => {
            return (
              <Checkbox
                sx={{
                  borderRadius: '30px',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  '&.MuiCheckbox-root': {
                    borderRadius: '30px',
                    backgroundColor: 'grey.300',
                    '& span, input, svg': {
                      opacity: '0',
                      height: '0',
                      width: '0',
                    },
                    '&.Mui-checked': {
                      backgroundColor: 'primary.main',
                    },
                  },
                }}
                checked={selectedItems.includes(value)}
                onChange={() => handleSelect(value, maxCount, minCount)}
              />
            );
          }}
          control={control}
        />
      }
    />
  );
};

export default OptionCheckbox;

import React, { FC } from 'react';
import { Typography, SxProps, Theme, Tooltip } from '@mui/material';

interface TruncatedTextProps {
  text: string;
  maxLines: number;
  tooltip?: string;
  toltipPlacement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  sx?: SxProps<Theme>;
}

const TruncatedText: FC<TruncatedTextProps> = ({
  text,
  maxLines,
  tooltip,
  toltipPlacement,
  sx,
}) => {
  return (
    <Tooltip
      sx={{ fontSize: '15px' }}
      PopperProps={{
        disablePortal: true,
      }}
      disableHoverListener={!tooltip}
      disableFocusListener
      disableTouchListener
      title={
        <Typography
          sx={{
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
          }}
        >
          {tooltip}
        </Typography>
      }
      placement={toltipPlacement}
    >
      <Typography
        sx={{
          ...sx,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedText;

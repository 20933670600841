import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Parser from 'html-react-parser';

interface InfoProps {
  title: string;
  text: string;
}

const Info: FC<InfoProps> = ({ title, text }) => {
  return (
    <Box sx={{ margin: { xs: '50px 0', sm: '60px 0', md: '70px 0' } }}>
      <Container>
        <Box sx={{ textAlign: 'center', marginBottom: '70px' }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: { xs: '16px', sm: '20px', md: '30px' },
              lineHeight: { xs: '19px', sm: '26px', md: '36px' },
              fontWeight: '400',
              marginBottom: '22px',
              textTransform: 'capitalize',
            }}
            component="h3"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px' },
              lineHeight: { xs: '16px', sm: '20px', md: '23px' },
              fontWeight: '300',
              marginBottom: '22px',
            }}
            component="div"
          >
            {Parser(text)}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Info;

import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Parser from 'html-react-parser';

interface ArticleProps {
  title: string;
  text: string;
}

const Article: FC<ArticleProps> = ({ title, text }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '23px',
          lineHeight: '27px',
          fontWeight: '300',
          marginBottom: '22px',
          color: 'text.primary',
        }}
        component="h3"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          marginBottom: '27px',
          color: '#4F4F4F',
        }}
        component="div"
      >
        {Parser(text)}
      </Typography>
    </Box>
  );
};

export default Article;

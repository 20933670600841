export type Result<T, E> =
  | { ok: true; value: T; isOk: true; isErr: false }
  | { ok: false; value: E; isOk: false; isErr: true };

export function Ok<T>(value: T): Result<T, never> {
  return { ok: true, value, isOk: true, isErr: false };
}

export function Err<E>(value: E): Result<never, E> {
  return { ok: false, value, isOk: false, isErr: true };
}

import React, { FC, memo } from 'react';
import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButton } from 'components/generic';
import { Card } from 'components/menuPdf';
import { pdfjs } from 'react-pdf';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectMenuPdf } from 'store/selectors/menuSelectors';

const MenuPdf: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menuPdf = useAppSelector(selectMenuPdf);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {t('menu')}
        </title>
      </Helmet>
      <Box sx={{ padding: { xs: '30px 0', md: '40px 0' }, minHeight: '90vh' }}>
        <Container>
          <Box sx={{ marginBottom: '20px' }}>
            <BackButton handleBack={handleBack} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { sm: 'flex-start', md: 'center' },
            }}
          >
            {menuPdf.map((item) => (
              <Card key={item.id} url={item.menu_file} title={item.name} />
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default memo(MenuPdf);

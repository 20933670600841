import { useMemo } from 'react';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBlogPosts } from 'store/selectors/blogSelectors';
import { selectPageData } from 'store/selectors/pageSelectors';
import { selectMenuPdf } from 'store/selectors/menuSelectors';
import { selectCurrentBrandPromotionNews } from 'store/selectors/otherSelectors';
import { MenuTab } from 'types/MenuTabs';

const useMenuTabs = () => {
  const { BOOKING_TABLE } = useAppSelector(selectAppSettingsData);
  const blogPosts = useAppSelector(selectBlogPosts);
  const isAboutPage = useAppSelector(selectPageData('about'));
  const isDeliveryPage = useAppSelector(selectPageData('delivery'));
  const isVacanciesPage = useAppSelector(selectPageData('vacancies'));
  const isReviewsPage = useAppSelector(selectPageData('reviews'));
  const isReservePage = useAppSelector(selectPageData('reserve'));
  const isHomePage = useAppSelector(selectPageData('home'));
  const menuPdf = useAppSelector(selectMenuPdf);
  const isPromotionNews = useAppSelector(selectCurrentBrandPromotionNews);

  const menuTabs = useMemo(() => {
    const tabs: [boolean, MenuTab | MenuTab[]][] = [
      [
        !!isHomePage,
        [
          { label: 'home', path: '/' },
          { label: 'menu', path: '/menu' },
        ],
      ],
      [!isHomePage, { label: 'menu', path: '/' }],
      [menuPdf.length > 0, { label: 'menu_pdf', path: '/menu_pdf' }],
      [!!isDeliveryPage, { label: 'delivery', path: '/delivery' }],
      [!!isReservePage, { label: 'reserve', path: '/reserve' }],
      [
        isPromotionNews.length > 0,
        { label: 'tabs.promotion-news', path: '/promotion-news' },
      ],
      [!!isAboutPage, { label: 'about', path: '/about' }],
      [!!isReviewsPage, { label: 'reviews', path: '/reviews' }],
      [blogPosts.length > 0, { label: 'blog', path: '/blog' }],
      [!!isVacanciesPage, { label: 'vacancies', path: '/vacancies' }],
    ];

    return tabs.filter((tab) => tab[0]).flatMap((tab) => tab[1]);
  }, [
    BOOKING_TABLE,
    blogPosts,
    isHomePage,
    isAboutPage,
    isDeliveryPage,
    isReviewsPage,
    isReservePage,
    isVacanciesPage,
    menuPdf,
    isPromotionNews,
  ]);

  return menuTabs;
};

export default useMenuTabs;

import React, { FC } from 'react';
import { Box } from '@mui/material';
import {
  Receiving,
  BasketList,
  Promo,
  Total,
  BackToMenuButton,
  CalculateTitle,
  Recomendations,
  CheckoutButton,
  Promocode,
} from 'components/basket/BasketContent';
import { SubTitle } from 'components/generic';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuProduct } from 'types/Menu';

interface BasketContentProps {
  products: MenuProduct[];
  isPromocode: boolean;
  handleCheckoutButton: () => void;
  methods: UseFormReturn<FieldValues, any>;
  handleAddressButton: () => void;
}

const BasketContent: FC<BasketContentProps> = ({
  products,
  isPromocode,
  handleCheckoutButton,
  methods,
  handleAddressButton,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ marginBottom: { xs: '6px', sm: '50px' } }}>
        <BackToMenuButton />
      </Box>
      <Box sx={{ marginBottom: { xs: '10px', sm: '30px' } }}>
        <CalculateTitle />
      </Box>
      <Box sx={{ marginBottom: { xs: '19px', sm: '55px' } }}>
        <BasketList />
      </Box>
      {products.length > 0 && (
        <Box sx={{ marginBottom: { xs: '23px', sm: '80px' } }}>
          <Recomendations />
        </Box>
      )}

      <Box sx={{ marginBottom: { xs: '12px', sm: '30px' } }}>
        <SubTitle> {t('order_options')}:</SubTitle>
      </Box>
      <FormProvider {...methods}>
        <Box component="form" noValidate autoComplete="off">
          <Box sx={{ marginBottom: { xs: '40px', sm: '80px' } }}>
            <Receiving handleAddressButton={handleAddressButton} />
          </Box>
          <Box sx={{ marginBottom: { xs: '0px', sm: '10px' } }}>
            <Promo />
          </Box>
          <Box sx={{ marginBottom: { xs: '23px', sm: '40px' } }}>
            {isPromocode && <Promocode />}
          </Box>
          <Total />
          <CheckoutButton handleCheckoutButton={handleCheckoutButton} />
        </Box>
      </FormProvider>
    </>
  );
};

export default BasketContent;

import { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useTimer } from 'hooks';
import { useAuth } from 'hooks/auth';
import { verifyCode } from 'store/reducers/AccountSlice/ActionCreators';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import {
  selectCodeData,
  selectVerifyData,
  selectVerifyIsLoading,
} from 'store/selectors/accountSelectors';
import { AppSettingsData } from 'types/AppSettings';

const useCodeInputStep = (onClose: () => void) => {
  const dispatch = useAppDispatch();
  const { addApiKey } = accountSlice.actions;
  const { control, watch, setError, clearErrors } = useFormContext();
  const loginValue = watch('login');
  const codeValue = watch('code');
  const verifyIsLoading = useAppSelector(selectVerifyIsLoading);
  const { AUTH_SERVICE_TYPE } = useAppSelector<AppSettingsData>(
    selectAppSettingsData
  );
  const { t } = useTranslation();
  const { isTimer, timer, setTimer } = useTimer(30);
  const { getAuthCode } = useAuth();
  const { success, api_key } = useAppSelector(selectVerifyData);
  const { uid } = useAppSelector(selectCodeData);

  const logIn = (code: string, uidCode: string) => {
    dispatch(verifyCode({ uid: uidCode, code }))
      .unwrap()
      .then((result) => {
        if (!result.success && result.reason === 'WRONG_AUTH_CODE') {
          setError('code', {
            type: 'server',
            message: t('invalid_code'),
          });
        }
        if (result.success && result.api_key) {
          dispatch(addApiKey(result.api_key));
        }
      });
  };

  const mask = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 2) {
      return '999999';
    }
    return '9999';
  }, [AUTH_SERVICE_TYPE]);

  const hint = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return t('login.tips.phone_tip_2');
    }

    if (AUTH_SERVICE_TYPE === 2) {
      return t('login.tips.phone_tip_2');
    }

    if (AUTH_SERVICE_TYPE === 3) {
      return t('login.tips.phone_tip_4');
    }

    return t('login.tips.email_tip_2');
  }, [AUTH_SERVICE_TYPE]);

  const trySendAgain = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1 || AUTH_SERVICE_TYPE === 3) {
      return t('login.try_to_call_again', { timer });
    }

    return t('login.try_to_send_again', { timer });
  }, [AUTH_SERVICE_TYPE, timer]);

  const sendAgain = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1 || AUTH_SERVICE_TYPE === 3) {
      return t('login.call_again');
    }

    return t('login.send_again');
  }, [AUTH_SERVICE_TYPE]);

  const handleGetCode = () => {
    if (AUTH_SERVICE_TYPE === 1 || AUTH_SERVICE_TYPE === 3) {
      getAuthCode(loginValue.slice(1), AUTH_SERVICE_TYPE, true);
    }

    if (AUTH_SERVICE_TYPE === 0 || AUTH_SERVICE_TYPE === 2) {
      getAuthCode(loginValue, AUTH_SERVICE_TYPE);
    }
    setTimer(30);
  };

  useEffect(() => {
    setTimer(30);
  }, []);

  useEffect(() => {
    if (AUTH_SERVICE_TYPE === 2) {
      if (codeValue && codeValue.length >= 6) {
        logIn(codeValue, uid);
      }
      if (!codeValue || codeValue.length < 6) {
        clearErrors();
      }
    } else {
      if (codeValue && codeValue.length >= 4) {
        logIn(codeValue, uid);
      }
      if (!codeValue || codeValue.length < 4) {
        clearErrors();
      }
    }
  }, [codeValue, uid]);

  useEffect(() => {
    if (api_key && success) {
      onClose();
    }
  }, [api_key, success]);

  const handleLogIn = () => {
    logIn(codeValue, uid);
  };

  return {
    t,
    control,
    verifyIsLoading,
    isTimer,
    hint,
    handleGetCode,
    loginValue,
    handleLogIn,
    trySendAgain,
    sendAgain,
    mask,
  };
};

export default useCodeInputStep;

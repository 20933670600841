import React, { FC } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';
import { Card } from 'components/promotions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { MainTitle } from 'components/generic';
import { selectPromotionsDataIsLoading } from 'store/selectors/promotionSelectors';
import { selectCurrentBrandPromotionNews } from 'store/selectors/otherSelectors';
import { useTranslation } from 'react-i18next';

const PromotionNews: FC = () => {
  const { t } = useTranslation();
  const promotionIsLoading = useAppSelector(selectPromotionsDataIsLoading);
  const promotionNews = useAppSelector(selectCurrentBrandPromotionNews);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        {promotionIsLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70vh',
              width: '100%',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box sx={{ marginBottom: '40px' }}>
              <MainTitle>{t('tabs.promotion-news')}</MainTitle>
            </Box>
            <Box
              sx={{
                display: 'grid',
                columnGap: { xs: 3, sm: 3, md: 5 },
                rowGap: { xs: 4, md: 5 },
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                },
              }}
            >
              {promotionNews.map((news) => (
                <Card
                  key={news.id}
                  title={news.name}
                  description={news.description ?? ''}
                  desktopImage={news.pictures[0].src}
                  mobileImage={news.thumbnail_url}
                  date={news.date}
                />
              ))}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default PromotionNews;

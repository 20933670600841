import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useSrcImage } from 'hooks';

interface ItemImageProps {
  src: string;
  isOnlyPickup?: boolean;
  isOnlyPickupCategory?: boolean;
}

const ItemImage: FC<ItemImageProps> = ({
  src,
  isOnlyPickup,
  isOnlyPickupCategory,
}) => {
  const { ITEM_PLACEHOLDER_IMAGE } = useAppSelector(selectAppSettingsData);
  const image = useSrcImage(src);
  return (
    <Box sx={{ position: 'relative' }}>
      {isOnlyPickup || isOnlyPickupCategory ? (
        <Box
          sx={{
            position: 'absolute',
            top: '-5px',
            left: '-5px',
            right: '-5px',
            display: 'inline-flex',
            padding: '3px 0px',
            borderRadius: '5px',
            boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.14)',
            backgroundColor: 'primary.main',
            marginBottom: '5px',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', md: '10px' },
              fontWeight: '600',
              lineHeight: '16px',
              color: 'primary.contrastText',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            Только самовывоз
          </Typography>
        </Box>
      ) : null}

      <Box
        sx={{
          height: { xs: '117px', sm: '110px' },
          width: { xs: '117px', sm: '110px' },
          minWidth: { xs: '117px', sm: '110px' },
          overflow: 'hidden',
          borderRadius: '0px',
        }}
      >
        {!image ? (
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            alt="product image"
            src={ITEM_PLACEHOLDER_IMAGE}
          />
        ) : (
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            alt="product image"
            src={image}
          />
        )}
      </Box>
    </Box>
  );
};

export default ItemImage;

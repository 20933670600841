import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { KladrResult } from 'types/Kladr';

interface AutocompleteKladrFieldProps {
  control: Control<FieldValues>;
  name: string;
  options: KladrResult[];
  defaultValue?: number | string | null;
  label?: string;
  required?: boolean;
  requiredText?: string;
  size?: 'small' | 'medium';
}

const AutocompleteKladrField: FC<AutocompleteKladrFieldProps> = ({
  control,
  name,
  defaultValue,
  options,
  label,
  required,
  requiredText,
  size,
}) => {
  const defaultText = requiredText || '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          sx={{
            '& .MuiOutlinedInput-root.MuiOutlinedInput-root': {
              padding: '0px',
            },
          }}
          freeSolo
          options={options.map((option) => option.name)}
          onChange={(e, data) => onChange(data)}
          value={value || null}
          renderInput={(params) => (
            <TextField
              sx={{
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
                  {
                    padding: size === 'small' ? '11px 14px' : '13.5px 14px',
                  },
                '& .MuiInputLabel-root': {
                  color: '#BDBDBD',
                  lineHeight: size === 'small' ? '28px' : '20px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'primary.contrastText',
                },
                '& .MuiInputLabel-shrink': {
                  color: 'primary.contrastText',
                  backgroundColor: 'primary.main',
                  padding: '0px 5px',
                  left: '-5px',
                  top: '2px',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '20px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  '& fieldset': {
                    borderColor: 'primary.main',
                    '& legend': {
                      width: '38px',
                    },
                  },
                  '&:hover fieldset': {
                    border: '1px solid',
                    borderColor: 'secondary.main',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid',
                    borderColor: 'secondary.main',
                  },
                },
                '& .MuiFormHelperText-root': {
                  position: 'absolute',
                  top: '45px',
                  left: '0',
                },
              }}
              {...params}
              label={label}
              fullWidth
              size={size}
              helperText={error ? error.message : null}
              error={!!error}
              onChange={onChange}
            />
          )}
        />
      )}
      rules={{
        required: required || defaultText,
      }}
    />
  );
};

export default AutocompleteKladrField;

import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAccountData } from 'store/selectors/accountSelectors';
import { selectCityNameById } from 'store/selectors/citySelectors';
import { AccountData } from 'types/Account';
import { Address } from 'types/Address';

interface FormFields {
  [x: string]: any;
}

const useAddressQueryData = (
  modalType: 'add' | 'update',
  formValues: FormFields,
  addressId?: number
) => {
  const { id } = useAppSelector<AccountData>(selectAccountData);
  const cityName = useAppSelector<string>(selectCityNameById(formValues.city));

  const data = useMemo(() => {
    const body = {
      name: formValues.name,
      city_name: cityName,
      street: formValues.street,
      house: formValues.house,
      building: formValues.building,
      corpus: formValues.corpus || null,
      apartment: formValues.apartment || '',
      entrance: formValues.entrance || null,
      floor: formValues.floor || null,
      comment: formValues.comment,
      street_classifier_id: '',
      area: formValues.area || null,
      account: id,
      city: formValues.city,
    };

    if (modalType === 'update') {
      return { ...body, id: addressId };
    }

    return body;
  }, [modalType, formValues, addressId, id]) as Address;

  return data;
};

export default useAddressQueryData;

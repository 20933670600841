import React, { FC } from 'react';
import {
  LoginInputStep,
  CodeInputStep,
  CaptchaInputStep,
} from 'components/modals/AuthModal/Modal/AuthSteps';
import { Step } from 'components/modals/AuthModal';

interface AuthStepsProps {
  step: Step;
  onClose: () => void;
  prevStep: () => void;
  nextStep: () => void;
}

const AuthSteps: FC<AuthStepsProps> = ({
  step,
  onClose,
  prevStep,
  nextStep,
}) => {
  switch (step) {
    case Step.LoginInput:
      return <LoginInputStep nextStep={nextStep} onClose={onClose} />;
    case Step.CaptchaInput:
      return <CaptchaInputStep nextStep={nextStep} />;
    case Step.CodeInput:
      return <CodeInputStep onClose={onClose} prevStep={prevStep} />;
    default:
      throw new Error('It cannot be');
  }
};

export default AuthSteps;

import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const Buttons: FC = () => {
  const { BOOKING_TABLE } = useAppSelector(selectAppSettingsData);
  const { t } = useTranslation();
  return (
    <Stack sx={{ marginBottom: '18px' }} direction="row" spacing={3}>
      {BOOKING_TABLE && (
        <Button
          sx={{
            boxShadow: 'none',
            textTransform: 'inherit',
            borderRadius: '6px',
            border: '2px solid',
            borderColor: 'primary.main',
            height: '50px',
            alignSelf: { xs: 'flex-start', sm: 'flex-end' },
            '&:hover': {
              border: '2px solid',
              borderColor: 'primary.main',
            },
          }}
          variant="outlined"
          component={NavLink}
          to="/reserve#book_a_table"
        >
          <Typography
            sx={{
              fontSize: { xs: '18px', sm: '20px' },
              fontWeight: '300',
              lineHeight: { xs: '21px', sm: '23px' },
              color: 'secondary.main',
            }}
          >
            {t('book_a_table')}
          </Typography>
        </Button>
      )}
      <Button
        sx={{
          boxShadow: 'none',
          textTransform: 'inherit',
          borderRadius: '6px',
          border: '2px solid',
          borderColor: 'primary.main',
          height: '50px',
          alignSelf: { xs: 'flex-start', sm: 'flex-end' },
          '&:hover': {
            border: '2px solid',
            borderColor: 'primary.main',
          },
        }}
        variant="outlined"
        component={NavLink}
        to="/"
      >
        <Typography
          sx={{
            fontSize: { xs: '18px', sm: '20px' },
            fontWeight: '300',
            lineHeight: { xs: '21px', sm: '23px' },
            color: 'secondary.main',
          }}
        >
          {t('order_now')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default Buttons;

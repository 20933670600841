import React, { FC } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
  handleBack: () => void;
}

const BackButton: FC<BackButtonProps> = ({ handleBack }) => {
  const { t, i18n } = useTranslation();
  const isLtr = i18n.dir() === 'ltr';

  return (
    <>
      <IconButton
        sx={{
          display: { xs: 'inline-flex', sm: 'none' },
          width: '50px',
          height: '50px',
          '&.MuiButtonBase-root': {
            backgroundColor: 'primary.main',
          },
          border: '3px solid',
          borderColor: '#fff',
        }}
        onClick={handleBack}
      >
        {isLtr ? (
          <KeyboardArrowLeft
            sx={{ color: 'primary.textContrast' }}
            fontSize="large"
          />
        ) : (
          <KeyboardArrowRight fontSize="large" color="secondary" />
        )}
      </IconButton>
      <Button
        sx={{
          display: { xs: 'none', sm: 'inline-flex' },
          textTransform: 'inherit',
        }}
        variant="text"
        onClick={handleBack}
      >
        {isLtr ? (
          <ArrowBackIos color="secondary" />
        ) : (
          <ArrowForwardIos color="secondary" />
        )}

        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            lineHeight: '21px',
            color: 'secondary.main',
            fontWeight: '500',
          }}
        >
          {t('back')}
        </Typography>
      </Button>
    </>
  );
};

export default BackButton;

import React, { FC, useRef } from 'react';
import { Box } from '@mui/material';
import { Modifier } from 'types/Menu';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { Card } from 'components/modals/ProductModal/Drawer/Content/Combo/ComboSlider';

interface ComboSliderProps {
  modifiers: Modifier[];
  id: number;
}

const ComboSlider: FC<ComboSliderProps> = ({ modifiers, id }) => {
  const { setValue } = useFormContext();
  SwiperCore.use([Mousewheel, Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const isSlideActive = (slide: SwiperCore) => {
    const selectedSlideId = modifiers.find(
      (modifier, index) => index === slide.realIndex
    )?.id;
    if (selectedSlideId) {
      setValue(`${id}`, `${selectedSlideId}`);
    }
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        padding: '5px 0',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <KeyboardArrowLeft
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          '&.swiper-button-disabled': {
            opacity: 0,
            cursor: 'inherit',
          },
          cursor: 'pointer',
        }}
        ref={navigationPrevRef}
        fontSize="large"
        color="primary"
      />
      <Box sx={{ maxHeight: '112px', overflow: 'hidden' }}>
        <Swiper
          speed={600}
          onSlideChange={isSlideActive}
          autoHeight
          loop
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpoints={{
            '300': {
              slidesPerView: 1.1,
            },
          }}
        >
          {modifiers.map((modifier) => (
            <SwiperSlide key={modifier.id}>
              <Card
                name={modifier.name}
                description={modifier.description}
                src={modifier.src}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <KeyboardArrowRight
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          zIndex: 100,
          '&.swiper-button-disabled': {
            opacity: 0,
            cursor: 'inherit',
          },
          cursor: 'pointer',
        }}
        fontSize="large"
        color="primary"
        ref={navigationNextRef}
      />
    </Box>
  );
};

export default ComboSlider;

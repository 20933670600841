import React, { FC } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

interface BannerProps {
  title: string;
  image: string;
}

const Banner: FC<BannerProps> = ({ title, image }) => {
  return (
    <>
      {image && (
        <Box
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
            width: '100%',
            position: 'relative',
            zIndex: '0',
            height: '60vh',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              zIndex: '2',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              padding: '15px 25px',
            }}
          >
            {title && (
              <>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '78px',
                    lineHeight: '80px',
                    fontWeight: '400',
                  }}
                >
                  {title}
                </Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'secondary.main',
                    zIndex: '-1',
                    opacity: '0.7',
                  }}
                />
              </>
            )}
          </Box>
          {!image ? (
            <Skeleton
              sx={{ height: '60vh', width: '100%' }}
              animation="wave"
              variant="rectangular"
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              component="img"
              src={image}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Banner;

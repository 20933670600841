import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectMenuWithOffers } from 'store/selectors/menuSelectors';

const useActiveMenuObserver = (
  ref: MutableRefObject<Element | null>,
  activeCategory: string,
  handleSetActiveCategory: (arg: string) => void,
  options: IntersectionObserverInit = {}
) => {
  const [element, setElement] = useState<Element | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const menuWithOffers = useAppSelector(selectMenuWithOffers(''));

  const cleanObserver = () => {
    const currentObserver = observer.current;
    if (currentObserver) {
      currentObserver.disconnect();
    }
  };

  useEffect(() => {
    if (ref) {
      setElement(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (!element) return;
    cleanObserver();
    const position = window.pageYOffset;

    const ob = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        const sortedMenuWithOffers = menuWithOffers.sort(
          (prev, next) => prev.orderBy - next.orderBy
        );
        const firstCategory = sortedMenuWithOffers[0].id.toString();

        if (entry.target.id !== activeCategory && isElementIntersecting) {
          handleSetActiveCategory(entry.target.id);
        }
        if (position <= 0) {
          handleSetActiveCategory(firstCategory);
        }
      },
      { ...options }
    );
    observer.current = ob;

    ob.observe(element);
  }, [element, options, activeCategory]);

  return activeCategory;
};

export default useActiveMenuObserver;

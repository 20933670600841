import React, { FC, useEffect, useState } from 'react';
import { AutocompleteField, SelectField, TextField } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCitiesList,
  selectCityNameById,
} from 'store/selectors/citySelectors';
import { useDebounce } from 'hooks';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';

interface AddressFormProps {
  modalType: 'add' | 'update';
}

const AddressForm: FC<AddressFormProps> = ({ modalType }) => {
  const { control, watch } = useFormContext();
  const cityIdValue = watch('city');
  const address = watch('address');
  const citiesList = useAppSelector(selectCitiesList);
  const cityName = useAppSelector(selectCityNameById(cityIdValue));
  const { IS_APARTMENT_REQUIRED } = useAppSelector(selectAppSettingsData);
  const debouncedValue = useDebounce<string>(
    `${cityName} ${address || ''}`,
    200
  );
  const [options, setOptions] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (modalType === 'add') {
      try {
        axios
          .get('https://autocomplete.search.hereapi.com/v1/autocomplete', {
            params: {
              apikey: process.env.REACT_APP_HERE_API_KEY,
              q: debouncedValue,
              limit: 3,
            },
          })
          .then((response) => {
            setOptions(response.data.items);
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, [debouncedValue, modalType]);

  return (
    <Box>
      <Box sx={{ marginBottom: '20px' }}>
        <TextField
          control={control}
          name="name"
          label={t('short_address_name')}
          size="small"
        />
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <SelectField
          control={control}
          name="city"
          selectItem={citiesList}
          label={t('city')}
          required
          size="small"
        />
      </Box>
      {modalType === 'add' && (
        <Box sx={{ marginBottom: '20px' }}>
          <AutocompleteField
            control={control}
            name="address"
            label={t('address')}
            size="small"
            options={options}
          />
        </Box>
      )}
      <Box sx={{ marginBottom: '20px' }}>
        <TextField
          control={control}
          name="street"
          label={t('street')}
          size="small"
          required
        />
      </Box>
      <Stack sx={{ marginBottom: '20px' }} direction="row" spacing={2}>
        <TextField
          control={control}
          name="house"
          label={t('house')}
          size="small"
          required
        />
        <TextField
          control={control}
          name="apartment"
          label={t('apart')}
          size="small"
          required={IS_APARTMENT_REQUIRED}
        />
      </Stack>
      <Box sx={{ marginBottom: '20px' }}>
        <TextField
          control={control}
          name="area"
          label={t('area')}
          size="small"
        />
      </Box>
    </Box>
  );
};

export default AddressForm;

import React, { FC } from 'react';
import { Modal, Drawer } from 'components/modals/NameModal';
import { useMediaQuery } from 'react-responsive';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';

interface NameModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NameModal: FC<NameModalProps> = ({ isOpen, onClose }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { watch, setError } = methods;
  const nameValue = watch('first_name');
  const api_key = useAppSelector(selectApiKey);

  const onSubmit = () => {
    dispatch(updateAccountData({ api_key, body: { first_name: nameValue } }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          onClose();
        } else {
          setError('first_name', {
            type: 'server',
            message: 'some error',
          });
        }
      });
  };

  return (
    <>
      {isDrawer ? (
        <Drawer
          methods={methods}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <Modal
          methods={methods}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default NameModal;

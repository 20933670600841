import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Page } from 'types/Pages';
import { getPages } from './ActionCreators';

interface PagesState {
  pages: Page[];
  isLoading: boolean;
  error: string;
}

const initialState: PagesState = {
  pages: [],
  isLoading: false,
  error: '',
};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: {
    [getPages.fulfilled.type]: (state, action: PayloadAction<Page[]>) => {
      state.isLoading = false;
      state.error = '';
      state.pages = action.payload;
    },
    [getPages.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPages.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default pagesSlice.reducer;

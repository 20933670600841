import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguages } from 'hooks';

interface LanguageButtonProps {
  handleOpenLanguageDrawer: () => void;
}

const LanguageButton: FC<LanguageButtonProps> = ({
  handleOpenLanguageDrawer,
}) => {
  const { t, i18n } = useTranslation();
  const languages = useLanguages();

  return (
    <>
      {languages.length > 1 && (
        <Stack direction="row" spacing={1} onClick={handleOpenLanguageDrawer}>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '600',
              color: 'text.primary',
            }}
          >
            {i18n.language.slice(-2)}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '400',
            }}
          >
            {t('language')}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default LanguageButton;

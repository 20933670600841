import React, { FC, useState } from 'react';
import { YMaps } from '@pbe/react-yandex-maps';
import { Box, Button, Fade } from '@mui/material';
import { TextArea } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import {
  ClientForm,
  AddressForm,
  DeliveryTimeForm,
} from 'components/basket/BasketContent/Receiving';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAddressIsLoading } from 'store/selectors/addressSelectors';
import { selectCheckDelivery } from 'store/selectors/orderSelectors';
import { CheckDeliveryData } from 'types/Delivery';
import { selectAvailableRestaurants } from 'store/selectors/restaurantSelectors';
import DeliveryMap from './DeliveryMap/DeliveryMap';

interface DeliveryProps {
  handleAddressButton: () => void;
}

const Delivery: FC<DeliveryProps> = ({ handleAddressButton }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const isLoading = useAppSelector(selectAddressIsLoading);
  const { data } = useAppSelector(selectCheckDelivery);
  const restaurants = useAppSelector(selectAvailableRestaurants);
  const [calculateDeliveryTimeFlag, setCalculateDeliveryTimeFlag] =
    useState(false);

  const getUserCoords = (data: CheckDeliveryData) => [
    data.latitude,
    data.longitude,
  ];

  const getRestaurantCoords = (data: CheckDeliveryData) => {
    const restaurant = restaurants.filter(
      (restaurant) => restaurant.id === data.restaurant_id
    )[0];
    return [restaurant.latitude, restaurant.longitude];
  };

  return (
    <Fade in timeout={400}>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          maxWidth: '1130px',
          gridTemplateColumns: {
            xs: '100%',
            sm: '100%',
            md: '55% 40%',
          },
          gridTemplateAreas: {
            xs: '"client-address-form" "map" "order-comment-textarea"',
            sm: '"client-address-form" "map" "order-comment-textarea"',
            md: '"client-address-form map" "order-comment-textarea map"',
          },
          columnGap: '60px',
          rowGap: '16px',
        }}
      >
        <Box
          sx={{
            gridArea: 'client-address-form',
          }}
        >
          <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
            <ClientForm />
          </Box>
          <Box sx={{ marginBottom: { xs: '30px', sm: '50px' } }}>
            {!isLoading && (
              <AddressForm handleAddressButton={handleAddressButton} />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: { xs: '4px', sm: '8px' },
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' },
            }}
          >
            <DeliveryTimeForm />
            {!calculateDeliveryTimeFlag && data && (
              <Button onClick={() => setCalculateDeliveryTimeFlag(true)}>
                Рассчитать время доставки
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'order-comment-textarea',
          }}
        >
          <Box>
            <TextArea
              control={control}
              name="comment"
              label={t('comment_on_the_order')}
            />
          </Box>
        </Box>
        {calculateDeliveryTimeFlag && data && (
          <Box
            sx={{
              gridArea: 'map',
              width: '100%',
              height: '100%',
            }}
          >
            <YMaps
              query={{
                lang: 'ru_RU',
                apikey: process.env.REACT_APP_YANDEX_MAPS_API_KEY,
              }}
            >
              <DeliveryMap
                userCoords={getUserCoords(data)}
                restaurantCoords={getRestaurantCoords(data)}
              />
            </YMaps>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default Delivery;

import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { MainTitle } from 'components/generic';
import { BookingForm, BookingButton } from 'components/reserve/Booking';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useBooking } from 'hooks/booking';
import { AuthModal } from 'components/modals';

interface BookingProps {
  src: string;
}

const Booking: FC<BookingProps> = ({ src }) => {
  const { methods, onSubmit, authModal, handleCloseModal } = useBooking();
  const { t } = useTranslation();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          marginBottom: { xs: '30px', sm: '50px', md: '75px' },
          paddingTop: { xs: '23px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            paddingRight: { xs: '0', md: '30px' },
          }}
          id="book_a_table"
        >
          <Box sx={{ marginBottom: '20px' }}>
            <MainTitle>{t('book_a_table')}</MainTitle>
          </Box>
          <FormProvider {...methods}>
            <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
              <BookingForm />
              <BookingButton />
            </Box>
          </FormProvider>
        </Box>
        <Box
          sx={{
            width: '50%',
            paddingLeft: '30px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            component="img"
            src={src}
          />
        </Box>
      </Box>
      <AuthModal isOpen={authModal} onClose={handleCloseModal} />
    </Container>
  );
};

export default Booking;

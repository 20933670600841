import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="9" fill="#404040" />
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693604 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9963 8.81852 22.7309 5.76841 20.4812 3.51876C18.2316 1.26912 15.1815 0.00366459 12 0ZM12 5.53846C12.2739 5.53846 12.5416 5.61967 12.7693 5.77181C12.997 5.92395 13.1744 6.1402 13.2792 6.39321C13.384 6.64621 13.4114 6.92461 13.358 7.1932C13.3046 7.46179 13.1727 7.7085 12.9791 7.90215C12.7854 8.09579 12.5387 8.22766 12.2701 8.28109C12.0015 8.33451 11.7231 8.30709 11.4701 8.20229C11.2171 8.09749 11.0009 7.92002 10.8487 7.69233C10.6966 7.46463 10.6154 7.19693 10.6154 6.92307C10.6154 6.55585 10.7613 6.20367 11.0209 5.944C11.2806 5.68434 11.6328 5.53846 12 5.53846ZM12.9231 18.4615H12C11.8788 18.4616 11.7587 18.4378 11.6467 18.3914C11.5346 18.3451 11.4328 18.2771 11.3471 18.1913C11.2614 18.1056 11.1934 18.0038 11.147 17.8918C11.1007 17.7798 11.0768 17.6597 11.0769 17.5385V12C10.8321 12 10.5973 11.9027 10.4242 11.7296C10.2511 11.5565 10.1538 11.3217 10.1538 11.0769C10.1538 10.8321 10.2511 10.5973 10.4242 10.4242C10.5973 10.2511 10.8321 10.1538 11.0769 10.1538H12C12.1212 10.1538 12.2413 10.1776 12.3533 10.224C12.4654 10.2703 12.5672 10.3383 12.6529 10.424C12.7386 10.5098 12.8066 10.6116 12.853 10.7236C12.8993 10.8356 12.9232 10.9557 12.9231 11.0769V16.6154C13.1679 16.6154 13.4027 16.7126 13.5758 16.8857C13.7489 17.0589 13.8462 17.2936 13.8462 17.5385C13.8462 17.7833 13.7489 18.0181 13.5758 18.1912C13.4027 18.3643 13.1679 18.4615 12.9231 18.4615Z"
        fill="#E9E8E8"
      />
    </SvgIcon>
  );
};

export default InfoIcon;

import { useMemo } from 'react';

const useRandomId = (order_by: number) => {
  const randomNumber = useMemo(() => {
    return Math.random() + 33 * order_by;
  }, [order_by]);
  return randomNumber;
};

export default useRandomId;

import React, { FC } from 'react';
import { Typography, Box, Stack, Button } from '@mui/material';
import { MainTitle } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectBasketLength,
  selectResultTotalPrice,
  selectTotalPrice,
} from 'store/selectors/orderSelectors';
import { useTitleEnding } from 'hooks/basket';
import { useTranslation } from 'react-i18next';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';

const CalculateTitle: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const basketLength = useAppSelector<number>(selectBasketLength);
  const { totalPrice } = useAppSelector(selectTotalPrice);
  const { resultTotalPrice } = useAppSelector(selectResultTotalPrice);
  const ending = useTitleEnding(basketLength);
  const { clearOrder } = orderSlice.actions;

  const handleClearBasket = () => {
    dispatch(clearOrder());
    localStorage.removeItem('persist:order');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      {basketLength > 0 ? (
        <Stack
          sx={{ justifyContent: 'space-between', width: '100%' }}
          direction="row"
          spacing={1}
        >
          <Box>
            <Box sx={{ marginBottom: '10px' }}>
              <MainTitle>{t('your_order')}</MainTitle>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: { xs: '14px', sm: '18px' },
                lineHeight: { xs: '16px', sm: '19px' },
                fontWeight: '400',
                color: 'text.main',
              }}
            >
              ({basketLength} {ending}, {resultTotalPrice})
            </Typography>
          </Box>
          <Button
            sx={{
              borderRadius: '6px',
              textTransform: 'capitalize',
              fontSize: { xs: '14px', sm: '20px' },
              lineHeight: { xs: '21px', sm: '23px' },
              fontWeight: '300',
              boxShadow: 'none',
              color: 'text.primary',
              height: '50px',
              '&:hover': {
                boxShadow: 'none',
              },
              '&:focus': {
                boxShadow: 'none',
              },
            }}
            variant="text"
            onClick={handleClearBasket}
          >
            Очистить корзину
          </Button>
        </Stack>
      ) : (
        <MainTitle>{t('your_order')}</MainTitle>
      )}
    </Box>
  );
};

export default CalculateTitle;

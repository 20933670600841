import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Portal } from 'components/generic';
import { animated } from 'react-spring';
import { useDrawer } from 'hooks';
import { Content } from 'components/modals/AgeModal';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  handleSelectBrand: () => void;
  text?: string;
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  handleSelectBrand,
  text,
}) => {
  const { isMount, y } = useDrawer(isOpen, onClose);

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            <Box
              sx={{
                height: '100vh',
                width: '100%',
                padding: '70px 20px 10px 20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  marginBottom: '10px',
                }}
              >
                Выберите ресторан
              </Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '100%',
                  width: '100%',
                  padding: '0 0 30px 0',
                  '&::-webkit-scrollbar': {
                    width: 7,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey.400',
                    borderRadius: '20px',
                  },
                }}
              >
                <Content
                  text={text}
                  onClose={onClose}
                  handleSelectBrand={handleSelectBrand}
                />
              </Box>
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;

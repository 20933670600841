import { useEffect, useState } from 'react';

const useTimer = (count: number) => {
  const [timer, setTimer] = useState<number>(count);

  const isTimer = timer > 0;

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);
  return { isTimer, timer, setTimer };
};

export default useTimer;

import React, { FC, useCallback } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { SubTitle, BackButton } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

const PrivacyPolicy: FC = () => {
  const privacyPolicyPageData = useAppSelector(
    selectPageData('privacy_policy')
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  if (!privacyPolicyPageData) {
    return null;
  }

  const { text } = privacyPolicyPageData;

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_PRIVACY_POLICY_PAGE_TITLE ||
            process.env.REACT_APP_NAME}
        </title>
        {process.env.REACT_APP_PRIVACY_POLICY_PAGE_DESCRIPTION && (
          <meta
            name="description"
            content={process.env.REACT_APP_PRIVACY_POLICY_PAGE_DESCRIPTION}
          />
        )}
      </Helmet>

      <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
        <Container>
          <Box sx={{ marginBottom: '20px' }}>
            <BackButton handleBack={handleBack} />
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <SubTitle>{t('privacy_policy')}</SubTitle>
          </Box>
          {text && (
            <Typography
              sx={{
                fontSize: { xs: '16px', sm: '18px' },
                lineHeight: { xs: '18px', sm: '22px' },
                fontWeight: '300',
              }}
              component="div"
            >
              {Parse(text)}
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PrivacyPolicy;

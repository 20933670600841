import React, { FC } from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import { MainTitle } from 'components/generic';
import {
  BackToCartButton,
  Bonuses,
  PaymentInfo,
  PaymentTypeSelect,
} from 'components/payment';
import { LoadingButton } from '@mui/lab';
import { CloudPaymentModal } from 'components/modals';
import { Helmet } from 'react-helmet';
import { FormProvider } from 'react-hook-form';
import { usePayment } from 'hooks/order';
/**
 * Page for collection payment information of a user
 */
const Payment: FC = () => {
  const {
    t,
    methods,
    onSubmit,
    handleSubmit,
    BONUS_PROGRAM,
    orderTotalPrice,
    paymentTypes,
    orderIsLoading,
    control,
    orderButtonText,
    cloudPaymentModal,
    cloudPaymentModalClose,
    isCloudPayment,
    cloudPaymentModalOpen,
    hanldeGenCryptoKey,
  } = usePayment();

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0 92px 0' } }}>
      <Helmet>
        <script src="https://checkout.cloudpayments.ru/checkout.js" />
      </Helmet>
      <Container>
        <Box sx={{ marginBottom: { xs: '6px', sm: '50px' } }}>
          <BackToCartButton />
        </Box>
        <Box
          sx={{
            display: { xs: 'inline-flex', md: 'none' },
            marginBottom: { xs: '20px', sm: '64px' },
          }}
        >
          <MainTitle>{t('payment')}</MainTitle>
        </Box>
        <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={5}>
          <FormProvider {...methods}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: { xs: '100%', md: '50%' },
                marginBottom: { xs: '40px', md: '0px' },
              }}
              component="form"
              noValidate
              autoComplete="off"
            >
              <Box
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  marginBottom: { xs: '20px', sm: '64px' },
                }}
              >
                <MainTitle>{t('payment')}</MainTitle>
              </Box>
              <Box sx={{ marginBottom: { xs: '30px', sm: '40px' } }}>
                <PaymentTypeSelect
                  control={control}
                  paymentTypes={paymentTypes}
                  disabled={orderIsLoading}
                />
              </Box>
              {BONUS_PROGRAM && (
                <Box sx={{ marginBottom: '30px' }}>
                  <Bonuses />
                </Box>
              )}
              <Box>
                <Stack
                  sx={{ marginBottom: { xs: '20px', md: '22px' } }}
                  direction="row"
                  spacing={1}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '30px' },
                      lineHeight: { xs: '35px' },
                      fontWeight: '400',
                    }}
                  >
                    {t('total')}
                  </Typography>
                  <Stack direction="row" spacing={0.8}>
                    <Typography
                      sx={{
                        fontSize: { xs: '30px' },
                        lineHeight: { xs: '35px' },
                        fontWeight: '400',
                      }}
                    >
                      {orderTotalPrice}
                    </Typography>
                  </Stack>
                </Stack>
                <LoadingButton
                  sx={{
                    height: { xs: '43px', sm: '50px' },
                    fontSize: { xs: '16px', sm: '18px' },
                    lineHeight: { xs: '18px', sm: '21px' },
                    textTransform: 'inherit',
                    width: { xs: '100%', sm: '290px' },
                    boxShadow: 'none',
                    color: 'primary.contrastText',
                    fontWeight: '300',
                  }}
                  onClick={
                    isCloudPayment
                      ? cloudPaymentModalOpen
                      : handleSubmit(onSubmit)
                  }
                  variant="contained"
                  loading={orderIsLoading}
                >
                  {orderButtonText}
                </LoadingButton>
              </Box>
              <CloudPaymentModal
                isOpen={cloudPaymentModal}
                onClose={cloudPaymentModalClose}
                hanldeGenCryptoKey={hanldeGenCryptoKey}
              />
            </Box>
          </FormProvider>
          <Box sx={{ width: { xs: '100%', md: '50%' } }}>
            <PaymentInfo />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Payment;

import { Box, Container } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { OrderResult } from 'components/order';
import { useTranslation } from 'react-i18next';

/**
 * Page displayed on successful order placement.
 * Order without online payment.
 */
const OrderSuccess: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { order_id } = state as { order_id?: number };
  const { t } = useTranslation();

  if (order_id === null || order_id === undefined) {
    navigate('/error', { state: 'order' });
    throw new Error('No order id passed after order have been placed.');
  }

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <OrderResult
            orderNumber={order_id}
            orderMessage={t('the_order_has_been_placed')}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default OrderSuccess;

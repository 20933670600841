import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { createBooking } from 'store/reducers/BookingSlice/ActionCreators';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useDateTimeValue } from 'hooks/booking';
import { useAuth } from 'hooks/auth';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { SnackActions } from 'components/generic';

const useBooking = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { watch, reset } = methods;
  const formValues = watch();
  const dayValue = watch('day');
  const timeValues = watch('time');
  const [authModal, setAuthModal] = useState<boolean>(false);
  const { isAuth } = useAuth();
  const { clearAuthResponse } = accountSlice.actions;
  const bookingTime = useDateTimeValue(dayValue, timeValues);
  const api_key = useAppSelector(selectApiKey);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleOpenModal = useCallback(() => {
    setAuthModal(true);
  }, [authModal]);

  const handleCloseModal = useCallback(() => {
    setAuthModal(false);
    dispatch(clearAuthResponse());
  }, [authModal]);

  const onSubmit = () => {
    if (isAuth) {
      dispatch(
        createBooking({
          api_key,
          body: {
            name: formValues.name,
            phone: formValues.phone,
            booking_time: bookingTime,
            quantity_people: formValues.quantity_people,
            restaurant: formValues.restaurant,
            comment: formValues.comment,
            email: formValues.email,
          },
        })
      )
        .unwrap()
        .then((result) => {
          if (result.success) {
            reset();
            enqueueSnackbar(t('successfully_booked'), {
              action: SnackActions,
              variant: 'success',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
          } else {
            enqueueSnackbar(t('something_went_wrong'), {
              action: SnackActions,
              variant: 'error',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
          }
        });
    } else {
      handleOpenModal();
    }
  };

  return {
    methods,
    onSubmit,
    authModal,
    handleCloseModal,
  };
};

export default useBooking;

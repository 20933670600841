import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { Advantage } from 'components/about/Advantages';
import { Advantage as AdvantageType } from 'types/Pages';

interface AdvantagesProps {
  advantages: AdvantageType[];
}

const Advantages: FC<AdvantagesProps> = ({ advantages }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          backgroundColor: 'secondary.main',
          opacity: '0.3',
          zIndex: '-1',
        }}
      />
      <Container>
        <Box
          sx={{
            display: 'grid',
            columnGap: { xs: 1, sm: 15, md: 20, lg: 30 },
            rowGap: { xs: 2, sm: 3 },
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
            padding: { xs: '20px 0', sm: '50px 0' },
          }}
        >
          {advantages.map((advantage) => (
            <Advantage
              key={advantage.icon}
              title={advantage.title}
              text={advantage.text}
              icon={advantage.icon}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Advantages;

import React, { useMemo, useState } from 'react';
import {
  MirIcon,
  CardPlaceholderIcon,
  MasterCardIcon,
  VisaIcon,
  UnionPayIcon,
} from 'components/icons';

const usePaymentCardNumber = (cardNumber: string) => {
  const [cardPaymentSystem, setCardPaymentSystem] = useState<string>('');

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const masterCard = range(51, 54, 1);
  const newMasterCard = range(2221, 2720, 1);
  const visa = [4];
  const mir = range(2200, 2204, 1);
  const unionPay = [62];
  const americanExpress = [34, 37];

  const cardNumberMask = useMemo(() => {
    if (masterCard.includes(Number(cardNumber.slice(0, 2)))) {
      setCardPaymentSystem('masterCard');
      return '9999 9999 9999 9999';
    }
    if (newMasterCard.includes(Number(cardNumber.slice(0, 4)))) {
      setCardPaymentSystem('newMasterCard');
      return '9999 9999 9999 9999';
    }
    if (visa.includes(Number(cardNumber.slice(0, 1)))) {
      setCardPaymentSystem('visa');
      return '9999 9999 9999 9999';
    }
    if (mir.includes(Number(cardNumber.slice(0, 4)))) {
      setCardPaymentSystem('mir');
      return '9999 9999 9999 9999 999';
    }
    if (unionPay.includes(Number(cardNumber.slice(0, 2)))) {
      setCardPaymentSystem('unionPay');
      return '9999 9999 9999 9999 999';
    }
    if (americanExpress.includes(Number(cardNumber.slice(0, 2)))) {
      setCardPaymentSystem('americanExpress');
      return '9999 999999 99999';
    }
    setCardPaymentSystem('');
    return '9999999999999999999';
  }, [cardNumber]);

  const cardIcon = useMemo(() => {
    switch (cardPaymentSystem) {
      case 'mir':
        return <MirIcon fontSize="large" />;
      case 'visa':
        return <VisaIcon fontSize="large" />;
      case 'masterCard':
        return <MasterCardIcon fontSize="large" />;
      case 'newMasterCard':
        return <MasterCardIcon fontSize="large" />;
      case 'unionPay':
        return <UnionPayIcon fontSize="large" />;
      default:
        return <CardPlaceholderIcon fontSize="large" />;
    }
  }, [cardNumber, cardPaymentSystem]);

  return { cardNumberMask, cardIcon };
};

export default usePaymentCardNumber;

import React, { FC, useCallback } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  Articles,
  Banner,
  Info,
  MainBanner,
  Offers,
  Recommendations,
  Reviews,
} from 'components/home';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectImagesData,
  selectPageData,
} from 'store/selectors/pageSelectors';
import { Helmet } from 'react-helmet';

const Home: FC = () => {
  const { qr_code } = useAppSelector(selectImagesData);
  const homePageData = useAppSelector(selectPageData('home'));

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToOrder = useCallback(() => {
    navigate('/menu');
  }, []);

  if (!homePageData) {
    return null;
  }

  const {
    banner_title_1,
    banner_title_2,
    banner_text_1,
    banner_text_2,
    main_banner_image,
    banner_image_1,
    banner_image_2,
    home_title_1,
    home_title_2,
    home_text_1,
    home_text_2,
    banner_mobile_image_2,
    burger,
    main_banner_logo,
    opportunities,
    reviews,
  } = homePageData;

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>
      <MainBanner src={main_banner_image} logo={main_banner_logo} />
      <Info title={home_title_1} text={home_text_1} />
      <Offers />
      <Recommendations categoryId={19} title={t('home_page.our_signatures')} />
      <Banner
        type={1}
        title={banner_title_1}
        text={banner_text_1}
        src={banner_image_1}
        handleClick={handleToOrder}
        textColor={process.env.REACT_APP_TEXT_WHITE}
        burger={burger}
      />
      <Info title={home_title_2} text={home_text_2} />
      <Recommendations categoryId={21} title={t('home_page.feel_the_taste')} />
      <Reviews data={reviews} />
      <Banner
        type={2}
        title={banner_title_2}
        src={banner_image_2}
        text={banner_text_2}
        textColor={process.env.REACT_APP_TEXT_BLACK}
        qrCodeImage={qr_code}
        mobileBannerImage={banner_mobile_image_2}
      />
      {opportunities.length > 0 && <Articles data={opportunities} />}
    </>
  );
};

export default Home;

import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { OrderItem } from 'components/payment/PaymentInfo';
import { useTranslation } from 'react-i18next';
import {
  selectBenefitPrice,
  selectDeliveryPrice,
  selectGeneratedOrder,
  selectResultTotalPrice,
} from 'store/selectors/orderSelectors';

const PaymentInfo: FC = () => {
  const { t } = useTranslation();
  const { benefitPrice } = useAppSelector(selectBenefitPrice);
  const { deliveryPrice } = useAppSelector(selectDeliveryPrice);
  const { resultTotalPrice } = useAppSelector(selectResultTotalPrice);
  const items = useAppSelector(selectGeneratedOrder);
  const { LOGO_LIGHT } = useAppSelector(selectAppSettingsData);

  return (
    <Box
      sx={{
        padding: { xs: '18px 14px', sm: '30px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        position: 'relative',
        overflow: 'hidden',
        alignSelf: { xs: 'auto', md: 'flex-start' },
        maxWidth: { xs: '100%', md: '510px' },
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.main',
          zIndex: '-1',
          opacity: '0.2',
        }}
      />
      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'secondary.main',
          flexGrow: '1',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: { xs: '22px', sm: '32px' },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '20px', sm: '30px' },
              lineHeight: { xs: '22px', sm: '33px' },
              fontWeight: '400',
              color: 'text.primary',
            }}
          >
            {t('my_order')}
          </Typography>
          <Box
            sx={{ maxWidth: { xs: '98px', sm: 'auto' } }}
            component="img"
            src={LOGO_LIGHT}
          />
        </Box>
        <Box
          sx={{
            alignSelf: 'flex-end',
            display: 'grid',
            columnGap: { xs: 1.3, sm: 1.3 },
            rowGap: { xs: 1.9, sm: 2.5 },
            gridTemplateColumns: {
              xs: '1fr 10% 23%',
              sm: '1fr 8% 18%',
              md: '1fr 8% 26%',
              lg: '1fr 5% 25%',
            },
            marginBottom: '20px',
          }}
        >
          {items.map((orderItem) => (
            <OrderItem
              key={orderItem.id}
              title={orderItem.title}
              count={orderItem.count}
              price={orderItem.price}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: { xs: '13px', sm: '30px' },
          alignSelf: 'flex-end',
          display: 'grid',
          columnGap: { xs: 1.3, sm: 1.3 },
          rowGap: { xs: 1.9, sm: 2.5 },
          gridTemplateColumns: {
            xs: '1fr 0.4fr',
            sm: '1fr 0.4fr',
          },
          justifyItems: 'flex-end',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '500',
          }}
        >
          {t('promo_benefits')}:
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '300',
          }}
        >
          {benefitPrice}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '500',
          }}
        >
          {t('delivery_cost')}:
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '300',
          }}
        >
          {deliveryPrice}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '500',
          }}
        >
          {t('total')}:
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '300',
          }}
        >
          {resultTotalPrice}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentInfo;

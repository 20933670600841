import { createRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectMenuIsLoading,
  selectFilteredMenuWithBrandOffers,
  selectFilteredMenuWithOffers,
} from 'store/selectors/menuSelectors';
import { useWindowDimensions } from 'hooks/menu';
import { selectBrandId } from 'store/selectors/brandsSelectors';

const useMenu = () => {
  const isLoading = useAppSelector(selectMenuIsLoading);
  const [opacity, setOpacity] = useState<number>(1);
  const { pageHeight } = useWindowDimensions();
  const { t } = useTranslation();
  const observerMargin = Math.floor(pageHeight / 2);
  const brandId = useAppSelector(selectBrandId);
  const menuWithOffers = useAppSelector(
    brandId
      ? selectFilteredMenuWithBrandOffers(t('promo'), brandId)
      : selectFilteredMenuWithOffers(t('promo'))
  );
  const [activeCategoryId, setActiveCategoryId] = useState<string>(
    menuWithOffers[0]?.id
  );

  const rootMargin = useMemo(() => {
    return `-${
      pageHeight % 2 === 0 ? observerMargin - 1 : observerMargin
    }px 0px -${observerMargin}px 0px`;
  }, [observerMargin]);

  const categoryRefs = useMemo(() => {
    return menuWithOffers.reduce((refsObj: any, category) => {
      refsObj[category.id] = createRef<HTMLDivElement>();
      return refsObj;
    }, {});
  }, [menuWithOffers]);

  const handleScrollTo = (id: string) => () => {
    setOpacity(0);
    setTimeout(() => {
      setOpacity(1);
    }, 400);

    const element = categoryRefs[id].current;
    const headerOffset = 190;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
      });
    }, 300);
  };

  const handleSetActiveCategory = (categoryId: string) => {
    setActiveCategoryId(categoryId);
  };

  return {
    t,
    opacity,
    activeCategoryId,
    menuWithOffers,
    categoryRefs,
    rootMargin,
    handleScrollTo,
    handleSetActiveCategory,
    isLoading,
  };
};

export default useMenu;

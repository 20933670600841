import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ruLocale from 'dayjs/locale/ru';

interface DatePickerProps {
  control: Control<FieldValues>;
  name: string;
  label?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({
  control,
  name,
  label,
  required,
  size,
  defaultValue,
  minDate,
  maxDate,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue?.toString() || null}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={ruLocale}
        >
          <DesktopDatePicker
            inputFormat="DD.MM.YYYY"
            value={value}
            label={label}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                sx={{
                  '& .MuiOutlinedInput-input': {
                    padding: size === 'small' ? '11px 14px' : '13.5px 14px',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#BDBDBD',
                    lineHeight: size === 'small' ? '28px' : '20px',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'primary.contrastText',
                  },
                  '& .MuiInputLabel-shrink': {
                    color: 'primary.contrastText',
                    backgroundColor: 'primary.main',
                    padding: '0px 5px',
                    left: '-5px',
                    top: '2px',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '20px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                    '& fieldset': {
                      borderColor: 'primary.main',
                      '& legend': {
                        width: '38px',
                      },
                    },
                    '&:hover fieldset': {
                      border: '1px solid',
                      borderColor: 'secondary.main',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid',
                      borderColor: 'secondary.main',
                    },
                  },
                }}
                size={size}
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      )}
      rules={{
        required,
      }}
    />
  );
};

export default DatePicker;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectBlog = (state: RootState) => state.blog;

/**
 * Returns the blogPosts
 */
export const selectBlogPosts = createSelector([selectBlog], (blog) =>
  [...blog.blogPosts].sort(
    (prev, next) =>
      new Date(next.published_at).getTime() -
      new Date(prev.published_at).getTime()
  )
);
/**
 * Returns blog is loading
 */
export const selectBlogIsLoading = createSelector(
  [selectBlog],
  (blog) => blog.isLoading
);

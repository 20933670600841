import React, { FC } from 'react';
import { Drawer } from 'components/modals/LanguageModal';

interface LanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LanguageModal: FC<LanguageModalProps> = ({ isOpen, onClose }) => {
  return <Drawer isOpen={isOpen} onClose={onClose} />;
};

export default LanguageModal;

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { formatPrice } from 'helpers';

const usePrice = (priceCop: number) => {
  const { CURRENCY_CODE } = useAppSelector(selectAppSettingsData);
  return formatPrice(priceCop, CURRENCY_CODE);
};

export default usePrice;

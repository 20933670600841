import React, { FC, useCallback } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { SubTitle, BackButton } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';
import Parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Vacancies: FC = () => {
  const navigate = useNavigate();
  const vacanciesPageData = useAppSelector(selectPageData('vacancies'));
  const { t } = useTranslation();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  if (!vacanciesPageData) {
    return null;
  }

  const { text } = vacanciesPageData;

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box sx={{ marginBottom: '20px' }}>
          <BackButton handleBack={handleBack} />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <SubTitle>{t('vacancies')}</SubTitle>
        </Box>
        {text && (
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '18px' },
              lineHeight: { xs: '18px', sm: '22px' },
              fontWeight: '300',
            }}
            component="div"
          >
            {Parse(text)}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default Vacancies;

import React, { FC } from 'react';
import { Container, Box, CircularProgress } from '@mui/material';
import { MainTitle } from 'components/generic';
import { Card } from 'components/blog';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectBlogPosts,
  selectBlogIsLoading,
} from 'store/selectors/blogSelectors';

const Blog: FC = () => {
  const { t } = useTranslation();
  const blogPosts = useAppSelector(selectBlogPosts);
  const isLoading = useAppSelector(selectBlogIsLoading);

  return (
    <>
      <Box sx={{ padding: { xs: '27px 0', sm: '32px 0', md: '54px 0' } }}>
        <Container>
          <Box sx={{ marginBottom: { xs: '18px', sm: '23px', md: '37px' } }}>
            <MainTitle>{t('blog')}</MainTitle>
          </Box>

          <Box
            sx={{
              display: 'grid',
              columnGap: { xs: 1, sm: 3, md: 4, lg: 5 },
              rowGap: { xs: 1, sm: 3, md: 4, lg: 5 },
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            {blogPosts.map((post) => (
              <Card
                id={post.id}
                key={post.id}
                image={post.image}
                title={post.title}
                announce={post.announce}
                description={post.description}
                published_at={post.published_at}
              />
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Blog;

import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { Article } from 'components/home/Articles';
import { useMediaQuery } from 'react-responsive';
import { Opportunity } from 'types/Pages';

interface ArticlesProps {
  data: Opportunity[];
}

const Articles: FC<ArticlesProps> = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {!isMobile && (
        <Box sx={{ paddingBottom: '58px' }}>
          <Container>
            <Box
              sx={{
                paddingTop: '90px',
                display: 'grid',
                columnGap: { xs: 2, sm: 3, md: 10 },
                rowGap: { xs: 2, sm: 3, md: 10 },
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  md: 'repeat(2, 1fr)',
                },
              }}
            >
              {data.map((article) => (
                <Article
                  key={article.id}
                  title={article.title}
                  text={article.description}
                />
              ))}
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Articles;

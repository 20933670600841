import React, { FC } from 'react';
import { Modal, Drawer } from 'components/modals/ProductModal';
import { useMediaQuery } from 'react-responsive';

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
  categoryId: number;
}

const ProductModal: FC<ProductModalProps> = ({
  isOpen,
  onClose,
  productId,
  categoryId,
}) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          productId={productId}
          categoryId={categoryId}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          productId={productId}
          categoryId={categoryId}
        />
      )}
    </>
  );
};

export default ProductModal;

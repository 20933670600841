import React, { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import { selectAccountData } from 'store/selectors/accountSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAuth } from 'hooks/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AccountData } from 'types/Account';

interface LoginButtonProps {
  handleOpenAuthDrawer: () => void;
}

const LoginButton: FC<LoginButtonProps> = ({ handleOpenAuthDrawer }) => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const { first_name } = useAppSelector<AccountData>(selectAccountData);
  const { t } = useTranslation();

  const handleNavigate = () => {
    navigate('/account');
  };

  const handleButton = useMemo(() => {
    if (isAuth) {
      return handleNavigate;
    }
    return handleOpenAuthDrawer;
  }, [isAuth]);

  const buttonName = useMemo(() => {
    if (isAuth && first_name) {
      return first_name;
    }
    if (isAuth && !first_name) {
      return t('account');
    }
    return t('sign_in');
  }, [isAuth, first_name]);

  return (
    <Stack
      sx={{ alignItems: 'center' }}
      direction="row"
      spacing={0.2}
      onClick={handleButton}
    >
      <Person sx={{ marginRight: '6px' }} color="secondary" fontSize="medium" />
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '19px',
          fontWeight: '400',
        }}
      >
        {buttonName}
      </Typography>
    </Stack>
  );
};

export default LoginButton;

import React, { FC, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BackDrawerButton, Portal } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { useWindowDimensions } from 'hooks/menu';
import { useDisableBodyScroll, useDelayUnmount, useLanguages } from 'hooks';
import ReactCountryFlag from 'react-country-flag';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface DrawerProps {
  onClose: () => void;
  isOpen: boolean;
}

const Drawer: FC<DrawerProps> = ({ onClose, isOpen }) => {
  useDisableBodyScroll(isOpen);
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
    opacity: 0,
  }));
  const open = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };
  const close = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
    if (isOpen) {
      onClose();
    }
  };
  const languages = useLanguages();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  const handleChangeLanguage = (code: string) => () => {
    i18next.changeLanguage(code);
    close();
  };

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            <Box
              sx={{ position: 'fixed', top: '24px', left: '20px' }}
              onClick={close}
            >
              <BackDrawerButton direction="down" />
            </Box>
            <Box
              sx={{
                overflow: 'auto',
                height: '100vh',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '100px 20px 100px 20px',
                }}
              >
                {languages.map(({ code, name, country_code }) => (
                  <Stack
                    sx={{
                      marginBottom: '20px',
                    }}
                    direction="row"
                    spacing={2}
                    key={code}
                    onClick={handleChangeLanguage(code)}
                  >
                    <Typography
                      sx={{
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: '400',
                      }}
                    >
                      {name}
                    </Typography>
                    <ReactCountryFlag
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                      countryCode={country_code}
                      svg
                    />
                  </Stack>
                ))}
              </Box>
            </Box>
          </animated.div>
          <animated.div
            style={{
              position: 'fixed',
              bottom: '-10px',
              left: '0px',
              right: '0px',
              touchAction: 'none',
              zIndex: '200',
              boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.25)',
              y,
            }}
          >
            <Box
              sx={{
                width: '100%',
                paddingTop: '24px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 34px)',
                zIndex: '1',
                backgroundColor: 'rgba(255, 255, 255, .15)',
                backdropFilter: 'blur(3px)',
              }}
            >
              <Button
                sx={{
                  textTransform: 'inherit',
                  width: '100%',
                  borderRadius: '6px',
                  fontSize: '16px',
                  lineHeight: '16px',
                  height: '100%',
                  padding: '14px 0',
                  color: 'secondary.main',
                  border: '2px solid',
                  '&:hover': {
                    border: '2px solid',
                  },
                  borderColor: 'primary.main',
                  whiteSpace: 'nowrap',
                  boxShadow: 'none',
                  backgroundColor: 'common.white',
                }}
                variant="outlined"
                fullWidth
                onClick={close}
              >
                {t('close')}
              </Button>
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;

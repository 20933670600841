import React, { FC } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/AddressModal/Modal';
import { FormProvider } from 'react-hook-form';
import { useAddressModal } from 'hooks/address';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: 'add' | 'update';
  addressId?: number;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, modalType, addressId }) => {
  const {
    isRuForm,
    methods,
    isCheckDelivery,
    addressView,
    onSubmit,
    handleBackToForm,
    handleDeleteAddress,
    handleClose,
  } = useAddressModal(modalType, onClose, addressId);

  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          padding: '32px',
          overflowY: 'inherit',
        },
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={handleClose}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Content
            onClose={handleClose}
            modalType={modalType}
            isRuForm={isRuForm}
            addressView={addressView}
            isCheckDelivery={isCheckDelivery}
            handleBackToForm={handleBackToForm}
            handleDeleteAddress={handleDeleteAddress}
          />
        </Box>
      </FormProvider>
    </Dialog>
  );
};

export default Modal;

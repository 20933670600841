import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlogPost } from 'types/Blog';

export const getBlogPosts = createAsyncThunk(
  'blog/getBlogPosts',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<BlogPost[]>(
        `${process.env.REACT_APP_V3}/blog/post/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get blog posts: ${e}`);
    }
  }
);

export const getBlogPost = createAsyncThunk(
  'blog/getBlogPost',
  async (queryArgs: { postId: string; lang: string }, thunkAPI) => {
    const { postId, lang } = queryArgs;
    try {
      const response = await axios.get<BlogPost>(
        `${process.env.REACT_APP_V3}/blog/post/${postId}`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get blog post: ${e}`);
    }
  }
);

import React, { FC } from 'react';
import {
  CheckDelivery,
  DrawerHeader,
} from 'components/modals/AddressModal/Drawer/Content';
import { AddressForm, AddressFormRU } from 'components/account/forms';
import { Box } from '@mui/material';

interface ContentProps {
  isCheckDelivery: boolean;
  addressView: string;
  modalType: 'add' | 'update';
  handleDeleteAddress: () => void;
  isRuForm: boolean;
}

const Content: FC<ContentProps> = ({
  isCheckDelivery,
  addressView,
  modalType,
  handleDeleteAddress,
  isRuForm,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 20px 100px 20px',
      }}
    >
      <DrawerHeader
        handleDeleteAddress={handleDeleteAddress}
        modalType={modalType}
      />
      {isCheckDelivery ? (
        <CheckDelivery addressView={addressView} />
      ) : (
        <>
          {isRuForm ? <AddressFormRU /> : <AddressForm modalType={modalType} />}
        </>
      )}
    </Box>
  );
};

export default Content;

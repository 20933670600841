import React, { FC, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Link,
} from '@mui/material';
import { Close as CloseIcon, CheckCircle } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useMediaQuery } from 'react-responsive';

const MobileAppBanner: FC = () => {
  const [cookie, setCookie] = useCookies(['app_banner']);
  const { IOS_APP_PAGE_LINK, ANDROID_APP_PAGE_LINK } = useAppSelector<{
    IOS_APP_PAGE_LINK: string;
    ANDROID_APP_PAGE_LINK: string;
  }>(selectAppSettingsData);
  const isBanner = useMediaQuery({ maxWidth: 767 });
  const IOSIcon = `${process.env.PUBLIC_URL}/apple-touch-icon.png`;

  const getLink = () => {
    const isAndroid = () => {
      return navigator.userAgent.match(/Android/i);
    };

    const isIOS = () => {
      return (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i)
      );
    };

    if (isAndroid()) {
      return ANDROID_APP_PAGE_LINK;
    }

    if (isIOS()) {
      return IOS_APP_PAGE_LINK;
    }
    return '';
  };

  const handleCloseButton = useCallback(() => {
    setCookie('app_banner', true, { maxAge: 604800000 });
  }, [setCookie]);

  const handleClickButton = useCallback(() => {
    setCookie('app_banner', true, { maxAge: 2592000000 });
  }, [setCookie]);

  const isShow = useMemo(() => {
    return !cookie.app_banner;
  }, [cookie.app_banner]);

  return (
    <>
      {isBanner && isShow && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100vw',
            zIndex: '1500',
            backgroundColor: 'secondary.main',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '10px', right: '20px' }}
            onClick={handleCloseButton}
            size="small"
            aria-label="close-btn"
          >
            <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
          </IconButton>
          <Container>
            <Box
              sx={{
                display: 'flex',
                padding: '30px 0 65px 0',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: '68px',
                  height: '68px',
                  background: '#ffffff',
                  borderRadius: '20px',
                  padding: '10px',
                  marginBottom: '20px',
                }}
              >
                {IOSIcon ? (
                  <Box
                    sx={{
                      objectFit: 'cover',
                      width: '100%',
                      minHeight: '100%',
                    }}
                    component="img"
                    src={IOSIcon}
                    alt="icon"
                  />
                ) : (
                  <CheckCircle
                    sx={{ color: 'primary.main', fontSize: '48px' }}
                  />
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: '17px',
                  lineHeight: '20px',
                  color: 'primary.contrastText',
                  fontWeight: '400',
                  marginBottom: '20px',
                }}
              >
                В приложении удобнее
              </Typography>
              <Button
                sx={{
                  minWidth: '188px',
                  height: '45px',
                  fontSize: '18px',
                  fontWeight: '300',
                  lineHeight: '21px',
                  borderRadius: '6px',
                  color: 'primary.contrastText',
                  boxShadow: 'none',
                  '&:focus': {
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
                component={Link}
                onClick={handleClickButton}
                href={getLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Открыть
              </Button>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default MobileAppBanner;

import { CalculatedBasketProduct } from './Basket';

/**
 * Possible payment types.
 * Note: not all of them are used by the frontend
 */
export enum PAYMENT_TYPES {
  PAYMENT_CASH = 0,
  PAYMENT_CARD = 1,
  PAYMENT_CARD_COURIER = 2,
  PAYMENT_BONUS = 3,
  PAYMENT_APPLE_PAY = 4,
  PAYMENT_GOOGLE_PAY = 5,
  PAYMENT_SAMSUNG_PAY = 6,
  PAYMENT_ON_ACCOUNT = 7,
  PAYMENT_ON_SBP = 9,
}

export enum ORDER_STATUS_TYPES {
  ORDER_STATUS_NEW = 0,
  ORDER_STATUS_CONFIRMED = 1,
  ORDER_STATUS_DELIVERY = 2,
  ORDER_STATUS_COMPLETED = 3,
  ORDER_STATUS_CANCELLED = 10,
}

export interface CreateOrderAddress {
  city_id: number | null;
  city_name?: string;
  street: string;
  house: string;
  area?: string | null;
  building?: number;
  corpus?: string;
  apartment?: string;
  entrance?: string;
  floor?: number;
  latitude?: number | null;
  longitude?: number | null;
  street_classifier_id?: string;
}

export interface CreateOrderClient {
  name: string | null;
  email?: string | null;
  phone: string | null;
  app_version: string;
}

export interface CreateOrderPayment {
  payment_type?: number | null;
  payment_binding_id?: string;
  payment_cryptogram?: string;
  payment_cardholder?: any;
  payment_token_android_pay?: string;
  payment_token_apple_pay?: string;
  points?: number;
}

export interface OrderPromotion {
  action_id?: number;
  item_option_id?: number;
}

export interface CreateOrder {
  brand_id?: number | null;
  restaurant_id?: number | null;
  delivery_zone_id?: number;
  delivery_type: number;
  platform_type: number;
  order_type: number;
  call_back: number;
  comment?: string;
  pickup_time?: string | null;
  change_from?: number;
  cutlery: number;
  codeword?: number;
  os_player_id?: string;
  fb_player_id?: string;
  address: CreateOrderAddress | null;
  client: CreateOrderClient | null;
  payment: CreateOrderPayment;
  basket: CalculatedBasketProduct[] | [];
  actions: OrderPromotion[];
}

export interface CreateOrderParams {
  api_key: string;
  body?: CreateOrder;
}

export interface ResponseCreateOrder {
  data: {
    job_id: number | null;
    order_id: number;
    // No payment data in case if a client doesn't pay online
    payment_data?: {
      order_id: number;
      errorMessage: string | null;
      errorCode: number;
      formUrl: string | null;
      success: boolean;
    };
  } | null;
  reason: string;
  success: boolean;
  message_type: number;
  message: string;
}

/**
 * Describes single payment type option for UI element
 */
export interface PaymentTypeOption {
  label: string;
  value: number;
}

export interface OrderItemOption {
  id: number;
  item_option: {
    id: number;
    name: string;
    description: string;
    price_kop: number;
    calorie: string;
    okod: string;
    external_group_id: null;
    order_by: number;
    parent_item: number;
    parent_name: string;
    parent_item_src: string;
  };
  group_modifiers: [];
  count: number;
  price_kop: number;
  item_name: string;
  is_present: boolean;
  order: number;
  cooking_option: null;
  actions: [];
}

/**
 * Describes an element of the order history
 */
export interface OrderHistoryItem {
  id: number;
  date: string;
  address: string;
  phone: string;
  username: string;
  comment: string;
  status: number;
  action: null;
  action_item: null;
  order_item_options: OrderItemOption[];
  order_ingridients: [];
  ext_number: string;
  payment_type: number;
  brand: null;
  delivery_type: number;
  restaurant: number;
  delivery_price: number;
  total_price: number;
}

export interface TimedOrderScheduleResponse {
  success: boolean;
  reason: string;
  data: {
    asap_bounds: [string, string];
    schedule: {
      [date: string]: [string, string];
    };
  } | null;
}

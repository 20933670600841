import { useTranslation } from 'react-i18next';
import { PAYMENT_TYPES } from 'types/Order';

const usePaymentButtonText = (payment_type?: number | null) => {
  const { t } = useTranslation();
  /**
   * Button text mapping.
   * ToDo: in future may be add this text to the settings like it is done for payment options
   */
  const PLACE_ORDER_BUTTON_TEXT = {
    PAYMENT_CASH: t('place_order'),
    PAYMENT_CARD: t('pay_with_redit_card'),
    PAYMENT_CARD_COURIER: t('place_order'),
    PAYMENT_BONUS: t('pay_with_bonuses'),
    PAYMENT_APPLE_PAY: t('pay_with_apple_pay'),
    PAYMENT_GOOGLE_PAY: t('pay_with_google_pay'),
    PAYMENT_SAMSUNG_PAY: t('pay_with_samsung_pay'),
    PAYMENT_ON_ACCOUNT: t('place_order'),
    DEFAULT: t('place_order'),
  };

  switch (payment_type) {
    case PAYMENT_TYPES.PAYMENT_CASH:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_CASH;
    case PAYMENT_TYPES.PAYMENT_CARD:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_CARD;
    case PAYMENT_TYPES.PAYMENT_CARD_COURIER:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_CARD_COURIER;
    case PAYMENT_TYPES.PAYMENT_BONUS:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_BONUS;
    case PAYMENT_TYPES.PAYMENT_APPLE_PAY:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_APPLE_PAY;
    case PAYMENT_TYPES.PAYMENT_GOOGLE_PAY:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_GOOGLE_PAY;
    case PAYMENT_TYPES.PAYMENT_SAMSUNG_PAY:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_SAMSUNG_PAY;
    case PAYMENT_TYPES.PAYMENT_ON_ACCOUNT:
      return PLACE_ORDER_BUTTON_TEXT.PAYMENT_ON_ACCOUNT;
    default:
      return PLACE_ORDER_BUTTON_TEXT.DEFAULT;
  }
};

export default usePaymentButtonText;

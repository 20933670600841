import { useTranslation } from 'react-i18next';

const useReadyTime = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('as_soon_as_possible'),
      value: 1,
    },
    {
      label: t('on_time'),
      value: 2,
    },
  ];
};

export default useReadyTime;

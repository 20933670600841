import React, { FC, useCallback, useState } from 'react';
import { Modal, Drawer, Step } from 'components/modals/AuthModal';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from 'hooks/auth';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import { AppSettingsData } from 'types/AppSettings';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal: FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const dispatch = useAppDispatch();
  const { getAuthCode } = useAuth();
  const methods = useForm();
  const { watch, reset } = methods;
  const loginValue = watch('login');
  const [step, setStep] = useState<Step>(Step.LoginInput);
  const { clearAuthResponse } = accountSlice.actions;
  const { AUTH_SERVICE_TYPE, AUTH_USE_CAPTCHA } =
    useAppSelector<AppSettingsData>(selectAppSettingsData);

  const captchafullNextStep = () => {
    setStep((current) => {
      switch (current) {
        case Step.LoginInput:
          return Step.CaptchaInput;
        case Step.CaptchaInput:
          return Step.CodeInput;
        case Step.CodeInput:
          return Step.CodeInput;
        default:
          throw new Error(`Из состояния ${current} нельзя пойти дальше`);
      }
    });
  };

  const captchalessNextStep = () => {
    setStep((current) => {
      switch (current) {
        case Step.LoginInput:
          return Step.CaptchaInput;
        case Step.CaptchaInput:
          return Step.CodeInput;
        case Step.CodeInput:
          return Step.CodeInput;
        default:
          throw new Error(`Из состояния ${current} нельзя пойти дальше`);
      }
    });
  };

  const captchalessPrevStep = () => {
    setStep((current) => {
      switch (current) {
        case Step.CaptchaInput:
        case Step.CodeInput:
          return Step.LoginInput;
        default:
          throw new Error(`Из состояния ${current} нельзя пойти назад`);
      }
    });
  };

  const captchafullPrevStep = () => {
    setStep((current) => {
      switch (current) {
        case Step.CodeInput:
          return Step.LoginInput;
        default:
          throw new Error(`Из состояния ${current} нельзя пойти назад`);
      }
    });
  };

  const nextStep = AUTH_USE_CAPTCHA ? captchafullNextStep : captchalessNextStep;
  const prevStep = () => {
    dispatch(clearAuthResponse());
    reset();
    if (AUTH_USE_CAPTCHA) {
      captchafullPrevStep();
    } else {
      captchalessPrevStep();
    }
  };

  const onCaptchafullSubmit = () => {
    if (step === Step.LoginInput) {
      nextStep();
    }
  };

  const onCaptchalessSubmit = () => {
    if (step === Step.LoginInput) {
      if (AUTH_SERVICE_TYPE === 1 || AUTH_SERVICE_TYPE === 3) {
        getAuthCode(loginValue.replace(/\D/g, ''), AUTH_SERVICE_TYPE, true);
      }
      if (AUTH_SERVICE_TYPE === 0) {
        getAuthCode(loginValue, AUTH_SERVICE_TYPE);
      }
      if (AUTH_SERVICE_TYPE === 2) {
        const clearNumber = loginValue.replace(/\D/g, '');
        getAuthCode(clearNumber, AUTH_SERVICE_TYPE);
      }
    }
  };

  const onSubmit = AUTH_USE_CAPTCHA ? onCaptchafullSubmit : onCaptchalessSubmit;

  const handleClose = useCallback(() => {
    onClose();
    reset();
    setTimeout(() => {
      setStep(Step.LoginInput);
    }, 200);
  }, []);

  return (
    <>
      {isDrawer ? (
        <Drawer
          step={step}
          methods={methods}
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={onSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ) : (
        <Modal
          step={step}
          methods={methods}
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={onSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
    </>
  );
};

export default AuthModal;

import React, { FC } from 'react';
import { Box } from '@mui/material';
import { CookingOption, Option, GroupModifier, Ingredient } from 'types/Menu';
import { Regular, Combo } from 'components/modals/ProductModal/Drawer/Content';

interface ContentProps {
  isCombo: boolean;
  calculatedDescription: string;
  name: string;
  options: Option[];
  groupModifiers: GroupModifier[];
  ingredients: Ingredient[];
  cookingOptions: CookingOption[];
  image: string;
  additionalImages: any[];
  description: string;
  extraDescription: string;
  optionDesc?: string;
}

const Content: FC<ContentProps> = ({
  isCombo,
  calculatedDescription,
  name,
  options,
  groupModifiers,
  ingredients,
  cookingOptions,
  image,
  additionalImages,
  description,
  extraDescription,
  optionDesc,
}) => {
  return (
    <Box>
      {isCombo ? (
        <Combo
          name={name}
          groupModifiers={groupModifiers}
          image={image}
          extraDescription={extraDescription}
          calculatedDescription={calculatedDescription}
        />
      ) : (
        <Regular
          name={name}
          options={options}
          groupModifiers={groupModifiers}
          ingredients={ingredients}
          cookingOptions={cookingOptions}
          image={image}
          additionalImages={additionalImages}
          description={description}
          extraDescription={extraDescription}
          optionDesc={optionDesc}
        />
      )}
    </Box>
  );
};

export default Content;

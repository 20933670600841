import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectPriceForFreeDelivery,
  selectResultTotalPrice,
  selectIsDelivery,
  selectTotalPrice,
  selectDeliveryPrice,
  selectBasketLength,
  selectBenefitPrice,
  selectCalculatedBasket,
} from 'store/selectors/orderSelectors';
import { useTitleEnding } from 'hooks/basket';
import { useTranslation } from 'react-i18next';

const Total: FC = () => {
  const { t } = useTranslation();
  const { benefitPriceKop, benefitPrice } = useAppSelector(selectBenefitPrice);
  const { deliveryPriceKop, deliveryPrice } =
    useAppSelector(selectDeliveryPrice);
  const { freeDeliveryPriceKop, forFreeDeliveryPrice } = useAppSelector(
    selectPriceForFreeDelivery
  );
  const { resultTotalPrice } = useAppSelector(selectResultTotalPrice);
  const { totalPrice } = useAppSelector(selectTotalPrice);
  const { success } = useAppSelector(selectCalculatedBasket);
  const basketLength = useAppSelector(selectBasketLength);
  const isDelivery = useAppSelector(selectIsDelivery);
  const ending = useTitleEnding(basketLength);

  return (
    <Box>
      <Box sx={{ marginBottom: { xs: '10px', sm: '20px' } }}>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '23px ' },
            lineHeight: { xs: '19px', sm: '27px' },
            fontWeight: '600',
          }}
        >
          {t('total')} {resultTotalPrice}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: { xs: '0px', sm: '0 25px' },
          marginBottom: { xs: '40px', sm: '43px' },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '23px ' },
            lineHeight: { xs: '16px', sm: '27px' },
            marginBottom: { xs: '11px', sm: '20px' },
            fontWeight: '300',
            color: 'text.primary',
          }}
        >
          {basketLength} {ending} {resultTotalPrice}
        </Typography>
        {benefitPriceKop > 0 && (
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '23px ' },
              lineHeight: { xs: '16px', sm: '27px' },
              marginBottom: { xs: '11px', sm: '20px' },
              fontWeight: '300',
              color: 'text.primary',
            }}
          >
            {t('promo_benefits')} {benefitPrice}
          </Typography>
        )}

        {success && isDelivery && (
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '23px ' },
              lineHeight: { xs: '16px', sm: '27px' },
              marginBottom: { xs: '6px', sm: '20px' },
              fontWeight: '300',
              color: 'text.primary',
            }}
          >
            {t('shipping_cost')} {deliveryPrice}
          </Typography>
        )}

        {freeDeliveryPriceKop > 0 && deliveryPriceKop > 0 && isDelivery && (
          <Typography
            sx={{
              fontSize: { xs: '12px', sm: '20px ' },
              lineHeight: { xs: '14px', sm: '24px' },
              color: 'error.main',
              marginBottom: '30px',
              fontWeight: '600',
            }}
          >
            {`(${t('add_something_on', { forFreeDeliveryPrice })})`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Total;

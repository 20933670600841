import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useDirection = () => {
  const [direction, setDirection] = useState<string>('ltr');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.dir() === 'ltr') {
      setDirection('ltr');
    } else {
      setDirection('rtl');
    }
  }, [i18n]);

  return direction;
};

export default useDirection;

import React, {
  useContext,
  ReactNode,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import {
  getAccount,
  getCode,
} from 'store/reducers/AccountSlice/ActionCreators';
import { getAddresses } from 'store/reducers/AddressSlice/ActionCreators';
import { selectApiKey } from 'store/selectors/accountSelectors';

interface AuthContextType {
  isAuth: boolean;
  getAuthCode: (
    login: string,
    method: number,
    mix?: boolean,
    captcha_id?: string,
    captcha_x?: number,
    captcha_y?: number
  ) => void;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const { removeAccountData, removeApiKey, clearVerifyResponse } =
    accountSlice.actions;
  const api_key = useAppSelector(selectApiKey);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const getAuthCode = (
    login: string,
    method: number,
    mix?: boolean,
    captcha_id?: string,
    captcha_x?: number,
    captcha_y?: number
  ) => {
    dispatch(
      getCode({
        login,
        service_type: method,
        mix,
        captcha_id,
        captcha_x,
        captcha_y,
      })
    );
  };

  const logOut = () => {
    setIsAuth(false);
    dispatch(removeAccountData());
    dispatch(removeApiKey());
    dispatch(clearVerifyResponse());
    navigate('/');
  };

  useEffect(() => {
    if (api_key) {
      setIsAuth(true);
      dispatch(getAccount(api_key));
      dispatch(getAddresses(api_key));
    }
  }, [api_key]);

  const value = { isAuth, getAuthCode, logOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  return useContext(AuthContext);
}

import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/CloudPaymentModal';

interface CloudPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  hanldeGenCryptoKey: () => void;
}

const CloudPaymentModal: FC<CloudPaymentModalProps> = ({
  isOpen,
  onClose,
  hanldeGenCryptoKey,
}) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          hanldeGenCryptoKey={hanldeGenCryptoKey}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          hanldeGenCryptoKey={hanldeGenCryptoKey}
        />
      )}
    </>
  );
};

export default CloudPaymentModal;

import React, { FC } from 'react';
import { Rating as MUIRating } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Star as StarIcon } from '@mui/icons-material';

interface RatingProps {
  control: Control<FieldValues>;
  name: string;
  defaultValue?: number;
  required?: boolean;
  requiredText?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

const Rating: FC<RatingProps> = ({
  control,
  name,
  defaultValue,
  required,
  requiredText = '',
  size,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <MUIRating
          value={Number(value) || 3}
          onChange={onChange}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          size={size}
          disabled={disabled}
        />
      )}
      rules={{
        required: required || requiredText,
      }}
    />
  );
};

export default Rating;

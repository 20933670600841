import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface LabelProps {
  backgroundColor: string;
  text: string;
  textColor: string;
}

const Label: FC<LabelProps> = ({ backgroundColor, text, textColor }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        padding: '5px 14px',
        borderRadius: '5px',
        boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.14)',
        backgroundColor,
        marginBottom: '5px',
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '10px', md: '14px' },
          fontWeight: '600',
          lineHeight: '16px',
          color: textColor,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Label;

import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Card as MUICard,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  Box,
  Button,
} from '@mui/material';
import { PromotionModal } from 'components/modals';
import { useTranslation } from 'react-i18next';

interface PromotionCardProps {
  title: string;
  mobileImage: string;
  desktopImage: string;
  description: string;
  date: string;
}

const Card: FC<PromotionCardProps> = ({
  title,
  mobileImage,
  desktopImage,
  description,
  date,
}) => {
  const [trimmedDescription, setTrimmedDescription] = useState<string>('');
  const [isButton, setIsButton] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (description.length > 160) {
      setTrimmedDescription(`${description.slice(0, 122)}…`);
      setIsButton(true);
    } else {
      setTrimmedDescription(description);
      setIsButton(false);
    }
  }, [description]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  return (
    <>
      <MUICard
        sx={{
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          textDecoration: 'none',
        }}
        onClick={handleOpenModal}
      >
        <Paper
          sx={{
            overflow: 'hidden',
            borderRadius: 0,
            flexShrink: 0,
          }}
          variant="outlined"
        >
          <CardMedia
            sx={{
              objectFit: 'cover',
              height: 'auto',
              width: '100%',
            }}
            component="img"
            alt="promotions image"
            src={mobileImage}
          />
        </Paper>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: { xs: '20px 15px', sm: '20px' },
            height: '100%',
            minHeight: '185px',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '24px', sm: '26px' },
              lineHeight: { xs: '24px', sm: '26px' },
              marginBottom: '15px',
            }}
            variant="h5"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontSize: { xs: '15px', sm: '18px' },
              lineHeight: { xs: '23px', sm: '23px' },
            }}
            variant="body1"
            color="text.secondary"
          >
            {trimmedDescription}
          </Typography>
          {isButton && (
            <Box
              sx={{
                maxWidth: '150px',
                marginTop: '20px',
                alignSelf: 'flex-end',
              }}
            >
              <Button variant="outlined">{t('read_more')}</Button>
            </Box>
          )}
        </CardContent>
      </MUICard>
      <PromotionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        src={desktopImage}
        title={title}
        description={description}
        date={date}
      />
    </>
  );
};

export default Card;

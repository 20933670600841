import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Content } from 'components/modals/ProductModal/Drawer';
import { BackDrawerButton, Portal } from 'components/generic';
import { useDrawer } from 'hooks';
import { FormProvider } from 'react-hook-form';
import { useProductModal } from 'hooks/menu';
import { animated } from 'react-spring';
import { useTranslation } from 'react-i18next';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
  categoryId: number;
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  productId,
  categoryId,
}) => {
  const { isMount, y, closeDrawer } = useDrawer(isOpen, onClose);
  const {
    name,
    options,
    group_modifiers,
    ingredients,
    cooking_options,
    image,
    additional_images,
    extra_description,
    methods,
    onSubmit,
    isCombo,
    description,
    calculatedDescription,
    totalPrice,
    optionDesc,
  } = useProductModal(onClose, productId, categoryId);
  const { t } = useTranslation();

  return (
    <Portal>
      {isMount && (
        <Box component={FormProvider} {...methods}>
          <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <animated.div
              style={{
                position: 'fixed',
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                height: '150vh',
                background: '#ffffff',
                y,
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: '24px',
                  left: '20px',
                  zIndex: '100',
                }}
                onClick={closeDrawer}
              >
                <BackDrawerButton direction="down" />
              </Box>
              <Box
                sx={{
                  height: '100vh',
                  width: '100%',
                  overflow: 'auto',
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 100px)',
                }}
              >
                <Content
                  isCombo={isCombo}
                  name={name}
                  options={options}
                  groupModifiers={group_modifiers}
                  ingredients={ingredients}
                  cookingOptions={cooking_options}
                  image={image}
                  additionalImages={additional_images}
                  description={description}
                  extraDescription={extra_description}
                  calculatedDescription={calculatedDescription}
                  optionDesc={optionDesc}
                />
              </Box>
            </animated.div>
            <animated.div
              style={{
                position: 'fixed',
                bottom: '-10px',
                left: '0px',
                right: '0px',
                touchAction: 'none',
                zIndex: '200',
                boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.25)',
                y,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '9px',
                  paddingLeft: '21px',
                  paddingRight: '21px',
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 33px)',
                  display: 'flex',
                  flexDirection: 'column',
                  zIndex: '1',
                  backgroundColor: 'rgba(255, 255, 255, .15)',
                  backdropFilter: 'blur(3px)',
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    marginRight: '3px',
                    fontWeight: '300',
                    fontSize: '24px',
                    textAlign: 'right',
                  }}
                >
                  {totalPrice}
                </Typography>

                <Button
                  sx={{
                    textTransform: 'inherit',
                    width: '100%',
                    borderRadius: '6px',
                    fontSize: '16px',
                    lineHeight: '16px',
                    height: '100%',
                    padding: '15px 0',
                    whiteSpace: 'nowrap',
                    border: '2px solid',
                    color: 'primary.main',
                    '&:hover': {
                      border: '2px solid',
                    },
                    borderColor: 'primary.main',
                    boxShadow: 'none',
                    background: '#ffffff',
                  }}
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  {t('add_to_cart')}
                </Button>
              </Box>
            </animated.div>
          </Box>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;

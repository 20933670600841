import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/menu/Card';
import { useTranslation } from 'react-i18next';
import { usePrice } from 'hooks';

interface FooterProps {
  count: number;
  isCount: boolean;
  priceCop: number;
  isCountModeButton: boolean;
  handleAddProductToBasket: () => void;
  handleAddProductCount: () => void;
  handleRemoveProductCount: () => void;
}

const Footer: FC<FooterProps> = ({
  count,
  isCount,
  priceCop,
  isCountModeButton,
  handleAddProductToBasket,
  handleAddProductCount,
  handleRemoveProductCount,
}) => {
  const currentPrice = usePrice(priceCop);
  const { t } = useTranslation();

  return (
    <>
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '20px' },
          lineHeight: { xs: '18px', sm: '23px' },
          fontWeight: '400',
          marginBottom: '7px',
          color: 'text.primary',
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        {currentPrice}
      </Typography>
      <Box
        sx={{
          width: { xs: '117px', sm: '140px' },
          height: { xs: '45px', sm: '50px' },
          textDecoration: 'none',
          margin: { xs: '0 auto', sm: '0' },
        }}
      >
        <Button
          isCount={isCount}
          count={count}
          handleAddProductCount={handleAddProductCount}
          handleRemoveProductCount={handleRemoveProductCount}
          isCountModeButton={isCountModeButton}
          handleAddProductToBasket={handleAddProductToBasket}
        >
          {t('add_to_cart')}
        </Button>
      </Box>
    </>
  );
};

export default Footer;

import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/PromoProductsModal';
import { PromoProduct } from 'types/Promotion';

interface PromoProductsModalProps {
  isOpen: boolean;
  onClose: () => void;
  promoProducts: PromoProduct[];
  actionId: number;
}

const PromoProductsModal: FC<PromoProductsModalProps> = ({
  isOpen,
  onClose,
  promoProducts,
  actionId,
}) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          promoProducts={promoProducts}
          actionId={actionId}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          promoProducts={promoProducts}
          actionId={actionId}
        />
      )}
    </>
  );
};

export default PromoProductsModal;

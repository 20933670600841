import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { useTranslation } from 'react-i18next';

interface RTLProps {
  children: ReactNode;
}

const Rtl: FC<RTLProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const [dir, setDir] = useState<string>(
    i18n.language === 'ar-AE' ? 'rtl' : 'ltr'
  );

  useEffect(() => {
    if (i18n.language === 'ar-AE') {
      document.body.setAttribute('dir', 'rtl');
      setDir('rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
      setDir('ltr');
    }
  }, [i18n.language]);

  const cacheRtl = useMemo(() => {
    if (dir === 'rtl') {
      return createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
      });
    }
    return createCache({ key: 'css' });
  }, [dir]);

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

export default Rtl;

import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { selectLogo } from 'store/selectors/brandsSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const HeaderLogo: FC = () => {
  const logo = useAppSelector(selectLogo);

  return (
    <Link
      sx={{
        position: { xs: 'absolute', lg: 'relative' },
        left: 0,
        right: 0,
        margin: 'auto',
        width: { xs: '150px', lg: 'auto' },
        height: { xs: '70px', lg: 'auto' },
        WebkitTapHighlightColor: 'transparent',
        textDecoration: 'none',
        padding: { xs: '10px 0px', lg: '0 15px 0 0' },
        textAlign: 'center',
      }}
      to="/"
      component={NavLink}
    >
      <Box
        sx={{
          width: { xs: 'auto', lg: '100%' },
          maxWidth: '150px',
          height: '100%',
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        component="img"
        src={logo}
      />
    </Link>
  );
};

export default HeaderLogo;

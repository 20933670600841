import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { CssBaseline, Box, Fab } from '@mui/material';
import {
  Footer,
  Header,
  CookiesBanner,
  ScrollToTopButton,
  MobileAppBanner,
} from 'components/generic';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectCitiesData, selectCityId } from 'store/selectors/citySelectors';
import { City } from 'types/City';
import { Brand } from 'types/Brands';
import { SelectCityModal, BrandsModal, NameModal } from 'components/modals';
import { selectBrandId, selectBrands } from 'store/selectors/brandsSelectors';
import { citySlice } from 'store/reducers/CitySlice/CitySlice';
import { useLanguages } from 'hooks';
import { selectUserId, selectUserName } from 'store/selectors/accountSelectors';
import { brandSlice } from 'store/reducers/BrandSlice/BrandSlice';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';

const AppLayout: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const brands = useAppSelector<Brand[]>(selectBrands);
  const brandId = useAppSelector(selectBrandId);
  const cities = useAppSelector<City[]>(selectCitiesData);
  const cityId = useAppSelector<number | null>(selectCityId);
  const userName = useAppSelector<string | null>(selectUserName);
  const userId = useAppSelector(selectUserId);
  const { selectCity } = citySlice.actions;
  const [cityModal, setCityModal] = useState<boolean>(false);
  const [brandModal, setBrandModal] = useState<boolean>(false);
  const [nameModal, setNameModal] = useState<boolean>(false);
  const languages = useLanguages();
  const [params] = useSearchParams();
  const { selectBrand } = brandSlice.actions;
  const { clearOrder } = orderSlice.actions;
  const navigate = useNavigate();

  const isTopBar = useMemo(() => {
    return cities.length < 2 && languages.length <= 1;
  }, [cities, languages]);

  const handleBrandModalOpen = useCallback(() => {
    setBrandModal(true);
  }, [brandModal]);

  const handleBrandModalClose = useCallback(() => {
    setBrandModal(false);
  }, [brandModal]);

  const handleNameModalOpen = useCallback(() => {
    setNameModal(true);
  }, [nameModal]);

  const handleNameModalClose = useCallback(() => {
    setNameModal(false);
    sessionStorage.setItem('nameModalIsOpened', 'true');
  }, [nameModal]);

  useEffect(() => {
    if (!cityId || cities.length <= 1) {
      dispatch(selectCity(cities[0]?.id));
    }
    if (cityId || cities.length <= 1) {
      setCityModal(false);
    } else {
      setCityModal(true);
    }
  }, [cityId, cities, brandId, dispatch]);

  useEffect(() => {
    const rawBrandIdParam = params.get('brand');
    if (rawBrandIdParam) {
      const brandIdParam = Number.parseInt(rawBrandIdParam, 10);
      if (
        Number.isNaN(brandIdParam) ||
        brands.some((brand) => brand.is_active && brand.id === brandIdParam)
      ) {
        dispatch(selectBrand(brandIdParam));
        sessionStorage.setItem('brandModalIsOpened', 'true');
        navigate(`/?brand=${brandIdParam}`);
        dispatch(clearOrder());
        handleBrandModalClose();
        return;
      }
    }

    if (
      sessionStorage.getItem('brandModalIsOpened') === 'true' ||
      brands.length <= 1 ||
      location.pathname.includes('menu')
    ) {
      handleBrandModalClose();
      return;
    }

    handleBrandModalOpen();
  }, [brands, dispatch, location.pathname]);

  useEffect(() => {
    if (
      userId &&
      !userName &&
      sessionStorage.getItem('nameModalIsOpened') !== 'true'
    ) {
      handleNameModalOpen();
    }
  }, [userId, userName]);

  const isHeader = useMemo(() => {
    switch (location.pathname) {
      case '/cart':
        return false;
      case '/payment':
        return false;
      default:
        return true;
    }
  }, [location.pathname]);

  const isFooter = useMemo(() => {
    switch (location.pathname) {
      case '/cart':
        return false;
      case '/payment':
        return false;
      default:
        return true;
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Box id="back-to-top-anchor" />
        {isHeader && <Header />}
        <Box
          sx={{
            paddingTop:
              isFooter || isHeader
                ? {
                    xs: isTopBar ? '60px' : '100px',
                    md: isTopBar ? '80px' : '110px',
                  }
                : '0px',
            flexGrow: '1',
            lineHeight: '1',
          }}
        >
          <Outlet />
          <ScrollToTopButton>
            <Fab color="primary" size="large" aria-label="scroll back to top">
              <KeyboardArrowUpIcon sx={{ color: 'primary.contrastText' }} />
            </Fab>
          </ScrollToTopButton>
        </Box>
        {isFooter && <Footer />}
      </Box>
      <CookiesBanner />
      <MobileAppBanner />
      <SelectCityModal isOpen={cityModal} onClose={() => null} />
      <BrandsModal
        isOpen={brandModal}
        onClose={handleBrandModalClose}
        isOpener
      />
      <NameModal isOpen={nameModal} onClose={handleNameModalClose} />
    </>
  );
};

export default AppLayout;

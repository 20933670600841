import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { WebOffers, MobileOffers } from 'components/home/Offers';
import { Box } from '@mui/material';

const Offers: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={{ margin: { xs: '50px 0', sm: '60px 0', md: '70px 0' } }}>
      {isMobile ? <MobileOffers /> : <WebOffers />}
    </Box>
  );
};

export default Offers;

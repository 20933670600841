import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { Locality, Language } from 'components/generic/Header';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { City } from 'types/City';
import { selectCitiesData } from 'store/selectors/citySelectors';
import { useLanguages } from 'hooks';

const HeaderTopBar: FC = () => {
  const cities = useAppSelector<City[]>(selectCitiesData);
  const languages = useLanguages();

  if (cities.length < 2 || languages.length < 1) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'primary.main',
          zIndex: '-1',
          opacity: '0.2',
        }}
      />
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '30px',
          }}
        >
          {cities.length > 1 && <Locality />}
          {languages.length > 1 && <Language />}
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderTopBar;

import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SearchFieldProps {
  handleChange: (arg: string) => void;
  size?: 'small' | 'medium';
}

const SearchField: FC<SearchFieldProps> = ({ size, handleChange }) => {
  const { t } = useTranslation();

  return (
    <TextField
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: size === 'small' ? '11px 14px' : '13.5px 14px',
        },
        '& .MuiInputLabel-root': {
          color: '#BDBDBD',
          lineHeight: size === 'small' ? '28px' : '20px',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'secondary.main',
        },
        '& .MuiInputLabel-shrink': {
          color: 'secondary.main',
          backgroundColor: 'primary.main',
          padding: '0px 7px',
          left: '-5px',
          fontWeight: '600',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '6px',
          '& fieldset': {
            border: '2px solid',
            borderColor: 'primary.main',
          },
          '&:hover fieldset': {
            borderColor: 'secondary.main',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'secondary.main',
          },
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          top: '45px',
          left: '0',
        },
      }}
      name="search"
      label={t('search')}
      size={size}
      fullWidth
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default SearchField;

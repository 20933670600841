import React, { FC, memo, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Category, Nav } from 'components/menu';
import { useMenu } from 'hooks/menu';
import { Helmet } from 'react-helmet';
import { MenuProduct } from 'types/Menu';
import { Search } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { menuSlice } from 'store/reducers/MenuSlice/MenuSlice';
import { useDebounce } from 'hooks';
import { selectFilterQueryInput } from 'store/selectors/menuSelectors';

const Menu: FC = () => {
  const {
    t,
    opacity,
    activeCategoryId,
    menuWithOffers,
    categoryRefs,
    rootMargin,
    handleScrollTo,
    handleSetActiveCategory,
    isLoading,
  } = useMenu();

  const dispatch = useAppDispatch();
  const { setFilterQuery, setFilterQueryInput } = menuSlice.actions;
  const filterQueryInput = useAppSelector(selectFilterQueryInput);
  const filterQuery = useDebounce(filterQueryInput, 500);

  useEffect(
    function updateSearchQuery() {
      dispatch(setFilterQuery(filterQuery));
    },
    [filterQuery, dispatch]
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_MENU_PAGE_TITLE ||
            `${process.env.REACT_APP_NAME} | ${t('menu')}`}
        </title>
        {process.env.REACT_APP_MENU_PAGE_DESCRIPTION && (
          <meta
            name="description"
            content={process.env.REACT_APP_MENU_PAGE_DESCRIPTION}
          />
        )}
      </Helmet>
      <Box sx={{ minHeight: '90vh' }}>
        {isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Nav
              activeCategoryId={activeCategoryId}
              handleScrollTo={handleScrollTo}
            />
            <Box sx={{ padding: { xs: '60px 0', sm: '80px 0' } }}>
              <Container>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <TextField
                    sx={{
                      display: { lg: 'none' },
                      margin: { xs: '8px 0 16px', sm: '-12px 0 16px' },
                      width: '100%',
                      maxWidth: '450px',
                    }}
                    size="small"
                    placeholder="Поиск"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    value={filterQueryInput}
                    onChange={(event) =>
                      dispatch(setFilterQueryInput(event.target.value))
                    }
                  />
                </Box>
                <Box sx={{ opacity: `${opacity}`, transition: 'opacity 0.3s' }}>
                  {menuWithOffers.map(
                    (category: {
                      id: string;
                      items: MenuProduct[];
                      name: string;
                    }) => (
                      <Category
                        key={category.id}
                        items={category.items}
                        categoryId={category.id}
                        categoryName={category.name}
                        activeCategoryId={activeCategoryId}
                        handleSetActiveCategory={handleSetActiveCategory}
                        categoryRefs={categoryRefs}
                        rootMargin={rootMargin}
                      />
                    )
                  )}
                </Box>
              </Container>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default memo(Menu);

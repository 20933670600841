import React, { FC } from 'react';
import {
  AutocompleteKladrField,
  SelectField,
  TextField,
} from 'components/generic';
import { useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectCitiesList } from 'store/selectors/citySelectors';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAutocomplete } from 'hooks/address';

const AddressFormRU: FC = () => {
  const { control, watch } = useFormContext();
  const streetValue = watch('street');
  const { t } = useTranslation();
  const citiesList = useAppSelector(selectCitiesList);
  const { IS_APARTMENT_REQUIRED } = useAppSelector(selectAppSettingsData);
  const options = useAutocomplete(streetValue);

  return (
    <Box>
      <Box sx={{ marginBottom: '20px' }}>
        <TextField
          control={control}
          name="name"
          label="Короткое название адреса (Дом или Офис)"
          size="small"
        />
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <SelectField
          control={control}
          name="city"
          selectItem={citiesList}
          label="Город"
          size="small"
          required
        />
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <AutocompleteKladrField
          control={control}
          name="street"
          label={t('street')}
          size="small"
          options={options}
          required
        />
      </Box>
      <Stack
        sx={{ marginBottom: '20px' }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
      >
        <Stack
          sx={{ width: { xs: '100%', sm: '55%' } }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
        >
          <TextField
            control={control}
            name="house"
            label="Дом и корпус"
            size="small"
            required
          />
          <TextField
            control={control}
            name="apartment"
            label="Кв./Офис"
            size="small"
            required={IS_APARTMENT_REQUIRED}
            type="number"
          />
        </Stack>
        <Stack
          sx={{ width: { xs: '100%', sm: '45%' } }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
        >
          <TextField
            control={control}
            name="entrance"
            label="Подъезд"
            size="small"
            type="number"
          />
          <TextField
            control={control}
            name="floor"
            label="Этаж"
            size="small"
            type="number"
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddressFormRU;

import React, { FC, useEffect, useState } from 'react';
import { Modifier } from 'types/Menu';
import { OptionCheckbox } from 'components/generic';
import { Box } from '@mui/material';

interface ComboMultipleSelectorProps {
  modifiers: Modifier[];
  id: number;
  minCount: number;
  maxCount: number;
}

const ComboMultipleSelector: FC<ComboMultipleSelectorProps> = ({
  modifiers,
  id,
  minCount,
  maxCount,
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelect = (value: number, maxCount: number, minCount: number) => {
    setSelectedItems((prevSelectedItems) => {
      const isPresent = prevSelectedItems.includes(value);
      const count = prevSelectedItems.length;

      if (minCount === maxCount) {
        if (isPresent) {
          return prevSelectedItems;
        }

        const [, ...rest] = prevSelectedItems;
        return [...rest, value];
      }

      if (count === maxCount) {
        if (isPresent) {
          return prevSelectedItems.filter((item) => item !== value);
        }

        const [, ...rest] = prevSelectedItems;
        return [...rest, value];
      }

      if (count > minCount && count < maxCount) {
        if (isPresent) {
          return prevSelectedItems.filter((item) => item !== value);
        }

        return [...prevSelectedItems, value];
      }

      if (count === minCount) {
        if (isPresent) {
          return prevSelectedItems;
        }

        return [...prevSelectedItems, value];
      }

      throw new Error('count must be between minCount and maxCount');
    });
  };

  useEffect(() => {
    setSelectedItems(
      modifiers.slice(0, minCount).map((modifier) => modifier.id)
    );
  }, []);

  return (
    <Box
      sx={{
        marginLeft: { xs: '0xp', md: '30px' },
        marginTop: '8px',
      }}
    >
      {modifiers.map((modifier) => (
        <OptionCheckbox
          selectedItems={selectedItems}
          handleSelect={handleSelect}
          key={modifier.id}
          label={modifier.name}
          groupId={id}
          value={modifier.id}
          price={modifier.price_kop}
          minCount={minCount}
          maxCount={maxCount}
        />
      ))}
    </Box>
  );
};

export default ComboMultipleSelector;

import React, { FC, memo } from 'react';
import { Document, Page } from 'react-pdf';
import { Box, Typography, Skeleton } from '@mui/material';

interface CardProps {
  url: string;
  title: string;
}

const Card: FC<CardProps> = ({ url, title }) => {
  const handleDirect = () => {
    window.open(url, '_blank noreferrer noopener');
  };

  return (
    <Box
      sx={{
        width: { xs: '50%', md: '25%' },
        padding: { xs: '0 10px', md: '0 20px' },
        marginBottom: { xs: '10px', md: '20px' },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '20px', md: '24px' },
          lineHeight: '30px',
          fontWeight: '400',
          textAlign: 'center',
          fontFamily: 'Montserrat, sans-serif',
          marginBottom: { xs: '10px', md: '20px' },
          cursor: 'pointer',
        }}
        onClick={handleDirect}
      >
        {title}
      </Typography>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleDirect}
      >
        <Box
          file={url}
          component={Document}
          loading={<Skeleton variant="rectangular" sx={{ height: '400px' }} />}
          error=""
        >
          <Box
            component={Page}
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            sx={{
              objectFit: 'cover',
              '& .react-pdf__Page__canvas': {
                width: '100% !important',
                height: 'auto !important',
                textAlign: 'center',
              },
              transition: '0.5s',
              '&:hover': {
                transform: { xs: 'none', md: 'scale(1.08)' },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Card);

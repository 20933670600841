import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0Z" />
    </SvgIcon>
  );
};

export default NoneIcon;

import { selectServerTime } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAsapInterval } from 'store/selectors/orderSelectors';
import type { Result } from 'types/generic';
import { Ok, Err } from 'types/generic';
import moment from 'moment-timezone';

const useWorkTime = (): Result<
  true,
  | { reason: 'TOO_SOON'; opening_time: moment.Moment }
  | { reason: 'TOO_LATE' }
  | { reason: 'NO_ASAP_INTERVAL' }
> => {
  const serverTime = moment.utc(useAppSelector<string>(selectServerTime));
  const asapInterval = useAppSelector(selectAsapInterval);

  if (!asapInterval) {
    return Err({ reason: 'NO_ASAP_INTERVAL' });
  }

  const [start, end] = asapInterval.map((x) => moment.utc(x));

  if (serverTime < start) {
    return Err({ reason: 'TOO_SOON', opening_time: start });
  }

  if (serverTime > end) {
    return Err({ reason: 'TOO_LATE' });
  }

  return Ok(true);
};

export default useWorkTime;

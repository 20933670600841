import React, { FC, memo } from 'react';
import { Card as MUICard, Stack, Box } from '@mui/material';
import { Image, Info, Footer, Label } from 'components/menu/Card';
import { useProductCard } from 'hooks/menu';
import { ProductModal } from 'components/modals';

interface CardProps {
  categoryId: number;
  productId: number;
  isIntersecting: boolean;
}

const Card: FC<CardProps> = ({ categoryId, productId, isIntersecting }) => {
  const {
    cardPriceCop,
    src,
    name,
    description,
    count,
    isCount,
    isModalOpen,
    isCountButton,
    handleOpenModal,
    handleCloseModal,
    handleAddProductToBasket,
    handleAddProductCount,
    handleRemoveProductCount,
    label,
  } = useProductCard(categoryId, productId);

  return (
    <>
      <MUICard
        sx={{
          borderRadius: '0px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          textDecoration: 'none',
          boxShadow: 'none',
          WebkitTapHighlightColor: 'transparent',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'initial',
        }}
        onClick={handleOpenModal}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            right: '-7px',
            top: '-7px',
            zIndex: '10',
          }}
        >
          {label.map((item) => (
            <Label
              key={item.order_by}
              text={item.text}
              textColor={item.text_color}
              backgroundColor={item.background_color}
            />
          ))}
        </Box>

        <Image src={src} isIntersecting={isIntersecting} />
        <Stack
          sx={{ flexGrow: '1', padding: { xs: '12px', sm: '20px' } }}
          direction="column"
        >
          <Info productName={name} description={description} />
          <Footer
            count={count}
            isCount={isCount}
            priceCop={cardPriceCop}
            isCountModeButton={isCountButton}
            handleAddProductToBasket={handleAddProductToBasket}
            handleAddProductCount={handleAddProductCount}
            handleRemoveProductCount={handleRemoveProductCount}
          />
        </Stack>
      </MUICard>
      <ProductModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        productId={productId}
        categoryId={categoryId}
      />
    </>
  );
};

export default memo(Card);

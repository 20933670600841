import React, { FC } from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ContentProps {
  onClose: () => void;
  handleSelectBrand: () => void;
  text?: string;
}

const Content: FC<ContentProps> = ({ onClose, text, handleSelectBrand }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '30px',
          lineHeight: '33px',
          fontWeight: '400',
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        {text || 'Вы достигли 18 лет?'}
      </Typography>
      <Stack sx={{ width: '100%' }} direction="row" spacing={2}>
        <Button
          sx={{
            height: '50px',
            minWidth: '120px',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'primary.main',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
          }}
          variant="outlined"
          color="primary"
          onClick={onClose}
          fullWidth
        >
          {t('no')}
        </Button>
        <Button
          sx={{
            height: '50px',
            minWidth: '120px',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'primary.contrastText',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
          }}
          variant="contained"
          color="primary"
          onClick={handleSelectBrand}
          fullWidth
        >
          {t('yes')}
        </Button>
      </Stack>
    </Box>
  );
};

export default Content;

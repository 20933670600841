import React, { FC, useCallback, useMemo } from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import image from 'assets/images/happy.png';
import animationData from 'lotties/cartOrderComplete.json';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectRestaurantById,
  selectRestaurantByOrderId,
} from 'store/selectors/restaurantSelectors';
import { Restaurant } from 'types/Restaurant';
import { useFormContext } from 'react-hook-form';

const HighRating: FC = () => {
  const { watch } = useFormContext();
  const restIdValue = watch('rest_id');
  const orderIdValue = watch('order_id');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const restVisitData = useAppSelector<Restaurant | undefined>(
    selectRestaurantById(restIdValue)
  );
  const restDeliveryData = useAppSelector(
    selectRestaurantByOrderId(orderIdValue)
  );
  const yandexLink = useMemo(() => {
    return restVisitData
      ? restVisitData.yandex_review_link
      : restDeliveryData?.yandex_review_link;
  }, [restVisitData, restDeliveryData]);
  const handleNavToMenu = () => {
    navigate('/');
  };
  const handleRedirect = useCallback(() => {
    return (
      window.open(`${yandexLink}`, '_blank') ||
      window.location.replace(`${yandexLink}`)
    );
  }, [yandexLink]);

  // const defaultLottieOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        sx={{
          fontSize: '30px',
          lineHeight: '30px',
          fontWeight: '400',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        {t('review_page.high_rating.title')}
      </Typography>
      <Box
        sx={{
          display: 'inline-block',
          maxWidth: '250px',
          marginBottom: '20px',
        }}
      >
        {/* <Lottie options={defaultLottieOptions} /> */}
        <Box sx={{ height: '150px', width: '150px', overflow: 'hidden' }}>
          <Box
            sx={{
              objectFit: 'cover',
              width: '100%',
              minHeight: '100%',
              objectPosition: 'center',
            }}
            src={image}
            component="img"
          />
        </Box>
      </Box>
      <Typography
        sx={{
          maxWidth: '500px',
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          marginBottom: '70px',
        }}
      >
        {t('review_page.high_rating.text')}
      </Typography>
      <Stack
        sx={{ margin: '0 auto', maxWidth: '500px' }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
      >
        <Button
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            height: '50px',
            fontWeight: '300',
            width: { xs: '100%', sm: '50%' },
          }}
          variant="contained"
          onClick={handleRedirect}
        >
          {t('review_page.high_rating.yes')}
        </Button>
        <Button
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            height: '50px',
            fontWeight: '300',
            width: { xs: '100%', sm: '50%' },
          }}
          variant="outlined"
          onClick={handleNavToMenu}
        >
          {t('review_page.high_rating.another_time')}
        </Button>
      </Stack>
    </Box>
  );
};

export default HighRating;

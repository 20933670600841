import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CaptchaImageProps {
  puzzleImage: string;
  pieceImage: string;
  relativeY: number;
  relativeX: number;
  displayError?: boolean;
  fullWidth?: boolean;
}

const CaptchaImage: FC<CaptchaImageProps> = ({
  puzzleImage,
  pieceImage,
  relativeY,
  relativeX,
  displayError = false,
  fullWidth = false,
}) => {
  const { t } = useTranslation();

  const width = fullWidth ? '100%' : '400px';
  const asImageSource = (data: string) => `data:image/png;base64,${data}`;

  return (
    <Box sx={{ position: 'relative', width, maxHeight: '100%' }}>
      {displayError && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              padding: '10px',
              fontSize: '18px',
              backgroundColor: 'white',
              color: 'error.main',
              borderRadius: '8px',
              fontWeight: 500,
            }}
          >
            {t('login.tips.captcha_tip_3')}
          </Typography>
        </Box>
      )}
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box
            component="img"
            sx={{ width: '100%', maxHeight: '100%' }}
            alt="captcha puzzle"
            src={asImageSource(puzzleImage)}
          />
        </Box>
        <Box
          sx={{
            width: '20%',
            height: '20%',
            left: `calc(${relativeX} * (100% - 20%))`,
            top: `calc(${relativeY} * (100% - 20%))`,
            position: 'absolute',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'fill',
            }}
            alt="captcha piece"
            src={asImageSource(pieceImage)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CaptchaImage;

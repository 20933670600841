import React, { FC } from 'react';
import { Box, Typography, Button as MUIButton } from '@mui/material';
import {
  Buttons,
  CheckDelivery,
} from 'components/modals/AddressModal/Modal/Content';
import { AddressForm, AddressFormRU } from 'components/account/forms';
import { useTranslation } from 'react-i18next';

interface ContentProps {
  onClose: () => void;
  modalType: 'add' | 'update';
  isRuForm: boolean;
  addressView: string;
  isCheckDelivery: boolean;
  handleBackToForm: () => void;
  handleDeleteAddress: () => void;
}

const Content: FC<ContentProps> = ({
  onClose,
  modalType,
  isRuForm,
  addressView,
  isCheckDelivery,
  handleBackToForm,
  handleDeleteAddress,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '530px' }}>
      {modalType === 'update' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '30px',
          }}
        >
          <Typography
            sx={{
              fontSize: '30px',
              lineHeight: '33px',
              fontWeight: '400',
            }}
            variant="h4"
          >
            {t('change_delivery_address')}
          </Typography>
          <MUIButton
            sx={{
              fontSize: '15px',
              fontWeight: '500',
              textTransform: 'capitalize',
              padding: '3px 10px',
            }}
            onClick={handleDeleteAddress}
          >
            {t('delete')}
          </MUIButton>
        </Box>
      ) : (
        <Typography
          sx={{
            marginBottom: '30px',
            fontSize: '30px',
            lineHeight: '33px',
            fontWeight: '400',
          }}
          variant="h4"
        >
          {t('delivery_address')}
        </Typography>
      )}
      {isCheckDelivery ? (
        <CheckDelivery
          addressView={addressView}
          handleBackToForm={handleBackToForm}
        />
      ) : (
        <>
          {isRuForm ? <AddressFormRU /> : <AddressForm modalType={modalType} />}
          <Buttons onClose={onClose} />
        </>
      )}
    </Box>
  );
};

export default Content;

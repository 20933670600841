import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useCategoryObserver = (
  ref: MutableRefObject<Element | null>,
  options: IntersectionObserverInit = {},
  forward = true
) => {
  const [element, setElement] = useState<Element | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useRef<null | IntersectionObserver>(null);

  const cleanObserver = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    if (ref) {
      setElement(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (!element) {
      return;
    }
    cleanObserver();

    const ob = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!forward) {
          setIsIntersecting(isElementIntersecting);
        } else if (forward && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          cleanObserver();
        }
      },
      { ...options }
    );
    observer.current = ob;

    ob.observe(element);
  }, [element, options]);

  return isIntersecting;
};

export default useCategoryObserver;

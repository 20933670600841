import React, { FC } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/NameModal/Modal';
import { FormProvider } from 'react-hook-form';

interface ModalProps {
  methods: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const Modal: FC<ModalProps> = ({ methods, isOpen, onClose, onSubmit }) => {
  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          padding: '32px',
          width: '540px',
          height: '432px',
          overflowY: 'inherit',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={onClose}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>

      <Box component={FormProvider} {...methods}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Content onClose={onClose} />
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;

import React, { FC } from 'react';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAvailableBonus } from 'store/selectors/accountSelectors';
import { useTranslation } from 'react-i18next';

interface CheckboxProps {
  control: Control<FieldValues>;
}
const Checkbox: FC<CheckboxProps> = ({ control }) => {
  const availablePoints = useAppSelector(selectAvailableBonus);
  const { t } = useTranslation();

  return (
    <Controller
      name="bonus"
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          value={value}
          onChange={onChange}
          control={<MUICheckbox color="secondary" />}
          label={t('i_want_to_use_bonuses', { availablePoints })}
        />
      )}
    />
  );
};

export default Checkbox;

import { createSelector } from '@reduxjs/toolkit';
import { SelectData } from 'types/SelectData';
import { RootState } from '../store';
import { selectCityId } from './citySelectors';
import { selectOrderHistory } from './orderSelectors';

const selectRestaurantData = (state: RootState) => state.restaurants;

/**
 * Returns restaurants data is loading
 */
export const selectRestaurantIsLoading = createSelector(
  [selectRestaurantData],
  (restaurantData) => restaurantData.isLoading
);
/**
 * Returns available restaurants list for selet field
 */
export const selectAvailableRestaurantsList = createSelector(
  [selectRestaurantData, selectCityId],
  (restaurantData, cityId) => {
    const availableRestaurants = restaurantData.restaurantList.filter(
      (restaurant) => restaurant.city.id === cityId
    );
    return availableRestaurants.map((restaurant) => {
      return { label: restaurant.name, value: restaurant.id } as SelectData;
    });
  }
);
/**
 * Returns available restaurants
 */
export const selectAvailableRestaurants = createSelector(
  [selectRestaurantData, selectCityId],
  (restaurantData, cityId) =>
    restaurantData.restaurantList.filter(
      (restaurant) => restaurant.city.id === cityId
    )
);
/**
 * Returns available & pickup restaurants list for select field
 */
export const selectAvailablePickupRestaurantsList = createSelector(
  [selectAvailableRestaurants],
  (availableRestaurants) =>
    availableRestaurants
      .filter((restaurant) => restaurant.is_pickup === 1)
      .map((pickupRestaurant) => {
        return {
          label: pickupRestaurant.name,
          value: pickupRestaurant.id,
        } as SelectData;
      })
);
/**
 * Returns the first available restaurant to display data
 */
export const selectFirstAvailableRestaurant = createSelector(
  [selectRestaurantData, selectCityId],
  (restaurantData, cityId) => {
    const firstRestaurant = restaurantData.restaurantList.filter(
      (restaurant) => restaurant.city.id === cityId
    )[0];
    return {
      name: firstRestaurant?.name,
      address: firstRestaurant?.address,
      description: firstRestaurant?.info,
      phone: firstRestaurant?.phones,
      email: firstRestaurant?.email,
      operating_time: firstRestaurant?.operating_time,
      operating_time_fri: firstRestaurant?.operating_time_fri,
      operating_time_sat: firstRestaurant?.operating_time_sat,
      operating_time_sun: firstRestaurant?.operating_time_sun,
      longitude: firstRestaurant?.longitude,
      latitude: firstRestaurant?.latitude,
    };
  }
);
/**
 * Returns the restaurant data by id
 */
export const selectRestaurantById = (restId: number) =>
  createSelector([selectAvailableRestaurants], (restaurants) => {
    const selectedRest = restaurants.find((rest) => rest.id === restId);
    return selectedRest;
  });
/**
 * Returns the restaurant data by order id
 */
export const selectRestaurantByOrderId = (orderId: number) =>
  createSelector(
    [selectAvailableRestaurants, selectOrderHistory],
    (restaurants, orderHistory) => {
      const selectedOrder = orderHistory.find((order) => order.id === orderId);
      const selectedRest = restaurants.find(
        (rest) => rest.id === selectedOrder?.restaurant
      );
      return selectedRest;
    }
  );
/**
 * Returns phone first restaurant
 */
export const selectPhoneNumber = createSelector(
  [selectRestaurantData],
  (restaurants) => restaurants.restaurantList[0].phones
);

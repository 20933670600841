import React, { FC, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import { Card, CategoryTitle, PromotionSlider } from 'components/menu';
import { MenuProduct } from 'types/Menu';
import { useActiveMenuObserver, useCategoryObserver } from 'hooks/menu';
import { useScrollToBlock } from 'hooks';

interface CategoryProps {
  activeCategoryId: string;
  categoryRefs: any;
  items: MenuProduct[];
  categoryId: string;
  categoryName: string;
  rootMargin: string;
  handleSetActiveCategory: (id: string) => void;
}

const Category: FC<CategoryProps> = ({
  activeCategoryId,
  categoryRefs,
  items,
  categoryId,
  categoryName,
  rootMargin,
  handleSetActiveCategory,
}) => {
  useScrollToBlock();
  const isIntersecting = useCategoryObserver(categoryRefs[categoryId]);
  const activeCategory = useActiveMenuObserver(
    categoryRefs[categoryId],
    activeCategoryId,
    handleSetActiveCategory,
    {
      rootMargin,
    }
  );

  useEffect(() => {
    handleSetActiveCategory(activeCategory);
  }, [activeCategoryId]);

  return (
    <Box
      sx={{
        marginBottom: { xs: '32px', sm: '70px' },
      }}
      ref={categoryRefs[categoryId]}
      id={categoryId.toString()}
    >
      <Box sx={{ marginBottom: { xs: '9px', sm: '35px' } }}>
        <CategoryTitle>{categoryName}</CategoryTitle>
      </Box>
      {categoryId === 'promo' && <PromotionSlider />}
      <Box
        sx={{
          display: 'grid',
          columnGap: { xs: 1, sm: 2, md: 4, lg: 5, xl: 5 },
          rowGap: { xs: 2, sm: 3, md: 4, lg: 5, xl: 5 },
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
            xl: 'repeat(4, 1fr)',
          },
        }}
      >
        {items.map((item: MenuProduct) => (
          <Card
            productId={item.id}
            categoryId={item.parent_menu}
            key={item.id}
            isIntersecting={isIntersecting}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(Category);

import React, { FC } from 'react';
import { Dialog } from '@mui/material';
import { Content } from 'components/modals/AgeModal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSelectBrand: () => void;
  text?: string;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  handleSelectBrand,
  text,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => null}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          overflowY: 'inherit',
          maxWidth: '500px',
          padding: '15px 30px',
        },
      }}
    >
      <Content
        text={text}
        onClose={onClose}
        handleSelectBrand={handleSelectBrand}
      />
    </Dialog>
  );
};

export default Modal;

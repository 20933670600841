import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/AddressModal';

interface AddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  addressId?: number;
  modalType: 'add' | 'update';
}

const AddressModal: FC<AddressModalProps> = ({
  isOpen,
  onClose,
  addressId,
  modalType,
}) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          modalType={modalType}
          addressId={addressId}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          modalType={modalType}
          addressId={addressId}
        />
      )}
    </>
  );
};

export default AddressModal;

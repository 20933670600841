import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseBookingBody } from 'types/Booking';
import { createBooking } from './ActionCreators';

interface BasketState {
  booking: ResponseBookingBody;
  isLoading: boolean;
  error: string;
}

const initialState: BasketState = {
  booking: {
    reason: '',
    booking_id: null,
    success: false,
  },
  isLoading: false,
  error: '',
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: {
    [createBooking.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseBookingBody>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.booking.booking_id = action.payload.booking_id || null;
      state.booking.reason = action.payload.reason || '';
      state.booking.success = action.payload.success;
    },
    [createBooking.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createBooking.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default bookingSlice.reducer;

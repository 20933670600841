import React, { FC } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import animationData from 'lotties/errorBasket.json';
import Lottie from 'react-lottie';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectFirstAvailableRestaurant } from 'store/selectors/restaurantSelectors';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface ErrorMessageProps {
  message?: string | null;
}

/**
 * Displays error with description.
 * If message prop is passed displays it instead of predefined message.
 */
const ErrorMessage: FC<ErrorMessageProps> = ({ message = null }) => {
  const { t, i18n } = useTranslation();
  const isLtr = i18n.dir() === 'ltr';
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { phone } = useAppSelector(selectFirstAvailableRestaurant);
  const { FEEDBACK_LINK } = useAppSelector(selectAppSettingsData);

  const handleNavToCart = () => {
    navigate('/cart');
  };

  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '24px', sm: '30px' },
          fontWeight: '400',
          color: 'text.primary',
        }}
        variant="h3"
      >
        {message || t('sorry_something_went_wrong')}
      </Typography>
      <Box sx={{ maxWidth: '300px' }}>
        <Lottie options={defaultOptions} />
      </Box>
      <Button
        sx={{
          textTransform: 'inherit',
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          padding: '13px 25px',
          color: 'primary.contrastText',
          boxShadow: 'none',
          marginBottom: '10px',
        }}
        component={NavLink}
        to="/payment"
        variant="contained"
      >
        Повторить
      </Button>
      <Button
        sx={{
          fontSize: '20px',
          lineHeight: '23px',
          height: '50px',
          fontWeight: '300',
          padding: '0px 20px',
          marginBottom: '30px',
        }}
        variant="text"
        onClick={handleNavToCart}
      >
        {isLtr ? (
          <ArrowBackIos color="secondary" />
        ) : (
          <ArrowForwardIos color="secondary" />
        )}

        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            lineHeight: '21px',
            color: 'secondary.main',
            fontWeight: '500',
          }}
        >
          Вернуться к заказу
        </Typography>
      </Button>
      {FEEDBACK_LINK && phone && (
        <>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xs: '20px', sm: '25px' },
              fontWeight: '400',
              color: 'text.primary',
              marginBottom: '11px',
            }}
            variant="body1"
          >
            {t('please_call_or_text_us')}
          </Typography>
          <Typography
            sx={{
              display: 'inline-block',
              fontSize: '16px',
              lineHeight: '19px',
              textDecoration: 'none',
              marginBottom: '8px',
              WebkitTapHighlightColor: 'transparent',
              color: 'secondary.main',
            }}
            component={Link}
            href={`tel:${phone}`}
          >
            {phone}
          </Typography>
          <Typography
            sx={{
              display: 'inline-block',
              fontSize: '16px',
              lineHeight: '19px',
              textDecoration: 'none',
              marginBottom: '8px',
              WebkitTapHighlightColor: 'transparent',
              color: 'secondary.main',
            }}
            component={Link}
            href={FEEDBACK_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('send_a_text_message')}
          </Typography>
        </>
      )}
    </>
  );
};

export default ErrorMessage;

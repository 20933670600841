import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const OdnoklassnikiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8212 11.101C13.8212 12.3043 14.7967 13.2799 16 13.2799C17.2033 13.2799 18.1789 12.3043 18.1789 11.101C18.1789 9.89764 17.2033 8.92212 16 8.92212C14.7967 8.92212 13.8212 9.89764 13.8212 11.101Z"
        fill="#396A42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM10.7292 11.1009C10.7292 8.18993 13.089 5.83006 16 5.83006C18.9111 5.83006 21.2709 8.18993 21.2709 11.1009C21.2709 14.0119 18.9111 16.3719 16 16.3719C13.089 16.3719 10.7292 14.0119 10.7292 11.1009ZM17.9466 20.566C20.0567 20.1361 21.3212 19.1368 21.3881 19.0832C22.0056 18.588 22.1048 17.686 21.6096 17.0685C21.1145 16.451 20.2126 16.3518 19.5949 16.8469C19.5819 16.8575 18.234 17.8915 16.0161 17.8936C16.0169 17.8936 16.0168 17.8935 16.0148 17.8935C16.0125 17.8935 16.0137 17.8935 16.0148 17.8935C16.0146 17.8935 16.015 17.8935 16.0148 17.8935C16.0143 17.8935 16.0138 17.8936 16.0135 17.8936C13.7949 17.8919 12.418 16.8575 12.405 16.8469C11.7874 16.3518 10.8855 16.451 10.3903 17.0685C9.89515 17.686 9.99434 18.588 10.6118 19.0832C10.6796 19.1376 11.9963 20.1629 14.1656 20.5826L11.1423 23.7422C10.5929 24.312 10.6094 25.2192 11.1792 25.7687C11.4573 26.0368 11.8157 26.1701 12.1739 26.1701C12.5493 26.1701 12.9245 26.0234 13.2057 25.7317L16.0143 22.7398L19.1065 25.7511C19.6668 26.3105 20.5741 26.3096 21.1333 25.7495C21.6925 25.1894 21.6919 24.282 21.1317 23.7227L17.9466 20.566Z"
        fill="#396A42"
      />
    </SvgIcon>
  );
};

export default OdnoklassnikiIcon;

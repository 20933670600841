import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { CheckCircleOutlineOutlined as CheckIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Success: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <CheckIcon
            sx={{ fontSize: '72px', marginBottom: '15px' }}
            color="success"
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -60%)',
          textAlign: 'center',
          width: '70%',
        }}
      >
        <Typography
          sx={{ fontSize: '16px', lineHeight: '17px', fontWeight: '300' }}
        >
          {t('address_saved_successfully')}
        </Typography>
      </Box>
    </>
  );
};

export default Success;

import React, { FC } from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';

interface SwitchProps {
  firstValue: string;
  secondValue: string;
  value: boolean;
  handleChange: (arg: boolean) => () => void;
}

const Switch: FC<SwitchProps> = ({
  firstValue,
  secondValue,
  value,
  handleChange,
}) => {
  return (
    <Stack
      sx={{
        height: '50px',
        backgroundColor: 'grey.300',
        position: 'relative',
        borderRadius: '30px',
        overflow: 'hidden',
        alignItems: 'center',
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
      }}
      direction="row"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          width: '50%',
          height: '100%',
          borderRadius: '30px',
          backgroundColor: 'primary.main',
          transform: `translate(${value ? 100 : 0}%, 0px)`,
          transition: 'all 0.3s ease-out',
        }}
      />
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '20px' },
          lineHeight: { xs: '20px', sm: '26px' },
          color: value ? 'grey.600' : 'primary.contrastText',
          fontWeight: value ? '300' : '600',
          width: '50%',
          height: '100%',
          WebkitTapHighlightColor: 'transparent',
          zIndex: '1',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          userSelect: 'none',
          padding: '0',
          borderRadius: '30px',
        }}
        onClick={handleChange(false)}
        component={Button}
      >
        {firstValue}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '20px' },
          lineHeight: { xs: '20px', sm: '26px' },
          color: value ? 'primary.contrastText' : 'grey.600',
          fontWeight: value ? '600' : '300',
          width: '50%',
          height: '100%',
          WebkitTapHighlightColor: 'transparent',
          zIndex: '1',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          userSelect: 'none',
          padding: '0',
          borderRadius: '30px',
        }}
        onClick={handleChange(true)}
        component={Button}
      >
        {secondValue}
      </Typography>
    </Stack>
  );
};

export default Switch;

import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { BackDrawerButton, Portal } from 'components/generic';
import { animated } from 'react-spring';
import { useDrawer } from 'hooks';
import { Brand } from 'types/Brands';
import { Card } from 'components/modals/BrandsModal';
import { useTranslation } from 'react-i18next';

interface DrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  brands: Brand[];
  isOpener?: boolean;
}

const Drawer: FC<DrawerProps> = ({ isOpen, handleClose, brands, isOpener }) => {
  const { isMount, y, closeDrawer } = useDrawer(isOpen, handleClose);
  const { t } = useTranslation();

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            {!isOpener && (
              <Box
                sx={{ position: 'fixed', top: '24px', left: '20px' }}
                onClick={closeDrawer}
              >
                <BackDrawerButton direction="down" />
              </Box>
            )}

            <Box
              sx={{
                height: '100vh',
                width: '100%',
                padding: '70px 20px 10px 20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  marginBottom: '10px',
                  marginTop: '10px',
                }}
              >
                {t('select_restaurant')}
              </Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '100%',
                  width: '100%',
                  padding: '0 0 30px 0',
                  '&::-webkit-scrollbar': {
                    width: 7,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey.400',
                    borderRadius: '20px',
                  },
                }}
              >
                <Stack sx={{ width: '100%' }} direction="column" spacing={0.5}>
                  {brands.map((brand) => (
                    <Card
                      key={brand.id}
                      id={brand.id}
                      name={brand.name}
                      description={brand.short_description}
                      logo={brand.logo}
                      image={brand.image}
                      label={brand.label}
                      handleClose={handleClose}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;

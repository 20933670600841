import { useState, useEffect } from 'react';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { formatPrice } from 'helpers';

const usePriceConverter = (modifierName: string, modifierPrice?: number) => {
  const [labelWithPrice, setLabelWithPrice] = useState<string>('');
  const { CURRENCY_CODE } = useAppSelector(selectAppSettingsData);

  useEffect(() => {
    if (modifierPrice) {
      setLabelWithPrice(
        `${modifierName} + ${formatPrice(modifierPrice, CURRENCY_CODE)}`
      );
    } else {
      setLabelWithPrice(modifierName);
    }
  }, [modifierName, modifierPrice]);

  return [labelWithPrice];
};

export default usePriceConverter;

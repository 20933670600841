const useLanguages = () => {
  const languages = [
    // {
    //   code: 'en-IN',
    //   country_code: 'gb',
    //   name: 'English',
    // },
    // {
    //   code: 'ar-AE',
    //   country_code: 'ae',
    //   name: 'العربية',
    // },
    {
      code: 'ru-RU',
      country_code: 'ru',
      name: 'Русский',
    },
  ];

  return languages;
};

export default useLanguages;

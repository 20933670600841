import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectCalculatedBasket } from 'store/selectors/orderSelectors';

interface CheckoutButtonProps {
  handleCheckoutButton: () => void;
}

const CheckoutButton: FC<CheckoutButtonProps> = ({ handleCheckoutButton }) => {
  const { t } = useTranslation();
  const { success } = useAppSelector(selectCalculatedBasket);

  return (
    <>
      <Button
        sx={{
          borderRadius: '6px',
          textTransform: 'capitalize',
          fontSize: { xs: '18px', sm: '20px' },
          lineHeight: { xs: '21px', sm: '23px' },
          fontWeight: '300',
          boxShadow: 'none',
          color: 'primary.contrastText',
          height: '50px',
          width: { xs: '100%', sm: '290px' },
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        }}
        color="primary"
        variant="contained"
        onClick={handleCheckoutButton}
        disabled={!success}
      >
        {t('checkout')}
      </Button>
    </>
  );
};

export default CheckoutButton;

import React, { FC } from 'react';
import { CardMedia, Box, Skeleton } from '@mui/material';
import { useSrcImage } from 'hooks';

export interface MenuCardImageProps {
  src: string;
  isIntersecting: boolean;
}
const MenuCardImage: FC<MenuCardImageProps> = ({ src, isIntersecting }) => {
  const image = useSrcImage(src);
  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: { xs: '167px', sm: '200px', md: '275px' },
      }}
    >
      {isIntersecting ? (
        <CardMedia
          sx={{
            height: '100%',
            transition: '0.5s',
            '&:hover': {
              transform: { xs: 'none', md: 'scale(1.2)' },
            },
          }}
          component="img"
          image={image}
          alt="product"
        />
      ) : (
        <Skeleton
          sx={{ height: '100%' }}
          animation="wave"
          variant="rectangular"
        />
      )}
    </Box>
  );
};

export default MenuCardImage;

import React, { FC } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { SelectData } from 'types/SelectData';
import Parser from 'html-react-parser';

interface SelectFieldProps {
  control: Control<FieldValues>;
  name: string;
  selectItem: SelectData[];
  defaultValue?: number | string | null;
  label?: string;
  required?: boolean;
  requiredText?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const SelectField: FC<SelectFieldProps> = ({
  control,
  name,
  selectItem,
  defaultValue,
  label,
  required,
  requiredText = '',
  size,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          sx={{
            '& .MuiOutlinedInput-input': {
              padding: size === 'small' ? '11px 14px' : '13.5px 14px',
            },
            '& .MuiInputLabel-root': {
              color: '#BDBDBD',
              lineHeight: size === 'small' ? '28px' : '20px',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'primary.contrastText',
            },
            '& .MuiInputLabel-shrink': {
              color: 'primary.contrastText',
              backgroundColor: 'primary.main',
              padding: '0px 5px',
              left: '-5px',
              top: '2px',
              fontWeight: '600',
              fontSize: '13px',
              lineHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '6px',
              '& fieldset': {
                borderColor: 'primary.main',
                '& legend': {
                  width: '38px',
                },
              },
              '&:hover fieldset': {
                border: '1px solid',
                borderColor: 'secondary.main',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid',
                borderColor: 'secondary.main',
              },
            },
          }}
          select
          fullWidth
          label={label || ''}
          variant="outlined"
          value={value}
          onChange={onChange}
          error={!!error}
          size={size}
          SelectProps={{
            MenuProps: { PaperProps: { sx: { maxHeight: '200px' } } },
          }}
          disabled={disabled}
        >
          <MenuItem disabled value="">
            {label}
          </MenuItem>
          {selectItem.map((item: SelectData) => (
            <MenuItem value={item.value} key={item.value}>
              {Parser(item.label)}
            </MenuItem>
          ))}
        </TextField>
      )}
      rules={{
        required: required || requiredText,
      }}
    />
  );
};

export default SelectField;

import React, { FC } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectFeedbackData } from 'store/selectors/accountSelectors';
import { Comment, Success } from 'components/reviews/LowRating';

const LowRating: FC = () => {
  const { success } = useAppSelector(selectFeedbackData);

  return <>{!success ? <Comment /> : <Success />}</>;
};

export default LowRating;

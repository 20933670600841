import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { selectBrandId } from './brandsSelectors';

const selectSocialMediaData = (state: RootState) => state.socialMedia;
/**
 * Returns social media
 */
export const selectSocialMedia = createSelector(
  [selectSocialMediaData, selectBrandId],
  (socialMediaData, brandId) =>
    socialMediaData.socialMedia.filter((item) => {
      if (brandId) {
        return Number(item.brand) === brandId;
      }

      return item;
    })
);

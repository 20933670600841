import React, { FC } from 'react';
import {
  Box,
  Button,
  Card as MUICard,
  CardMedia,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface CardProps {
  id: number;
  image: string;
  title: string;
  announce: string;
  description: string;
  published_at: string;
}

const Card: FC<CardProps> = ({
  id,
  image,
  title,
  announce,
  description,
  published_at,
}) => {
  const { t } = useTranslation();

  return (
    <MUICard
      sx={{
        borderRadius: '0px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: 'none',
        textDecoration: 'none',
      }}
      to={`/blog/${id}`}
      state={{ id, image, title, description, published_at }}
      component={NavLink}
    >
      <Box sx={{ overflow: 'hidden', position: 'relative' }}>
        <CardMedia
          sx={{
            height: '250px',
            backgroundColor: '#F2F2F2',
            transition: '0.5s',
            '&:hover': {
              transform: { xs: 'none', md: 'scale(1.2)' },
            },
          }}
          component="img"
          image={image}
          alt="blog-image"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: { xs: '20px 15px', sm: '20px' },
          flexGrow: '1',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '20px', sm: '25px', md: '30px' },
            lineHeight: { xs: '24px', sm: '28px', md: '36px' },
            fontWeight: '400',
            marginBottom: '10px',
          }}
          variant="h4"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px', md: '20px' },
            lineHeight: { xs: '16px', sm: '21px', md: '26px' },
            fontWeight: '300',
            flexGrow: '1',
            marginBottom: '15px',
          }}
          variant="body1"
        >
          {announce}
        </Typography>
        <Button
          sx={{
            boxShadow: 'none',
            textTransform: 'inherit',
            borderRadius: '6px',
            border: '2px solid',
            borderColor: 'primary.main',
            height: '50px',
            alignSelf: { xs: 'flex-start', sm: 'flex-end' },
            '&:hover': {
              border: '2px solid',
              borderColor: 'primary.main',
            },
          }}
          variant="outlined"
        >
          <Typography
            sx={{
              fontSize: { xs: '18px', sm: '20px' },
              fontWeight: '300',
              lineHeight: { xs: '21px', sm: '23px' },
              color: 'secondary.main',
            }}
          >
            {t('read_more')}
          </Typography>
        </Button>
      </Box>
    </MUICard>
  );
};

export default Card;

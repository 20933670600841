import React, { FC, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { BackDrawerButton } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';

const BackToMenuButton: FC = () => {
  const { t } = useTranslation();
  const direction = useDirection();

  const dir = useMemo(() => {
    if (direction === 'ltr') {
      return 'left';
    }
    return 'right';
  }, [direction]);

  const isLtr = useMemo(() => {
    if (direction === 'ltr') {
      return true;
    }
    return false;
  }, [direction]);

  return (
    <NavLink style={{ textDecoration: 'none' }} to="/">
      <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <BackDrawerButton direction={dir} />
      </Box>
      <Button
        sx={{
          display: { xs: 'none', sm: 'inline-flex' },
          textTransform: 'inherit',
        }}
      >
        {isLtr ? (
          <ArrowBackIos color="secondary" />
        ) : (
          <ArrowForwardIos color="secondary" />
        )}

        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            lineHeight: '21px',
            color: 'secondary.main',
            fontWeight: '500',
          }}
        >
          {t('back_to_the_menu')}
        </Typography>
      </Button>
    </NavLink>
  );
};

export default BackToMenuButton;

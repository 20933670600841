import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useTitleEnding = (count: number) => {
  const [ending, setEnding] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    switch (count) {
      case 1:
        return setEnding(t('product_form.form_1'));
      case 2:
      case 3:
      case 4:
        return setEnding(t('product_form.form_2'));
      default:
        return setEnding(t('product_form.form_3'));
    }
  }, [count, t]);

  return ending;
};

export default useTitleEnding;

import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/AgeModal';

interface AgeModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSelectBrand: () => void;
  text?: string;
}

const AgeModal: FC<AgeModalProps> = ({
  isOpen,
  onClose,
  handleSelectBrand,
  text,
}) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          text={text}
          handleSelectBrand={handleSelectBrand}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          text={text}
          handleSelectBrand={handleSelectBrand}
        />
      )}
    </>
  );
};

export default AgeModal;

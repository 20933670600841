import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const VkontakteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16333 24.8367 0 16 0ZM22.1533 18.0517C22.1533 18.0517 23.5683 19.4483 23.9167 20.0967C23.9267 20.11 23.9317 20.1233 23.935 20.13C24.0767 20.3683 24.11 20.5533 24.04 20.6917C23.9233 20.9217 23.5233 21.035 23.3867 21.045H20.8867C20.7133 21.045 20.35 21 19.91 20.6967C19.5717 20.46 19.2383 20.0717 18.9133 19.6933C18.4283 19.13 18.0083 18.6433 17.585 18.6433C17.5312 18.6432 17.4778 18.6517 17.4267 18.6683C17.1067 18.7717 16.6967 19.2283 16.6967 20.445C16.6967 20.825 16.3967 21.0433 16.185 21.0433H15.04C14.65 21.0433 12.6183 20.9067 10.8183 19.0083C8.615 16.6833 6.63167 12.02 6.615 11.9767C6.49 11.675 6.74833 11.5133 7.03 11.5133H9.555C9.89167 11.5133 10.0017 11.7183 10.0783 11.9C10.1683 12.1117 10.4983 12.9533 11.04 13.9C11.9183 15.4433 12.4567 16.07 12.8883 16.07C12.9693 16.069 13.0488 16.0485 13.12 16.01C13.6833 15.6967 13.5783 13.6883 13.5533 13.2717C13.5533 13.1933 13.5517 12.3733 13.2633 11.98C13.0567 11.695 12.705 11.5867 12.4917 11.5467C12.578 11.4275 12.6918 11.3309 12.8233 11.265C13.21 11.0717 13.9067 11.0433 14.5983 11.0433H14.9833C15.7333 11.0533 15.9267 11.1017 16.1983 11.17C16.7483 11.3017 16.76 11.6567 16.7117 12.8717C16.6967 13.2167 16.6817 13.6067 16.6817 14.0667C16.6817 14.1667 16.6767 14.2733 16.6767 14.3867C16.66 15.005 16.64 15.7067 17.0767 15.995C17.1336 16.0307 17.1994 16.0498 17.2667 16.05C17.4183 16.05 17.875 16.05 19.1117 13.9283C19.4931 13.2454 19.8245 12.5358 20.1033 11.805C20.1283 11.7617 20.2017 11.6283 20.2883 11.5767C20.3523 11.5441 20.4232 11.5275 20.495 11.5283H23.4633C23.7867 11.5283 24.0083 11.5767 24.05 11.7017C24.1233 11.9 24.0367 12.505 22.6817 14.34L22.0767 15.1383C20.8483 16.7483 20.8483 16.83 22.1533 18.0517V18.0517Z" />
    </SvgIcon>
  );
};

export default VkontakteIcon;

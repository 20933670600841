import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/BrandsModal';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBrands } from 'store/selectors/brandsSelectors';

interface BrandsModalProps {
  isOpen: boolean;
  onClose: () => void;
  isOpener?: boolean;
}

const BrandsModal: FC<BrandsModalProps> = ({ isOpen, onClose, isOpener }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const brands = useAppSelector(selectBrands);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isDrawer ? (
        <Drawer
          isOpen={isOpen}
          handleClose={handleClose}
          brands={brands}
          isOpener={isOpener}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          handleClose={handleClose}
          brands={brands}
          isOpener={isOpener}
        />
      )}
    </>
  );
};

export default BrandsModal;

import React, { FC, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import animationData from 'lotties/cartOrderComplete.json';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';

interface OrderResultParams {
  orderNumber: string | number | null;
  orderMessage: string | null;
}

/**
 * Displays order result element
 */
const OrderResult: FC<OrderResultParams> = ({ orderNumber, orderMessage }) => {
  const { t } = useTranslation();
  const isHomePage = useAppSelector(selectPageData('home'));
  const dispatch = useAppDispatch();
  const { clearBasket, clearOrder } = orderSlice.actions;

  useEffect(() => {
    // Clear basket and order after success
    dispatch(clearBasket());
    dispatch(clearOrder());
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '24px', sm: '30px' },
          fontWeight: '400',
          color: 'text.primary',
          marginBottom: '30px',
        }}
        variant="h3"
      >
        {orderMessage}
      </Typography>

      {orderNumber && (
        <Stack direction="column" spacing={0}>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xs: '18px', sm: '20px' },
              fontWeight: '400',
              color: '#949494',
            }}
            variant="body1"
          >
            {t('order_number')}
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xs: '20px', sm: '24px' },
              fontWeight: '600',
            }}
            variant="body1"
          >
            {orderNumber}
          </Typography>
        </Stack>
      )}
      <Box sx={{ maxWidth: '300px' }}>
        <Lottie options={defaultOptions} />
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: { xs: '18px', sm: '20px' },
          fontWeight: '400',
          marginBottom: '40px',
        }}
        variant="body1"
      >
        {t('the_order_has_been_placed')}
      </Typography>
      <Button
        sx={{
          textTransform: 'inherit',
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          padding: '13px 25px',
          color: 'primary.contrastText',
          boxShadow: 'none',
        }}
        component={NavLink}
        to={isHomePage ? '/menu' : '/'}
        variant="contained"
      >
        {t('back_to_the_menu')}
      </Button>
    </>
  );
};

export default OrderResult;

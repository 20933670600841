import React, { FC } from 'react';
import { Box, Rating, Typography } from '@mui/material';
import { FormatQuote as FormatQuoteIcon } from '@mui/icons-material/';
import Parser from 'html-react-parser';

interface ReviewProps {
  text: string;
  name: string;
  ratingValue: number;
}

const Review: FC<ReviewProps> = ({ text, name, ratingValue }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <FormatQuoteIcon
          sx={{
            fontSize: '50px',
            transform: 'rotate(180deg)',
            color: '#9CA4BC',
          }}
        />
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '20px' },
            lineHeight: { xs: '16px', sm: '23px' },
            fontWeight: '300',
            marginBottom: '27px',
          }}
          component="div"
        >
          {Parser(text)}
        </Typography>
      </Box>
      <Box>
        <Rating
          sx={{ marginBottom: '20px' }}
          name="read-only"
          value={ratingValue}
          readOnly
        />
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            fontWeight: '300',
          }}
        >
          - {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default Review;

import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { BackToButton } from 'components/pageNotFoundView';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectImagesData } from 'store/selectors/pageSelectors';

const PageNotFoundView: FC = () => {
  const { t } = useTranslation();
  const { image404 } = useAppSelector<{ image404: string }>(selectImagesData);

  return (
    <Box sx={{ padding: '40px 0px 100px 0px' }}>
      <Container>
        <Box sx={{ marginBottom: '20px' }}>
          <BackToButton />
        </Box>
        <Typography sx={{ fontSize: '30px', lineHeight: '36px' }}>
          {t('ooops')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '30px',
          }}
        >
          <Box
            sx={{
              objectFit: 'cover',
              width: { xs: '100%', sm: 'auto' },
              height: '100%',
              marginBottom: '20px',
            }}
            component="img"
            src={image404}
          />
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '28px' },
              lineHeight: { xs: '19px', sm: '36px' },
              fontWeight: '300',
            }}
          >
            {t('page_not_found')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PageNotFoundView;

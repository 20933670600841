import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Brand } from 'types/Brands';

export const getBrands = createAsyncThunk(
  'brand/get_brands',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<Brand[]>(
        `${process.env.REACT_APP_V3}/brand/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get brands, ${e}`);
    }
  }
);

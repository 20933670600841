import React, { FC } from 'react';
import { Box } from '@mui/material';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Review } from 'components/home/Reviews';
import { Review as ReviewType } from 'types/Pages';

interface ReviewsSliderProps {
  data: ReviewType[];
}

const ReviewsSlider: FC<ReviewsSliderProps> = ({ data }) => {
  SwiperCore.use([Autoplay, Mousewheel, Navigation]);

  return (
    <Box>
      <Swiper
        spaceBetween={25}
        centeredSlidesBounds
        breakpoints={{
          '767': {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        }}
      >
        {data.map((review) => (
          <SwiperSlide key={review.name}>
            <Review text={review.text} name={review.name} ratingValue={5} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ReviewsSlider;

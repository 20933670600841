import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { Ingredient } from 'types/Menu';
import { OptionCheckbox } from 'components/generic';
import { useTranslation } from 'react-i18next';

interface IngredientsProps {
  ingredients: Ingredient[];
}

const Ingredients: FC<IngredientsProps> = ({ ingredients }) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelect = (value: number) => {
    setSelectedItems((prevSelectedItems) => {
      const isPresent = prevSelectedItems.includes(value);

      if (isPresent) {
        const remaining = prevSelectedItems.filter(
          (item: number) => item !== value
        );
        return remaining;
      }

      return [...prevSelectedItems, value];
    });
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '20px' },
          lineHeight: { xs: '19px', sm: '23px' },
          marginBottom: '10px',
        }}
      >
        {t('add-ons')}
      </Typography>
      {ingredients.map((ingredient) => (
        <OptionCheckbox
          selectedItems={selectedItems}
          handleSelect={handleSelect}
          key={ingredient.id}
          label={ingredient.name}
          groupId="ingredients"
          value={ingredient.id}
          price={ingredient.price_kop}
        />
      ))}
    </>
  );
};

export default Ingredients;

import React, { FC, useRef, useMemo } from 'react';
import {
  Box,
  Container,
  IconButton,
  Button,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectFilterQueryInput,
  selectFilteredMenuTabs,
} from 'store/selectors/menuSelectors';
import { useTranslation } from 'react-i18next';
import { ArrowBackIosNew, ArrowForwardIos, Search } from '@mui/icons-material';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Parser from 'html-react-parser';
import { selectCitiesData } from 'store/selectors/citySelectors';
import { menuSlice } from 'store/reducers/MenuSlice/MenuSlice';
import { City } from 'types/City';
import { useLanguages } from 'hooks';

interface NavProps {
  activeCategoryId: string;
  handleScrollTo: (id: string) => () => void;
}

const Nav: FC<NavProps> = ({ activeCategoryId, handleScrollTo }) => {
  SwiperCore.use([Autoplay, Mousewheel, Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { t, i18n } = useTranslation();
  const tabs = useAppSelector(selectFilteredMenuTabs(t('promo')));
  const cities = useAppSelector<City[]>(selectCitiesData);
  const languages = useLanguages();

  const dispatch = useAppDispatch();
  const filterQueryInput = useAppSelector(selectFilterQueryInput);
  const { setFilterQueryInput } = menuSlice.actions;

  const isLtr = useMemo(() => {
    return i18n.dir() === 'ltr';
  }, [i18n.dir()]);

  const isTopBar = useMemo(() => {
    return cities.length < 2 && languages.length <= 1;
  }, [cities, languages]);

  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', lg: 'block' },
          width: '100dvw',
          height: '49px',
          backgroundColor: 'common.white',
          position: 'fixed',
          top: {
            xs: isTopBar ? '59px' : '99px',
            md: isTopBar ? '80px' : '110px',
          },
          zIndex: '1100',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Container maxWidth={false} sx={{ maxWidth: '1608px', height: '100%' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 234px',
              alignItems: 'center',
              gap: '3dvw',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                marginRight: '50px',
                width: 'calc(100% - 50px)',
                minWidth: 0,
              }}
            >
              <IconButton
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  marginRight: '10px',
                  position: 'absolute',
                  left: '-50px',
                  top: '0',
                  bottom: '0',
                  height: '40px',
                  margin: 'auto',
                  '&.swiper-button-disabled': {
                    opacity: 0,
                    cursor: 'inherit',
                  },
                }}
                size="medium"
                ref={navigationPrevRef}
              >
                {isLtr ? (
                  <ArrowBackIosNew fontSize="medium" color="primary" />
                ) : (
                  <ArrowForwardIos fontSize="medium" color="primary" />
                )}
              </IconButton>
              <Swiper
                dir={i18n.dir()}
                freeMode
                slidesPerView="auto"
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
              >
                {tabs.map(
                  (tab: { id: string; name: string; orderBy: number }) => (
                    <SwiperSlide style={{ width: 'auto' }} key={tab.id}>
                      <Button
                        sx={{
                          color:
                            tab.id === activeCategoryId
                              ? 'primary.main'
                              : 'text.primary',
                          fontSize: '16px',
                          lineHeight: '19px',
                          fontWeight: '700',
                          textTransform: 'inherit',
                          transition: 'all 0.3s ease-in-out',
                          cursor: 'pointer',
                          padding: '15px 15px',
                          position: 'relative',
                          borderRadius: '0px',
                          '&:before': {
                            content: "''",
                            width: '100%',
                            height: '2px',
                            position: 'absolute',
                            backgroundColor: 'primary.main',
                            opacity: tab.id === activeCategoryId ? '1' : '0',
                            transition: 'all 0.5s ease-in-out',
                            bottom: '0',
                            left: '0',
                          },
                        }}
                        key={tab.id}
                        onClick={handleScrollTo(tab.id)}
                      >
                        {Parser(tab.name)}
                      </Button>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
              <IconButton
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  marginLeft: '10px',
                  position: 'absolute',
                  right: '-50px',
                  top: '0',
                  bottom: '0',
                  height: '40px',
                  margin: 'auto',
                  '&.swiper-button-disabled': {
                    opacity: 0,
                    cursor: 'inherit',
                  },
                }}
                size="medium"
                ref={navigationNextRef}
              >
                {isLtr ? (
                  <ArrowForwardIos fontSize="medium" color="primary" />
                ) : (
                  <ArrowBackIosNew fontSize="medium" color="primary" />
                )}
              </IconButton>
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextField
                size="small"
                variant="standard"
                placeholder="Поиск"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={filterQueryInput}
                onChange={(event) =>
                  dispatch(setFilterQueryInput(event.target.value))
                }
              />
            </Box>
          </Box>
        </Container>
      </Box>

      {tabs.length > 0 && (
        <Tabs
          sx={{
            display: { xs: 'flex', lg: 'none' },
            width: '100vw',
            height: '49px',
            backgroundColor: 'common.white',
            position: 'fixed',
            top: {
              xs: isTopBar ? '59px' : '99px',
              md: isTopBar ? '80px' : '110px',
            },
            zIndex: '1100',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          }}
          value={false}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab: { id: string; name: string; orderBy: number }) => (
            <Tab
              sx={{
                color:
                  tab.id === activeCategoryId ? 'primary.main' : 'text.primary',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: '700',
                padding: '15px 15px',
                position: 'relative',
                borderRadius: '0px',
                whiteSpace: 'nowrap',
                maxWidth: 'unset',
                width: 'auto',
                '&:before': {
                  content: "''",
                  width: '100%',
                  height: '2px',
                  position: 'absolute',
                  backgroundColor: 'primary.main',
                  opacity: tab.id === activeCategoryId ? '1' : '0',
                  transition: 'all 0.5s ease-in-out',
                  bottom: '0',
                  left: '0',
                },
              }}
              label={Parser(tab.name)}
              key={tab.name}
              onClick={handleScrollTo(tab.id)}
            />
          ))}
        </Tabs>
      )}
    </>
  );
};

export default Nav;

import React, { FC, useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectLocationCity } from 'store/selectors/citySelectors';
import { SelectCityModal } from 'components/modals';
import { useTranslation } from 'react-i18next';

const Locality: FC = () => {
  const selectedLocationCity = useAppSelector(selectLocationCity);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: '300',
            color: 'primary.contrastText',
          }}
        >
          {t('location')}:
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: '600',
            padding: '0px',
            textTransform: 'inherit',
            position: 'relative',
            color: 'primary.contrastText',
            minWidth: 'auto',
            cursor: 'pointer',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              backgroundColor: 'primary.contrastText',
              bottom: '-1px',
              left: '0',
            },
          }}
          onClick={handleOpenModal}
        >
          {selectedLocationCity?.name}
        </Typography>
      </Box>

      <SelectCityModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Locality;

import React, { FC, useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { MainTitle, SearchField } from 'components/generic';
import {
  selectCitiesList,
  selectCityId,
  selectCitiesIsLoading,
} from 'store/selectors/citySelectors';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { CitiesList } from 'components/modals/SelectCityModal/Modal';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const citiesList = useAppSelector(selectCitiesList);
  const isCitiesLoading = useAppSelector(selectCitiesIsLoading);
  const { LOGO_LIGHT } = useAppSelector(selectAppSettingsData);
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredCities, setFilteredCities] = useState(citiesList);
  const { t } = useTranslation();
  const cityId = useAppSelector(selectCityId);

  useEffect(() => {
    const filtered = citiesList.filter((city) =>
      city.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredCities(filtered);
  }, [inputValue, citiesList]);

  const handleChangeValue = useCallback(
    (value: string) => {
      setInputValue(value);
    },
    [inputValue]
  );

  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          overflowY: 'inherit',
          borderRadius: '10px',
          padding: '30px 40px',
          width: '700px',
          height: '500px',
        },
      }}
      open={isOpen}
      onClose={cityId ? onClose : () => null}
    >
      {cityId && (
        <IconButton
          sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
          onClick={onClose}
          size="large"
          aria-label="close-btn"
        >
          <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
        </IconButton>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '28px',
        }}
      >
        <MainTitle>{t('select_your_city')}</MainTitle>
        <Box component="img" src={LOGO_LIGHT} />
      </Box>
      <SearchField handleChange={handleChangeValue} />

      {isCitiesLoading ? (
        <Box sx={{ padding: '50px', textAlign: 'center' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <CitiesList cities={filteredCities} onClose={onClose} />
      )}
    </Dialog>
  );
};

export default Modal;

import React, { FC, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import { SubTitle } from 'components/generic';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Card } from 'components/basket/BasketContent/Promo';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAvailablePromotions } from 'store/selectors/promotionSelectors';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Promo: FC = () => {
  const promotions = useAppSelector(selectAvailablePromotions);
  SwiperCore.use([Autoplay, Mousewheel, Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { t } = useTranslation();

  return (
    <>
      {promotions.length > 0 && (
        <>
          <Box sx={{ marginBottom: { xs: '14px', sm: '30px' } }}>
            <SubTitle>{t('save_more_with_our_promos')}:</SubTitle>
          </Box>
          <Box sx={{ padding: { xs: '0', md: '0 25px' } }}>
            <Box sx={{ position: 'relative' }}>
              <IconButton
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  marginRight: '10px',
                  position: 'absolute',
                  left: '-50px',
                  top: '0',
                  bottom: '0',
                  height: '40px',
                  margin: 'auto',
                  '&.swiper-button-disabled': {
                    opacity: 0,
                    cursor: 'inherit',
                  },
                }}
                size="medium"
                ref={navigationPrevRef}
              >
                <ArrowBackIosNew fontSize="medium" color="primary" />
              </IconButton>

              <Swiper
                spaceBetween={25}
                freeMode
                centeredSlidesBounds
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                breakpoints={{
                  '250': {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                  },
                  '300': {
                    slidesPerView: 2.2,
                    spaceBetween: 10,
                  },
                  '480': {
                    slidesPerView: 3.2,
                    spaceBetween: 15,
                  },
                  '567': {
                    slidesPerView: 3.2,
                    spaceBetween: 10,
                  },
                  '767': {
                    slidesPerView: 4.1,
                    spaceBetween: 20,
                  },
                  '991': {
                    slidesPerView: 4.5,
                    spaceBetween: 30,
                  },
                }}
              >
                {promotions.map((promotion) => (
                  <SwiperSlide key={promotion.id}>
                    <Card
                      title={promotion.name}
                      id={promotion.id}
                      isActive={promotion.is_auto_add}
                      promoProducts={promotion.promo_dishes}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <IconButton
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  marginLeft: '10px',
                  position: 'absolute',
                  right: '-50px',
                  top: '0',
                  bottom: '0',
                  height: '40px',
                  margin: 'auto',
                  '&.swiper-button-disabled': {
                    opacity: 0,
                    cursor: 'inherit',
                  },
                }}
                size="medium"
                ref={navigationNextRef}
              >
                <ArrowForwardIos fontSize="medium" color="primary" />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Promo;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MirIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="400px"
      height="120px"
      viewBox="0 0 400 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="62.9043048%"
          x2="0%"
          y2="62.9043048%"
          id="linearGradient-1"
        >
          <stop stopColor="#1F5CD7" offset="0%" />
          <stop stopColor="#02AEFF" offset="100%" />
        </linearGradient>
      </defs>
      <g id="sim" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="mir-logo" transform="translate(31.000000, 13.000000)">
          <g id="Group-5">
            <path
              d="M32.5505663,0.0163847792 C35.6952917,-0.00242815539 45.0412371,-0.844175419 49,12.5166493 C51.6666667,21.5166493 55.9145075,36.259066 61.7435225,56.7438993 L61.7435225,56.7438993 L64.1174373,56.7438993 C70.3685427,35.1473512 74.6627303,20.4049345 77,12.5166493 C81,-0.983350676 91,0.0166493236 95,0.0166493236 L125.86096,0.0166878181 L125.86096,96.0164413 L94.4066344,96.0164413 L94.4066344,39.4421872 L92.2973975,39.4421872 L74.7631716,96.0164413 L51.0977882,96.0164413 L33.5635623,39.4002658 L31.4543254,39.4002658 L31.4543254,96.0164413 L0,96.0164413 L0,0.0166878181 Z M171.028726,0.0166878181 L171.028726,56.6330713 L173.537996,56.6330713 L194.866787,10.0778192 C199.007082,0.813194049 207.831347,0.0166878181 207.831347,0.0166878181 L207.831347,0.0166878181 L238.269736,0.0166878181 L238.269736,96.0166493 L206.158501,96.0166493 L206.158501,39.4002658 L203.649231,39.4002658 L182.738651,85.955518 C178.598356,95.1782217 169.35588,96.0166493 169.35588,96.0166493 L138.917491,96.0166493 L138.917491,0.0166878181 L171.028726,0.0166878181 Z M349.260509,45.6365042 C344.781335,58.3294446 330.714922,67.4197682 315.141489,67.4197682 L315.141489,67.4197682 L281.466507,67.4197682 L281.466507,96.0166493 L250.930615,96.0166493 L250.930615,45.6365042 Z"
              id="Combined-Shape"
              fill="#0F754E"
            />
            <path
              d="M316.614216,0.0166878181 L249.329298,0.0166878181 C250.930615,21.3895399 269.336373,39.6860934 288.390129,39.6860934 L351.3823,39.6860934 C355.0174,21.922741 342.503442,0.0166878181 316.614216,0.0166878181 Z"
              id="path24"
              fill="url(#linearGradient-1)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default MirIcon;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from 'types/Address';
import {
  addAddress,
  deleteAddress,
  getAddresses,
  updateAddress,
} from './ActionCreators';

interface AddressState {
  addresses: Address[];
  defaultAddressId: number | null;
  isLoading: boolean;
  error: string;
}

const initialState: AddressState = {
  addresses: [],
  defaultAddressId: null,
  isLoading: false,
  error: '',
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    addDefaultAddressId: (state, action: PayloadAction<number>) => {
      state.defaultAddressId = action.payload;
    },
  },
  extraReducers: {
    [getAddresses.fulfilled.type]: (
      state,
      action: PayloadAction<Address[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.addresses = action.payload;
    },
    [getAddresses.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAddresses.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [addAddress.fulfilled.type]: (state, action: PayloadAction<Address>) => {
      state.isLoading = false;
      state.error = '';
      state.addresses.push(action.payload);
    },
    [addAddress.pending.type]: (state) => {
      state.isLoading = true;
    },
    [addAddress.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteAddress.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.addresses = state.addresses.filter(
        (address) => address.id !== Number(action.meta.arg.addressId)
      );
    },
    [deleteAddress.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteAddress.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateAddress.fulfilled.type]: (state, action: PayloadAction<Address>) => {
      state.isLoading = false;
      state.error = '';
      const selectedAddress = state.addresses.find(
        (address: Address) => address.id === action.payload.id
      );
      //! Пересмотреть изменение имеющегося объекта в массиве ==========================
      if (selectedAddress) {
        selectedAddress.name = action.payload.name;
        selectedAddress.apartment = action.payload.apartment;
        selectedAddress.building = action.payload.building;
        selectedAddress.city = action.payload.city;
        selectedAddress.corpus = action.payload.corpus;
        selectedAddress.comment = action.payload.comment;
        selectedAddress.entrance = action.payload.entrance;
        selectedAddress.floor = action.payload.floor;
        selectedAddress.house = action.payload.house;
        selectedAddress.street = action.payload.street;
        selectedAddress.city_name = action.payload.city_name;
        selectedAddress.street_classifier_id =
          action.payload.street_classifier_id;
      }
    },
    [updateAddress.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateAddress.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default addressSlice.reducer;

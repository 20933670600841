import React, { FC } from 'react';
import { Box, Container, Button } from '@mui/material';
import { AddressList, OrderInfo, PersonalInfo } from 'components/account';
import { MainTitle } from 'components/generic';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useAccount } from 'hooks/account';
import { useAuth } from 'hooks/auth';
import { Helmet } from 'react-helmet';

const Account: FC = () => {
  const { methods, onSubmit, isLoadingOrder, activeOrdersData } = useAccount();
  const { logOut } = useAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 900 });

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {t('account')}
        </title>
      </Helmet>
      <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', md: '500px' },
                marginRight: { xs: '0px', md: '40px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: { xs: '24px', sm: '38px' },
                }}
              >
                <MainTitle>{t('my_account')}</MainTitle>
                <Button
                  sx={{
                    fontSize: { xs: '14px', md: '20px' },
                    lineHeight: { xs: '15px', md: '23px' },
                    fontWeight: { xs: '500', md: '300' },
                    color: 'text.primary',
                    height: { xs: 'auto', md: '50px' },
                    width: { xs: 'auto', md: '150px' },
                    border: { xs: '0px solid', md: '2px solid' },
                    borderColor: { xs: 'none', md: 'primary.main' },
                    '&:hover': {
                      border: { xs: '0px solid', md: '2px solid' },
                      borderColor: { xs: 'none', md: 'primary.main' },
                    },
                  }}
                  variant={isMobile ? 'text' : 'outlined'}
                  onClick={logOut}
                >
                  {t('sign_out')}
                </Button>
              </Box>
              <Box sx={{ marginBottom: { xs: '30px', md: '0px' } }}>
                <FormProvider {...methods}>
                  <Box
                    component="form"
                    onSubmit={methods.handleSubmit(onSubmit)}
                    sx={{
                      marginBottom: { xs: '27px', sm: '37px' },
                      maxWidth: { xs: '100%', sm: '500px' },
                    }}
                  >
                    <PersonalInfo />
                  </Box>
                </FormProvider>
                <AddressList />
              </Box>
            </Box>

            {!isLoadingOrder && activeOrdersData.length > 0 && (
              <Box sx={{ marginBottom: { xs: '35px', md: '0px' } }}>
                <Box
                  sx={{
                    marginBottom: { xs: '18px', sm: '40px' },
                    height: { xs: 'auto', sm: '50px' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MainTitle> {t('current_orders')}</MainTitle>
                </Box>
                <OrderInfo />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Account;

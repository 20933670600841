import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { GroupModifier } from 'types/Menu';
import {
  ComboSlider,
  ComboMultipleSelector,
} from 'components/modals/ProductModal/Modal/Content/Combo';
import { useFormContext } from 'react-hook-form';
import { Tooltip } from 'components/generic';

interface ComboProps {
  name: string;
  groupModifiers: GroupModifier[];
  image: string;
  extraDescription: string;
  calculatedDescription: string;
}

const Combo: FC<ComboProps> = ({
  name,
  groupModifiers,
  image,
  extraDescription,
  calculatedDescription,
}) => {
  const { formState } = useFormContext();
  const titleRef = useRef<any>(null);
  const [titleHeight, setTitleHeight] = useState<number>(0);

  useEffect(() => {
    setTitleHeight(titleRef.current.clientHeight);
  }, [titleRef, formState]);

  return (
    <Box
      sx={{
        padding: '0',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          maxHeight: '380px',
          width: { xs: '100%', md: '50%' },
          marginBottom: { xs: '0px', md: '25px' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          component="img"
          src={image}
        />
      </Box>

      <Box
        sx={{
          height: '405px',
          overflow: 'hidden',
          width: { xs: '100%', md: '50%' },
        }}
      >
        <Box ref={titleRef}>
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                marginLeft: { xs: '0xp', md: '30px' },
                fontSize: '28px',
                fontWeight: '600',
                flexGrow: '1',
              }}
              component="div"
            >
              {name}
            </Typography>
            <Tooltip title={extraDescription} />
          </Stack>

          <Typography
            sx={{
              fontWeight: '600',
              marginLeft: { xs: '0xp', md: '30px' },
            }}
            component="div"
          >
            {calculatedDescription}
            <Divider />
          </Typography>
        </Box>

        <Stack
          direction="column"
          spacing={2}
          sx={{
            padding: '20px 0px',
            height: `calc(405px - ${titleHeight}px)`,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: 7,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: '20px',
            },
          }}
        >
          {groupModifiers.map((group) => (
            <Box key={group.id}>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '23px',
                  fontWeight: '400',
                  marginLeft: { xs: '0xp', md: '30px' },
                }}
              >
                {group.choice_name}
              </Typography>
              {group.max_count > 1 ? (
                <ComboMultipleSelector
                  modifiers={group.modifiers}
                  id={group.id}
                  maxCount={group.max_count}
                  minCount={group.min_count}
                />
              ) : (
                <ComboSlider modifiers={group.modifiers} id={group.id} />
              )}
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Combo;

import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import {
  selectIsOnlyPickupCategory,
  selectMenuCardPrice,
  selectMenuProduct,
} from 'store/selectors/menuSelectors';
import {
  selectBasketId,
  selectProductCount,
} from 'store/selectors/orderSelectors';
import { useRandomId } from 'hooks';

const useProductCard = (categoryId: number, productId: number) => {
  const dispatch = useAppDispatch();
  const { addProduct, addProductCount, removeProductCount, removeProduct } =
    orderSlice.actions;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    name,
    src,
    description,
    options,
    ingredients,
    cooking_options,
    group_modifiers,
    is_only_pickup,
    order_by,
    label,
  } = useAppSelector(selectMenuProduct(productId, categoryId));
  const cardPriceCop = useAppSelector(
    selectMenuCardPrice(productId, categoryId)
  );
  const basketId = useAppSelector(selectBasketId(options[0].id)) as number;
  const productCount = useAppSelector(
    selectProductCount(options[0].id)
  ) as number;

  const isOnlyPickupCategory = useAppSelector(
    selectIsOnlyPickupCategory(categoryId)
  );

  const randomId = useRandomId(order_by);

  const isCountButton = useMemo(() => {
    return (
      options.length <= 1 &&
      ingredients.length <= 0 &&
      cooking_options.length <= 0 &&
      group_modifiers.length <= 0
    );
  }, [options, ingredients, cooking_options, group_modifiers]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  const handleAddProductToBasket = useCallback(() => {
    const productData = {
      id: options[0].id,
      title: name,
      image: src,
      cooking_option_id: cooking_options[0]?.id || null,
      total_price: options[0].price_kop,
      count: 1,
      group_modifiers,
      ingredients,
      description,
      is_only_pickup,
      basketId: randomId,
    };

    if (isCountButton) {
      dispatch(
        addProduct({
          ...productData,
          is_only_pickup_category: isOnlyPickupCategory,
        })
      );
    }
  }, [isCountButton]);

  const handleAddProductCount = useCallback(() => {
    dispatch(addProductCount(basketId));
  }, [basketId]);

  const handleRemoveProductCount = useCallback(() => {
    dispatch(removeProductCount(basketId));
  }, [basketId]);

  const isCount = useMemo(() => {
    return productCount > 0;
  }, [productCount]);

  useEffect(() => {
    if (productCount === 0) {
      dispatch(removeProduct(basketId));
    }
  }, [productCount]);

  return {
    cardPriceCop,
    src,
    name,
    description,
    count: productCount,
    isCount,
    isModalOpen,
    isCountButton,
    handleOpenModal,
    handleCloseModal,
    handleAddProductToBasket,
    handleAddProductCount,
    handleRemoveProductCount,
    label,
  };
};

export default useProductCard;

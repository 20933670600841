import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import animationData from 'lotties/errorBasket.json';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import image from 'assets/images/sorry.png';

const Success: FC = () => {
  const { t, i18n } = useTranslation();
  const isLtr = i18n.dir() === 'ltr';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { clearFeedback } = accountSlice.actions;

  const handleNavToMenu = () => {
    navigate('/');
    dispatch(clearFeedback());
  };

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        sx={{
          fontSize: '30px',
          lineHeight: '30px',
          fontWeight: '400',
          textAlign: 'center',
        }}
      >
        {t('review_page.success.title')}
      </Typography>
      <Box
        sx={{
          display: 'inline-block',
          maxWidth: '250px',
        }}
      >
        {/* <Lottie options={defaultOptions} /> */}
        <Box
          sx={{
            height: '150px',
            width: '170px',
            overflow: 'hidden',
            margin: '30px 0',
          }}
        >
          <Box
            sx={{
              objectFit: 'cover',
              width: '100%',
              minHeight: '100%',
              objectPosition: 'center',
            }}
            src={image}
            component="img"
          />
        </Box>
      </Box>
      <Typography
        sx={{
          maxWidth: '500px',
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '300',
          marginBottom: '30px',
        }}
      >
        {t('review_page.success.text')}
      </Typography>

      <Button
        sx={{
          fontSize: '20px',
          lineHeight: '23px',
          height: '50px',
          fontWeight: '300',
          width: '50%',
        }}
        variant="text"
        onClick={handleNavToMenu}
      >
        {isLtr ? (
          <ArrowBackIos color="secondary" />
        ) : (
          <ArrowForwardIos color="secondary" />
        )}

        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            lineHeight: '21px',
            color: 'secondary.main',
            fontWeight: '500',
          }}
        >
          {t('review_page.success.button')}
        </Typography>
      </Button>
    </Box>
  );
};

export default Success;

import React, { FC, useEffect } from 'react';
import { Container, Box } from '@mui/material';
import { EmptyBasket, BasketContent } from 'components/basket';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsEmptyBasket } from 'store/selectors/orderSelectors';
import { useTranslation } from 'react-i18next';
import { AddressModal, AuthModal } from 'components/modals';
import { useBasket } from 'hooks/basket';
import { Helmet } from 'react-helmet';
import { selectMenuIsLoading } from 'store/selectors/menuSelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { selectBrandId } from 'store/selectors/brandsSelectors';

const Basket: FC = () => {
  const {
    methods,
    authModal,
    handleAuthModalClose,
    addressModal,
    handleAddressModalClose,
    handleAddressButton,
    handleCheckoutButton,
    products,
    isPromocode,
  } = useBasket();
  const isEmptyBasket = useAppSelector<boolean>(selectIsEmptyBasket);
  const isLoadingMenu = useAppSelector(selectMenuIsLoading);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const brandId = useAppSelector(selectBrandId);
  const { setBrandId } = orderSlice.actions;

  useEffect(function setBrand() {
    dispatch(setBrandId(brandId));
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {t('cart')}
        </title>
      </Helmet>

      <Box sx={{ padding: { xs: '30px 0 80px 0', md: '40px 0' } }}>
        <Container>
          {!isLoadingMenu && isEmptyBasket ? (
            <EmptyBasket />
          ) : (
            <BasketContent
              methods={methods}
              handleAddressButton={handleAddressButton}
              handleCheckoutButton={handleCheckoutButton}
              products={products}
              isPromocode={isPromocode}
            />
          )}
        </Container>
        <AddressModal
          isOpen={addressModal}
          onClose={handleAddressModalClose}
          modalType="add"
        />
        <AuthModal isOpen={authModal} onClose={handleAuthModalClose} />
      </Box>
    </>
  );
};

export default Basket;

import React, { MouseEvent, FC } from 'react';
import { Box, Button as MUIButton } from '@mui/material';
import { CounterButton } from 'components/generic';

interface ButtonProps {
  isCount: boolean;
  isCountModeButton: boolean;
  count: number;
  handleAddProductToBasket: () => void;
  handleAddProductCount: () => void;
  handleRemoveProductCount: () => void;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  isCount,
  isCountModeButton,
  count,
  handleAddProductToBasket,
  handleAddProductCount,
  handleRemoveProductCount,
  children,
}) => {
  const stopPropagation = (
    event: MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    if (isCountModeButton) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  return (
    <Box sx={{ height: '100%' }} onClick={(event) => stopPropagation(event)}>
      {!isCountModeButton || !isCount ? (
        <MUIButton
          sx={{
            textTransform: 'inherit',
            width: '100%',
            height: '100%',
            borderRadius: '6px',
            color: 'primary.main',
            fontSize: { xs: '15px', sm: '18px' },
            lineHeight: { xs: '17px', sm: '21px' },
            fontWeight: '300',
            border: { xs: '1px solid', sm: '2px solid' },
            '&:hover': {
              border: { xs: '1px solid', sm: '2px solid' },
              borderColor: { xs: 'primary.main', sm: 'primary.main' },
            },
            borderColor: { xs: 'primary.main', sm: 'primary.main' },
            whiteSpace: 'nowrap',
            boxShadow: 'none',
          }}
          onClick={handleAddProductToBasket}
        >
          {children}
        </MUIButton>
      ) : (
        <CounterButton
          count={count}
          addCount={handleAddProductCount}
          removeCount={handleRemoveProductCount}
        />
      )}
    </Box>
  );
};

export default Button;

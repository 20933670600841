import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Brand } from 'types/Brands';
import { getBrands } from './ActionCreators';

interface BrandsState {
  brands: Brand[];
  brand_id: number | null;
  isLoading: boolean;
  error: string;
}

const initialState: BrandsState = {
  brands: [],
  brand_id: null,
  isLoading: false,
  error: '',
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    selectBrand: (state, action: PayloadAction<number>) => {
      state.brand_id = action.payload;
    },
  },
  extraReducers: {
    [getBrands.fulfilled.type]: (state, action: PayloadAction<Brand[]>) => {
      state.isLoading = false;
      state.error = '';
      state.brands = action.payload;
    },
    [getBrands.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getBrands.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default brandSlice.reducer;

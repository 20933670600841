import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { PaymentForm } from 'components/modals/CloudPaymentModal/Modal/Content';
import { selectOrderIsLoading } from 'store/selectors/orderSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { LoadingButton } from '@mui/lab';

interface ContentProps {
  hanldeGenCryptoKey: () => void;
}

const Content: FC<ContentProps> = ({ hanldeGenCryptoKey }) => {
  const orderIsLoading = useAppSelector(selectOrderIsLoading);

  return (
    <Stack
      sx={{
        padding: '20px',
      }}
      direction="column"
      spacing={3}
    >
      <Typography
        sx={{
          fontSize: '30px',
          lineHeight: '33px',
          fontWeight: '400',
        }}
        variant="h4"
      >
        Введите данные карты
      </Typography>
      <PaymentForm />
      <LoadingButton
        sx={{
          fontSize: { xs: '16px', sm: '18px' },
          lineHeight: { xs: '18px', sm: '21px' },
          textTransform: 'inherit',
          boxShadow: 'none',
          color: 'primary.contrastText',
          fontWeight: '300',
          height: '45px',
        }}
        variant="contained"
        onClick={hanldeGenCryptoKey}
        loading={orderIsLoading}
      >
        Отправить
      </LoadingButton>
    </Stack>
  );
};

export default Content;

import React, { FC } from 'react';
import { Dialog, IconButton, Stack, Box, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Card } from 'components/modals/BrandsModal';
import { Brand } from 'types/Brands';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  brands: Brand[];
  isOpener?: boolean;
}

const Modal: FC<ModalProps> = ({ isOpen, handleClose, brands, isOpener }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={!isOpener ? handleClose : () => null}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          overflowY: 'inherit',
          maxWidth: '420px',
          padding: '15px',
        },
      }}
    >
      {!isOpener && (
        <IconButton
          sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
          onClick={handleClose}
          size="large"
          aria-label="close-btn"
        >
          <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
        </IconButton>
      )}
      <Typography
        sx={{
          fontSize: '30px',
          lineHeight: '33px',
          fontWeight: '300',
          textAlign: 'center',
          padding: '17px 0',
          fontFamily: 'Montserrat',
        }}
      >
        {t('select_restaurant')}
      </Typography>
      <Box
        sx={{
          maxHeight: '700px',
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: 7,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.400',
            borderRadius: '20px',
          },
        }}
      >
        <Stack direction="column" spacing={0.5}>
          {brands.map((brand) => (
            <Card
              key={brand.id}
              id={brand.id}
              name={brand.name}
              description={brand.short_description}
              logo={brand.logo}
              image={brand.image}
              label={brand.label}
              handleClose={handleClose}
            />
          ))}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default Modal;

import React, { FC } from 'react';
import { Booking, Info } from 'components/reserve';
import { Banner } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPageData } from 'store/selectors/pageSelectors';
import { useScrollToBlock } from 'hooks';
import { Box } from '@mui/material';

const Reserve: FC = () => {
  useScrollToBlock();
  const reservePageData = useAppSelector(selectPageData('reserve'));

  if (!reservePageData) {
    return null;
  }

  const {
    info_title,
    info_text,
    info_image,
    page_title,
    banner_image,
    form_image,
  } = reservePageData;

  return (
    <>
      <Box sx={{ marginBottom: { xs: '20px', sm: '50px', md: '100px' } }}>
        <Banner title={page_title} image={banner_image} />
      </Box>
      <Booking src={form_image} />
      <Info title={info_title} text={info_text} src={info_image} />
    </>
  );
};

export default Reserve;

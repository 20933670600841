import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCitiesData,
  selectLocationCity,
} from 'store/selectors/citySelectors';

interface LocationProps {
  onSelectCityModalOpen: () => void;
}

const Location: FC<LocationProps> = ({ onSelectCityModalOpen }) => {
  const selectedLocationCity = useAppSelector(selectLocationCity);
  const cities = useAppSelector(selectCitiesData);

  if (cities.length < 2) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      }}
      gap={1}
    >
      <LocationOnIcon color="primary" />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '19px',
          color: 'grey.50',
        }}
        onClick={onSelectCityModalOpen}
      >
        {selectedLocationCity?.name}
      </Typography>
    </Box>
  );
};

export default Location;

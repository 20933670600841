import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import axios from 'axios';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectCodeData } from 'store/selectors/accountSelectors';
import { AppSettingsData } from 'types/AppSettings';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useTranslation } from 'react-i18next';

type Data = {
  y: number;
  id: string;
  puzzle_image: string;
  piece_image: string;
};

type CaptchaInputState =
  | { kind: 'accepting_input'; data: Data; input: { relativeX: number } }
  | {
      kind: 'processing_input';
      data: Data;
      input: { relativeX: number };
    }
  | { kind: 'wrong_input'; data: Data }
  | { kind: 'loading_captcha' }
  | { kind: 'null' }
  | { kind: 'error'; reason: unknown };

const useCaptchaInputStep = (nextStep: () => void) => {
  const { success, reason } = useAppSelector(selectCodeData);
  const { AUTH_SERVICE_TYPE } = useAppSelector<AppSettingsData>(
    selectAppSettingsData
  );
  const { getAuthCode } = useAuth();
  const { watch } = useFormContext();
  const { t } = useTranslation();

  const [state, setState] = useState<CaptchaInputState>({ kind: 'null' });
  const loginValue = watch('login');

  useEffect(() => {
    if (success) {
      nextStep();
    } else if (
      !success &&
      reason === 'WRONG_CAPTCHA' &&
      state.kind === 'processing_input'
    ) {
      setState({ ...state, kind: 'wrong_input' });
    } else if (
      !success &&
      reason !== null &&
      state.kind === 'processing_input'
    ) {
      setState({ kind: 'error', reason });
    }
  }, [success, reason, state]);

  const setX = (value: number) => {
    setState((state) => {
      if (state.kind === 'accepting_input') {
        return { ...state, input: { relativeX: value } };
      }

      throw new Error('Cant call setX when not in accepting_input state');
    });
  };

  const getCaptchaRequest = async (): Promise<
    { success: true; data: Data } | { success: false; error: unknown }
  > => {
    try {
      const { data } = await axios.get<Data>(
        `${process.env.REACT_APP_V3}/captcha`
      );
      return { success: true, data };
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  };

  const getCaptcha = () => {
    setState({ kind: 'loading_captcha' });
    getCaptchaRequest().then((response) => {
      if (response.success) {
        setState({
          kind: 'accepting_input',
          data: response.data,
          input: { relativeX: 0 },
        });
      } else {
        setState({
          kind: 'error',
          reason: response.error,
        });
      }
    });
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  const processInput = (
    state: Extract<CaptchaInputState, { kind: 'processing_input' }>
  ) => {
    let login: string;
    let mix = false;

    if (AUTH_SERVICE_TYPE === 1 || AUTH_SERVICE_TYPE === 3) {
      login = loginValue.replace(/\D/g, '');
      mix = true;
    } else if (AUTH_SERVICE_TYPE === 0) {
      login = loginValue;
    } else if (AUTH_SERVICE_TYPE === 2) {
      login = loginValue.replace(/\D/g, ''); // clear number
    } else {
      throw new Error('Invalid AUTH_SERVICE_TYPE');
    }

    const x = state.input.relativeX * (4 / 5);

    getAuthCode(login, AUTH_SERVICE_TYPE, mix, state.data.id, x, state.data.y);
  };

  useEffect(() => {
    if (state.kind === 'processing_input') {
      processInput(state);
    }
  }, [state]);

  const onCaptchaSubmit = (x: number) => {
    setState((prev) => {
      if (prev.kind === 'accepting_input') {
        return {
          ...prev,
          kind: 'processing_input',
          input: { relativeX: x },
        };
      }
      throw new Error(
        'Cant submit captcha from state that is not accepting_input'
      );
    });
  };

  const onRetryButtonClick = () => {
    getCaptcha();
  };

  const convertY = (value: number) => value * (5 / 4);

  return { state, onCaptchaSubmit, onRetryButtonClick, t, setX, convertY };
};

export default useCaptchaInputStep;

import React, { FC } from 'react';
import { Box, Typography, Link } from '@mui/material';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectLocationCity } from 'store/selectors/citySelectors';
import { useTranslation } from 'react-i18next';

const CityPhoneNumber: FC = () => {
  const city = useAppSelector(selectLocationCity);
  const { t } = useTranslation();

  if (!(city && city.phone)) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: '16px',
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: '700', lineHeight: 1.2 }}>
        {t('contact-number')}
      </Typography>
      <Link href={`tel:${city.phone}`}>
        <Typography sx={{ fontSize: '1.05rem' }}>{city.phone}</Typography>
      </Link>
    </Box>
  );
};

export default CityPhoneNumber;

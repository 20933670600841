import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectSuccessDelivery,
  selectCheckDelieryIsLoading,
} from 'store/selectors/orderSelectors';
import { useTranslation } from 'react-i18next';

interface DrawerButtonProps {
  isCheckDelivery: boolean;
  handleBack: () => void;
  closeDrawer: () => void;
}

const DrawerButton: FC<DrawerButtonProps> = ({
  isCheckDelivery,
  handleBack,
  closeDrawer,
}) => {
  const isLoading = useAppSelector(selectCheckDelieryIsLoading);
  const isSuccess = useAppSelector(selectSuccessDelivery);
  const { t } = useTranslation();

  return (
    <>
      {!isCheckDelivery ? (
        <Button
          sx={{
            textTransform: 'inherit',
            width: '100%',
            borderRadius: '6px',
            fontSize: '16px',
            lineHeight: '16px',
            height: '100%',
            padding: '15px 0',
            whiteSpace: 'nowrap',
            border: '2px solid',
            color: 'text.primary',
            '&:hover': {
              border: '2px solid',
            },
            borderColor: 'primary.main',
            boxShadow: 'none',
            background: '#ffffff',
          }}
          type="submit"
          variant="outlined"
          color="primary"
        >
          {t('continue')}
        </Button>
      ) : (
        <>
          {isSuccess && !isLoading ? (
            <Button
              sx={{
                textTransform: 'inherit',
                width: '100%',
                borderRadius: '6px',
                fontSize: '16px',
                lineHeight: '16px',
                height: '100%',
                padding: '15px 0',
                whiteSpace: 'nowrap',
                border: '2px solid',
                color: 'text.primary',
                '&:hover': {
                  border: '2px solid',
                },
                borderColor: 'primary.main',
                boxShadow: 'none',
                background: '#ffffff',
              }}
              variant="outlined"
              color="primary"
              onClick={closeDrawer}
            >
              {t('close')}
            </Button>
          ) : (
            <Button
              sx={{
                textTransform: 'inherit',
                width: '100%',
                borderRadius: '6px',
                fontSize: '16px',
                lineHeight: '16px',
                height: '100%',
                padding: '15px 0',
                whiteSpace: 'nowrap',
                border: '2px solid',
                color: 'text.primary',
                '&:hover': {
                  border: '2px solid',
                },
                borderColor: 'primary.main',
                boxShadow: 'none',
                background: '#ffffff',
              }}
              variant="outlined"
              color="primary"
              onClick={handleBack}
            >
              {t('back')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default DrawerButton;

import React, { FC } from 'react';
import { Slider } from '@mui/material';

interface CaptchaSliderProps {
  value: number;
  onChange: (value: number) => void;
  onChangeCommitted: (value: number) => void;
  disabled?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  mobile?: boolean;
}

const CaptchaSlider: FC<CaptchaSliderProps> = ({
  value,
  onChange,
  onChangeCommitted,
  disabled = false,
  helperText = '',
  fullWidth = false,
  mobile = false,
}) => {
  const width = fullWidth ? '100%' : '343px';
  const height = mobile ? '45px' : '56px';

  return (
    <Slider
      disabled={disabled}
      min={0}
      max={1}
      step={0.001}
      track={false}
      value={value}
      onChange={(_, value) => onChange(value as number)}
      onChangeCommitted={(_, value) => onChangeCommitted(value as number)}
      sx={{
        '&.MuiSlider-root': {
          width: `calc(${width} - 60px)`,
          height,
          borderRadius: '6px',
          padding: 0,
          margin: 0,
        },
        '&.MuiSlider-root::before': {
          content: `"${helperText}"`,
          color: '#202020',
          position: 'absolute',
          zIndex: 2,
          width: '100%',
          top: '50%',
          textAlign: 'center',
        },
        '.MuiSlider-rail': {
          width: 'calc(100% + 60px)',
          backgroundColor: '#F0F0F0',
          opacity: 1,
          transform: 'translateY(-50%) translateX(-30px)',
        },
        '.MuiSlider-thumb': {
          width: mobile ? '46px' : '54px',
          height: '85%',
          borderRadius: '6px',
          cursor: 'pointer',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: 3,
          backgroundImage:
            "url(\"data:image/svg+xml;utf8,<svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.592 1.28289L21.2328 6.93989C21.5625 7.22114 21.7478 7.60239 21.7478 7.99989C21.7478 8.39739 21.5625 8.77864 21.2328 9.05989L14.5931 14.7179C14.2628 14.9993 13.8148 15.1574 13.3476 15.1574C12.8805 15.1574 12.4324 14.9993 12.1021 14.7179C11.7718 14.4365 11.5862 14.0548 11.5862 13.6569C11.5862 13.2589 11.7718 12.8773 12.1021 12.5959L15.7365 9.49989H2.19544C1.72843 9.49989 1.28054 9.34185 0.950317 9.06055C0.62009 8.77925 0.43457 8.39771 0.43457 7.99989C0.43457 7.60206 0.62009 7.22053 0.950317 6.93923C1.28054 6.65792 1.72843 6.49989 2.19544 6.49989H15.7365L12.1033 3.40389C11.7731 3.12249 11.5877 2.74089 11.5878 2.34304C11.5879 1.94518 11.7735 1.56365 12.1039 1.28239C12.4342 1.00113 12.8822 0.843168 13.3492 0.843262C13.8163 0.843356 14.2641 1.00149 14.5943 1.28289H14.592Z' fill='white'/></svg>\")",
        },
      }}
    />
  );
};

export default CaptchaSlider;

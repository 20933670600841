import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PromotionParams,
  Promotion,
  CheckPromoCode,
  CheckPromoCodeQuery,
} from 'types/Promotion';

export const getPromotions = createAsyncThunk(
  'promotion/getPromotions',
  async (params: PromotionParams, thunkAPI) => {
    const { api_key, platform, brand_id, city_id, lang } = params;
    try {
      const response = await axios.get<Promotion[]>(
        `${process.env.REACT_APP_V3}/promotion/`,
        {
          headers: {
            'Accept-Language': lang,
          },
          params: {
            api_key,
            platform,
            brand_id,
            city_id,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't get promo. Error:${e}`);
    }
  }
);

export const checkPromoCode = createAsyncThunk(
  'promotion/checkPromoCode',
  async (params: CheckPromoCodeQuery, thunkAPI) => {
    const { api_key, platform, promocode, brand_id } = params;
    try {
      const response = await axios.post<CheckPromoCode>(
        `${process.env.REACT_APP_V3}/promotion/check_promocode/`,
        {
          promocode,
          params: {
            api_key,
            platform,
            brand_id,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Couldn't verify promo code. Error:${e}`);
    }
  }
);
